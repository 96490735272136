import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../store/exercisesStore';
import { Button } from '../../../../teacherComponents';
import { ReactComponent as IconAdd } from '../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconCloud } from '../../../../assets/svg/lessons-cloud.svg';
import { ReactComponent as IconStars } from '../../../../assets/svg/lessons-stars.svg';
import {
    Chapters,
    ChapterModals,
    SelectWordsCategoryModalType,
} from '../../../TeacherLessons/data/constants';
import onboardingStore from '../../../../store/onboarding';
import cl from './GenerateChaptersButtons.module.css';

const GenerateChaptersButtons = ({ view, variant }) => {
    const { t } = useTranslation();

    const renderCreateIcon = () => {
        switch (view) {
            case Chapters.Track:
                return <IconCloud />;
            case Chapters.Dictionary:
                return <IconStars />;
            case Chapters.Situation:
            case Chapters.Exercise:
                return <IconStars />;
            default:
                return '';
        }
    };

    const handleGenerateClick = () => {
        exercisesStore.setChaptersModalType(ChapterModals[view]);
    };

    const handleSelectClick = () => {
        exercisesStore.setChaptersModalType(view);
    };

    if (view === Chapters.Dictionary)
        return (
            <div className={cl.bottomButtons}>
                <Button
                    style={{ width: '33%', padding: '12px 6px' }}
                    variant={variant}
                    text={t(`exercises.add_new_${view}`)}
                    icon={renderCreateIcon()}
                    onClick={handleGenerateClick}
                />
                <Button
                    style={{ width: '33%', padding: '12px 6px' }}
                    variant={variant}
                    text={t(`exercises.add_fav_${view}`)}
                    onClick={() => {
                        exercisesStore.setChaptersModalType(
                            SelectWordsCategoryModalType
                        );
                    }}
                />
                <Button
                    style={{ width: '33%', padding: '12px 6px' }}
                    variant={variant}
                    icon={<IconAdd />}
                    text={t(`exercises.add_fav_track`)}
                    onClick={handleSelectClick}
                />
            </div>
        );

    return (
        <div className={cl.bottomButtons}>
            <Button
                style={{ width: '50%' }}
                variant={variant}
                text={t(`exercises.add_new_${view}`)}
                icon={renderCreateIcon()}
                onClick={handleGenerateClick}
            />
            <Button
                style={{ width: '50%' }}
                variant={variant}
                icon={<IconAdd />}
                text={t(`exercises.add_fav_track`)}
                onClick={handleSelectClick}
            />
        </div>
    );
};

export default observer(GenerateChaptersButtons);
