import { v4 as uuidv4 } from 'uuid';

const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
};

const DEVICE_ID = getDeviceId();

export { getDeviceId, DEVICE_ID };
