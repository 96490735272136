import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import lessonsStore from '../../store/lessonsStore';
import onboardingStore from '../../../../store/onboarding';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import {
    AgeOptions,
    getExamOptions,
    LevelOptions,
} from '../../../TeacherContent/data/constants';
import { PrimaryInput } from '../../../../UI';
import { Button, Select, Tooltip } from '../../../../teacherComponents';
import {
    OnboardingWrapper,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../../../../teacherComponents/Onboarding';
import { LanguageLevels } from '../../../../data/common';
import { ReactComponent as IconClose } from '../../../../assets/svg/close.svg';
import cl from './AddLesson.module.css';

const AddLesson = ({ themeObj, forceAdd, onClick, isLast }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { difficulty: kanbanDifficulty } = lessonsStore;

    const onboardingAddKey = onboardingStore.isOnboarding(
        OnboardingKeys.AddLesson
    );
    const onboardingAwaitKey = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitLessonAdd
    );

    const { id: themeId, title: themeTitle, lessons } = themeObj;

    const ref = useRef();

    const [isAdd, setIsAdd] = useState(false);
    const [title, setTitle] = useState('');
    const [age, setAge] = useState();
    const [difficulty, setDifficulty] = useState(0);
    const [exam, setExam] = useState();
    const [isDisabled, setIsDisabled] = useState(false);

    const [addLesson, addLoading] = useFetching(async () => {
        if (!title) return;

        const order = lessons.length;

        const { data } = await ExerciseService.createLesson({
            title,
            lang,
            topic: themeTitle,
            difficulty,
            age,
            exam,
            order,
        });

        lessonsStore.addLesson(themeId, {
            id: data.id,
            title,
            lessons: [],
            age,
            difficulty,
            exam,
            order,
        });

        setIsAdd(false);

        onboardingAwaitKey && onboardingStore.moveToNextComponent();

        checkDifficultyConflict(difficulty);
    });

    const checkDifficultyConflict = (difficulty) => {
        if (kanbanDifficulty !== '') {
            const level = LanguageLevels[difficulty];
            if (level !== kanbanDifficulty) {
                lessonsStore.setDifficulty('');
            }
        }
    };

    const handleAddClick = () => {
        onClick();
        setIsAdd(true);
    };

    const handleAddClose = () => {
        if (onboardingAwaitKey) return;
        setIsAdd(false);
    };

    useEffect(() => {
        setTitle('');
        setAge();
        setDifficulty(0);
    }, [isAdd]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (onboardingAwaitKey) return;
            if (ref.current && !ref.current.contains(event.target)) {
                if (title && difficulty !== undefined) {
                    addLesson();
                }
                if (!onboardingAwaitKey) {
                    setIsAdd(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, title, age, difficulty, onboardingAwaitKey]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (title && difficulty !== undefined) {
                    addLesson();
                }
                if (!onboardingAwaitKey) {
                    setIsAdd(false);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [title, age, difficulty, exam, onboardingAwaitKey]);

    useEffect(() => {
        setIsAdd(forceAdd);
    }, [forceAdd]);

    useEffect(() => {
        if (onboardingAwaitKey) {
            setDifficulty(2);
        }
    }, [onboardingAwaitKey]);

    return (
        <div ref={ref} className={cl.buttonsContainer}>
            {isAdd ? (
                <OnboardingWrapper
                    onboardingKey={isLast ? onboardingAwaitKey : null}
                    delay={0}
                    title={t('teacher_onboarding.add_lesson_title')}
                    subtitle={t('teacher_onboarding.add_lesson_subtitle')}
                    showBlur={true}
                    bubblePosition={{
                        x: BubblePositions.x.Right,
                        y: BubblePositions.y.Center,
                    }}
                    preventClick
                >
                    <div className={cl.emptyLesson}>
                        <PrimaryInput
                            placeholder={t('exercises.lesson_title')}
                            style={{ width: '100%' }}
                            value={title}
                            onChange={setTitle}
                            autoFocus={true}
                        />
                        <div className={cl.selectContainer}>
                            <Select
                                variant={'grey'}
                                size={'narrow'}
                                label={t('users.level')}
                                value={difficulty}
                                options={LevelOptions}
                                onChange={setDifficulty}
                                hideReset
                                disabled={onboardingAwaitKey}
                            />
                            <Select
                                variant={'grey'}
                                size={'narrow'}
                                label={t('users.age')}
                                value={age}
                                options={AgeOptions}
                                onChange={setAge}
                            />
                            <Select
                                variant={'grey'}
                                size={'narrow'}
                                label={t('exercises.exam')}
                                value={exam}
                                options={getExamOptions(lang)}
                                onChange={setExam}
                            />
                        </div>
                        <div className={cl.buttons}>
                            <Button
                                text={t('exercises.add_lesson')}
                                onClick={addLesson}
                                variant={'purple'}
                                isLoading={addLoading}
                            />
                            <Button
                                icon={
                                    <IconClose
                                        style={{ minWidth: 14, marginLeft: -2 }}
                                    />
                                }
                                onClick={handleAddClose}
                                variant={'grey'}
                            />
                        </div>
                    </div>
                </OnboardingWrapper>
            ) : (
                <OnboardingWrapper
                    onboardingKey={isLast ? onboardingAddKey : null}
                    title={t('teacher_onboarding.add_first_lesson')}
                    subtitle={t('teacher_onboarding.press_to_continue')}
                    delay={1200}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Bottom,
                    }}
                    arrowPosition={ArrowPositions.Bottom}
                    onConfirm={handleAddClick}
                    bubbleAutoWidth
                >
                    <div className={`${cl.addButton}`} onClick={handleAddClick}>
                        {`+ ${t('exercises.add_lesson')}`}
                        {isDisabled && (
                            <Tooltip
                                variant={'warning'}
                                style={{ top: -45 }}
                                text={t('exercises.unselect_student')}
                                onHide={() => setIsDisabled(false)}
                            />
                        )}
                    </div>
                </OnboardingWrapper>
            )}
        </div>
    );
};

export default observer(AddLesson);
