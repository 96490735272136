import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import adminExercisesStore from '../../store/adminExercisesStore';
import { useFetching } from '../../../../hooks/useFetching';
import { Button } from '../../../../teacherComponents';
import { Checkbox } from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import ExerciseService from '../../../../api/ExerciseService';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
} from '../../../TeacherContent/views/ExercisesView/components';
import { MediaExerciseTypes } from '../../../TeacherContent/data/constants';
import cl from './ExerciseCard.module.css';

const ExerciseCard = ({ exercise }) => {
    const { t } = useTranslation();

    const {
        difficulty,
        lang,
        title,
        type,
        verified,
        text,
        trackId,
        trackInterval,
    } = exercise;

    const [verifyExercise] = useFetching(async (flag) => {
        await ExerciseService.verifyExercise({
            id: exercise.id,
            verified: flag,
        });

        adminExercisesStore.updateExercise({
            ...exercise,
            verified: flag,
        });
    });

    const handlePreviewClick = () => {
        adminExercisesStore.setCurrentExercise(exercise);
    };

    const getChapterType = () => {
        if (trackId && trackInterval) return 'video';
        if (Object.values(MediaExerciseTypes).includes(type)) return 'text';
        if (text) return 'text';
        return type;
    };

    return (
        <div className={cl.exerciseCard}>
            <div className={cl.preview}>
                <ExerciseIcon type={type} />
                <ExerciseMediaIcon type={getChapterType()} />
            </div>
            <div className={cl.cardInner}>
                <p className={cl.title}>
                    {title}
                    <span>{lang}</span>
                    <span>{LanguageLevels[difficulty]}</span>
                </p>
                <p>{t(`exercises.${type}`)}</p>
                <div className={cl.horizontalContainer}>
                    <Checkbox
                        label={t('exercises.verified')}
                        variant={'checkmark'}
                        value={verified}
                        onChange={verifyExercise}
                    />
                    <Button
                        variant={'purple'}
                        text={t('exercises.preview')}
                        onClick={handlePreviewClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(ExerciseCard);
