import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import SituationsService, { URL } from '../../../../api/SituationsService';
import situationsStore from '../../store/situationsStore';
import { PrimaryButton } from '../../../../UI';
import { ICONS } from '../../data/icons';
import cl from './SituationCard.module.css';

const SituationCard = ({ situation, style, showLanguage }) => {
    const { t } = useTranslation();
    const { id, title, picture, requestText, status, info, level, language } =
        situation;

    const handleSituationClick = () => {
        situationsStore.setCurrentSituation(situation);
    };

    const handleSituationDelete = async () => {
        await SituationsService.deleteSituationFromQueue({ id });
    };

    const formatModalitiesArray = (info, gender) =>
        info
            .filter((o) => o.gender === gender)
            .sort((a, b) => a.modality.localeCompare(b.modality));

    const renderInfoIcons = (info) => {
        const maleModalities = formatModalitiesArray(info, 'Male');
        const femaleModalities = formatModalitiesArray(info, 'Female');

        return (
            <>
                {maleModalities.length !== 0 && (
                    <div className={cl.infoObj}>
                        {'M'}
                        {maleModalities.map((o, i) => {
                            const modality = o.modality.toLowerCase();
                            return (
                                <div
                                    className={`${cl.icon} ${cl[modality]}`}
                                    key={`male_${i}`}
                                >
                                    {ICONS[modality]}
                                </div>
                            );
                        })}
                    </div>
                )}
                {femaleModalities.length !== 0 && (
                    <div className={cl.infoObj}>
                        {'F'}
                        {femaleModalities.map((o, i) => {
                            const modality = o.modality.toLowerCase();
                            return (
                                <div
                                    className={`${cl.icon} ${cl[modality]}`}
                                    key={`male_${i}`}
                                >
                                    {ICONS[modality]}
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    const renderLevels = (level) => {
        return level.map((l, i) => (
            <div className={cl.infoObj} key={`${l}_${i}`}>
                {l}
            </div>
        ));
    };

    if (status !== undefined)
        return (
            <div className={`${cl.entityCard} ${cl.loading}`} style={style}>
                <img src={`${URL}${picture}`} alt={title} />
                <p className={`${cl.entityTitle} ${cl.processed}`}>
                    {title || requestText}
                </p>
                <div className={cl.status}>
                    {showLanguage && (
                        <div className={cl.infoObj}>{language}</div>
                    )}
                    <div className={cl.infoObj}>{`Status: ${status}`}</div>
                </div>
                <div className={`${cl.infoCont} ${cl.top}`}>
                    {renderLevels([situation.level])}
                </div>
                <div className={`${cl.infoCont} ${cl.bottom}`}>
                    {renderInfoIcons([
                        {
                            gender: situation.gender,
                            modality: situation.modality || '',
                        },
                    ])}
                </div>
                {status === 'queue' && (
                    <PrimaryButton
                        style={{
                            position: 'absolute',
                            bottom: 3,
                            left: 3,
                            zIndex: 3,
                        }}
                        variant={'red'}
                        text={t('buttons.delete')}
                        onClick={handleSituationDelete}
                    />
                )}
            </div>
        );

    return (
        <div
            className={cl.entityCard}
            onClick={handleSituationClick}
            style={style}
        >
            <img src={`${URL}${picture}`} alt={title} />
            <p className={cl.entityTitle}>{title}</p>
            <div className={`${cl.infoCont} ${cl.top}`}>
                {renderLevels(level)}
            </div>
            <div className={`${cl.infoCont} ${cl.bottom}`}>
                {renderInfoIcons(info)}
            </div>
        </div>
    );
};

export default observer(SituationCard);
