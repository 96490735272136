import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Input,
    ToggleButton,
    Toolbar,
    ViewTitle,
} from '../../../../teacherComponents';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import PageWrapper from '../../../../components/UI/PageWrapper/PageWrapper';
import TrackService from '../../../../api/TrackService';
import userVideosStore from './store/userVideos';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { ContentContainer, UserChapterPreview } from '../../components';
import { ReactComponent as IconPlayer } from '../../../../assets/svg/lessons-track.svg';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { LanguageLevels } from '../../../../data/common';
import cl from './UserVideos.module.css';

const TRACKS_PER_PAGE = 50;

const UserVideos = () => {
    const { t, i18n } = useTranslation();
    const { language: lang } = i18n;

    const { currentTrack, difficulty, page, searchString, tracks } =
        userVideosStore;

    const [getTracks, isLoading] = useFetching(async () => {
        const params = {
            offset: page * TRACKS_PER_PAGE,
            limit: TRACKS_PER_PAGE,
            lang,
            categories: null,
            difficulty:
                difficulty !== null ? LanguageLevels.indexOf(difficulty) : null,
            searchString,
            recognized: true,
            processed: false,
        };
        const { data } = await TrackService.getHistory(params);
        userVideosStore.setTrackData(data);
    });

    useEffect(() => {
        getTracks();
    }, [page]);

    useEffect(() => {
        if (page !== 0) {
            userVideosStore.setPage(0);
        } else {
            getTracks();
        }
    }, [difficulty, searchString]);

    return (
        <PageWrapper additionalClass={cl.tracksWrapper}>
            <ViewTitle text={t('user_view.my_videos')} icon={<IconPlayer />} />
            <Toolbar>
                <Input
                    value={searchString}
                    onChange={userVideosStore.setSearchString}
                    placeholder={t('user_view.search')}
                />
                <ToggleButton
                    value={difficulty}
                    options={LanguageLevels}
                    onChange={userVideosStore.setDifficulty}
                    nullable
                />
            </Toolbar>
            <ContentContainer>
                <ChaptersList
                    chapters={tracks}
                    type={Chapters.Track}
                    current={currentTrack}
                    setCurrent={userVideosStore.setCurrentTrack}
                    isLoading={isLoading}
                />
                <UserChapterPreview
                    chapter={currentTrack}
                    type={Chapters.Track}
                />
            </ContentContainer>
        </PageWrapper>
    );
};

export default observer(UserVideos);
