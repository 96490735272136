import axios from 'axios';

const URL = 'recognition';

export default class RecognitionService {
    static async recognizeAudio(formData) {
        const response = await axios.post(`${URL}/recognize-audio`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    }
}
