import React, { useEffect, useRef, useState } from 'react';
import { useFetching } from '../../../../hooks/useFetching';
import { useTranslation } from 'react-i18next';
import { Checkbox, Loader, PrimaryButton, PrimaryInput } from '../../../../UI';
import PodcastService from '../../../../api/PodcastService';
import { NEWS_CATEGORY_ID, RATINGS, YT_URL } from '../../data/constants';
import { LanguageLevels } from '../../../../data/common';
import cl from './PodcastCard.module.css';

const PodcastCard = ({ podcastObj, onBlock, mode, currentLevel }) => {
    const { t } = useTranslation();
    const {
        name,
        description,
        image,
        id,
        customUrl,
        tracksCount,
        lang,
        presetCategories,
        tracksCountByDifficulties,
    } = podcastObj;
    const popupRef = useRef();
    const [rating, setRating] = useState(podcastObj.rating);
    const [promoted, setPromoted] = useState(podcastObj.rating !== 0);
    const [processed, setProcessed] = useState(podcastObj.processed);
    const [quantity, setQuantity] = useState('');
    const [calculatedQuantity, setCalculatedQuantity] = useState(null);
    const [recognizePopup, setRecognizePopup] = useState(false);
    const [ratingSelect, setRatingSelect] = useState(false);
    const [isNews, setIsNews] = useState(false);

    const handlePromotion = async () => {
        const res = await PodcastService.promotePodcast(id, rating);
        if (res.status === 200) {
            setPromoted(true);
            setRatingSelect(false);
        }
    };

    const handleUnpromotion = async () => {
        const res = await PodcastService.unpromotePodcast(id);
        if (res.status === 200) {
            setPromoted(false);
        }
    };

    const handleCheckboxClick = async () => {
        const newValue = !processed;
        setProcessed(newValue);
        PodcastService.updatePodcast(id, {
            processed: newValue,
        })
            .then((res) => {
                res.status !== 200 && setProcessed(!newValue);
            })
            .catch((_) => {
                setProcessed(!newValue);
            });
    };

    const handleNewsButton = async () => {
        if (isNews) {
            setIsNews(false);
            PodcastService.removeNewsCategory({ id })
                .then((res) => {
                    res.status !== 200 && setIsNews(true);
                })
                .catch((_) => {
                    setIsNews(true);
                });
        } else {
            setIsNews(true);
            PodcastService.addNewsCategory({ id })
                .then((res) => {
                    res.status !== 200 && setIsNews(false);
                })
                .catch((_) => {
                    setIsNews(false);
                });
        }
    };

    const handlePromoteButton = () => {
        if (promoted) {
            setRating(null);
            handleUnpromotion();
        } else {
            setRatingSelect(true);
        }
    };

    const [recognizeAll, recognizeLoading] = useFetching(async () => {
        const res = await PodcastService.recognizeAllPodcastTracks(
            id,
            quantity
        );
        if (res.status === 200) {
            handlePopupClose();
            const { data } = res;
            setCalculatedQuantity(data.count);
        }
    });

    const handleQunatityChange = (value) => {
        const match = value.match(/\d+/g);
        setQuantity(match ? match[0] : '');
    };

    const handlePopupClose = () => {
        setQuantity('');
        setRecognizePopup(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handlePopupClose();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    useEffect(() => {
        if (calculatedQuantity !== null) {
            setTimeout(() => {
                setCalculatedQuantity(null);
            }, 3000);
        }
    }, [calculatedQuantity]);

    useEffect(() => {
        setIsNews(
            presetCategories &&
                presetCategories.some((v) => v === NEWS_CATEGORY_ID)
        );
    }, [presetCategories]);

    return (
        <div className={cl.podcastCard}>
            <a
                className={cl.imgCont}
                href={`${YT_URL}/@${customUrl}/videos`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className={cl.podcastImg} src={image.md} alt={name} />
            </a>
            <div className={cl.podcastInfo}>
                <a
                    href={`${YT_URL}/@${customUrl}/videos`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cl.title}
                >
                    {name}
                </a>
                {mode === 'regular' && (
                    <>
                        <div className={cl.verticalBox}>
                            {tracksCount !== undefined && (
                                <>
                                    <div
                                        className={`${cl.infoText} ${cl.count}`}
                                    >
                                        {`${t('podcasts.quantity_label')}: `}
                                        <span>{tracksCount}</span>
                                    </div>
                                    {tracksCountByDifficulties && (
                                        <div
                                            className={
                                                cl.tracksCountByDifficulties
                                            }
                                        >
                                            {Object.keys(
                                                tracksCountByDifficulties
                                            ).map((i) => (
                                                <p
                                                    className={`${cl.infoText} ${
                                                        i ===
                                                        currentLevel.toString()
                                                            ? cl.active
                                                            : ''
                                                    }`}
                                                    key={i}
                                                >
                                                    <span>
                                                        {`${LanguageLevels[i]}: `}
                                                        {
                                                            tracksCountByDifficulties[
                                                                i
                                                            ]
                                                        }
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                            <p className={cl.infoText}>
                                {t('podcasts.language')}: <span>{lang}</span>
                            </p>
                            {promoted && (
                                <p className={cl.infoText}>
                                    {t('podcasts.promotion_rating')}:{' '}
                                    <span>{rating}</span>
                                </p>
                            )}
                            <p className={cl.infoText}>
                                {t('podcasts.processed')}:
                            </p>
                            <Checkbox
                                value={processed}
                                onChange={handleCheckboxClick}
                                style={{ marginLeft: -5 }}
                            />
                            <PrimaryButton
                                text={t(
                                    isNews
                                        ? 'podcasts.news'
                                        : 'podcasts.to_news'
                                )}
                                variant={isNews ? 'dark' : 'light'}
                                onClick={() => {
                                    handleNewsButton(id);
                                }}
                                style={{
                                    padding: '3px 6px',
                                    marginLeft: -5,
                                    fontSize: 12,
                                }}
                            />
                        </div>
                        <p className={cl.description}>{description}</p>
                        {ratingSelect ? (
                            <div className={cl.verticalBox}>
                                <PrimaryButton
                                    text={t('buttons.close')}
                                    onClick={() => setRatingSelect(false)}
                                />
                                <div className={cl.ratingCont}>
                                    {RATINGS.map((r) => (
                                        <span
                                            className={`${cl.ratingOption} ${
                                                rating === r ? cl.active : ''
                                            }`}
                                            key={r}
                                            onClick={() => setRating(r)}
                                        >
                                            {r}
                                        </span>
                                    ))}
                                </div>
                                <PrimaryButton
                                    text={t(
                                        promoted
                                            ? 'podcasts.promoted_btn'
                                            : 'podcasts.promote_btn'
                                    )}
                                    variant={'dark'}
                                    onClick={handlePromotion}
                                />
                            </div>
                        ) : (
                            <div className={cl.verticalBox}>
                                <PrimaryButton
                                    text={t(
                                        promoted
                                            ? 'podcasts.promoted_btn'
                                            : 'podcasts.promote_btn'
                                    )}
                                    variant={promoted ? 'dark' : 'light'}
                                    onClick={handlePromoteButton}
                                />
                                <PrimaryButton
                                    text={t('podcasts.block_btn')}
                                    onClick={() => {
                                        onBlock(id);
                                    }}
                                />
                                <div className={cl.recognizeBtnCont}>
                                    <PrimaryButton
                                        text={t('podcasts.recognize')}
                                        onClick={() => {
                                            setRecognizePopup(!recognizePopup);
                                        }}
                                    />

                                    {recognizePopup && (
                                        <div
                                            className={cl.popup}
                                            ref={popupRef}
                                        >
                                            {recognizeLoading ? (
                                                <Loader
                                                    style={{
                                                        transform:
                                                            'scale(0.5) translateY(-45%)',
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <PrimaryInput
                                                        placeholder={t(
                                                            'podcasts.quantity_label'
                                                        )}
                                                        value={quantity}
                                                        onChange={
                                                            handleQunatityChange
                                                        }
                                                    />
                                                    <div className={cl.buttons}>
                                                        <PrimaryButton
                                                            variant={'dark'}
                                                            onClick={
                                                                handlePopupClose
                                                            }
                                                            text={t(
                                                                'buttons.cancel'
                                                            )}
                                                        />

                                                        <PrimaryButton
                                                            variant={
                                                                quantity
                                                                    ? 'dark'
                                                                    : 'light'
                                                            }
                                                            onClick={
                                                                recognizeAll
                                                            }
                                                            isDisabled={
                                                                !quantity
                                                            }
                                                            text={t(
                                                                'podcasts.recognize'
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {calculatedQuantity !== null && (
                                        <div className={cl.quantityPopup}>
                                            <p>{`${t(
                                                'podcasts.quantity_label'
                                            )}: ${calculatedQuantity}`}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PodcastCard;
