import { LanguageLevels as LEVELS } from '../../../data/common';

const GENDERS = ['Male', 'Female'];
const LANGUAGES = ['EN', 'DE', 'RU', 'FR', 'IT', 'ES', 'PT'];

const MODALITIES = ['Friendly', 'Neutral', 'Rude'];

const AddSituationModes = {
    currentLevel: 'current_level',
    otherLevel: 'other_level',
};

export { AddSituationModes, GENDERS, LANGUAGES, LEVELS, MODALITIES };
