import React from 'react';
import cl from './Label.module.css';

const Label = ({ text, isError, style }) => {
    return (
        <p className={`${cl.label} ${isError ? cl.error : ''}`} style={style}>
            {text}
        </p>
    );
};

export default Label;
