import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import { Loader } from '../../../../../../UI';
import {
    CloseButton,
    Input,
    ToggleButton,
    Toolbar,
} from '../../../../../../teacherComponents';
import { LanguageLevels } from '../../../../../../data/common';
import { ReactComponent as IconFolder } from '../../../../../../assets/svg/lessons-folder.svg';
import { ReactComponent as IconEyes } from '../../../../../../assets/svg/lessons-eyes.svg';
import cl from './SelectTargetVocabulary.module.css';

const SelectTargetVocabulary = ({ visible, setVisible, onSelect }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const [categories, setCategories] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [difficulty, setDifficulty] = useState(null);

    const [getCategories, isLoading] = useFetching(async () => {
        const { data: categoriesData } =
            await ProgressService.getUserCategories({
                unassigned: false,
            });

        const categoriesWithWords = await Promise.all(
            categoriesData.items.map(async (category) => {
                const { data: categoryWordsData } =
                    await ProgressService.getCategoryWords({
                        categoryId: category.id,
                        lang,
                    });

                return {
                    ...category,
                    words: categoryWordsData.items,
                    wordsString: categoryWordsData.items
                        .map((w) => w.word)
                        .join(', '),
                };
            })
        );

        setCategories(categoriesWithWords);
    });

    const handleClick = (category) => {
        onSelect(category.wordsString);
        setVisible(false);
    };

    useEffect(() => {
        visible ? getCategories() : setCategories([]);
    }, [visible]);

    useEffect(() => {
        if (!categories.length) {
            setFiltered([]);
            return;
        }

        setFiltered(
            categories.filter(
                (c) =>
                    (difficulty !== null
                        ? c.level === LanguageLevels.indexOf(difficulty)
                        : true) &&
                    (searchString
                        ? c.title
                              .toLowerCase()
                              .includes(searchString.toLowerCase())
                        : true) &&
                    c.words.length !== 0
            )
        );
    }, [categories, difficulty, searchString]);

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            className={cl.selectPackModal}
        >
            <p className={cl.modalTitle}>{t('exercises.select_vocabulary')}</p>
            <Toolbar>
                <ToggleButton
                    value={difficulty}
                    onChange={setDifficulty}
                    options={LanguageLevels}
                    nullable
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                />
            </Toolbar>
            {isLoading ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <>
                    {filtered.length ? (
                        filtered.map((c) => (
                            <div
                                className={cl.categoryCard}
                                key={c.id}
                                onClick={() => handleClick(c)}
                            >
                                <div className={cl.preview}>
                                    <div className={cl.folder}>
                                        <IconFolder />
                                    </div>
                                    <p className={cl.count}>
                                        {c?.words?.length ?? '?'}
                                    </p>
                                </div>
                                <div className={cl.cardInner}>
                                    <p className={cl.title}>
                                        {c.title}
                                        <span>{LanguageLevels[c.level]}</span>
                                    </p>
                                    <div className={cl.words}>
                                        {c.words.map((w) => (
                                            <span
                                                className={cl.word}
                                                key={`${c.id}_${w.word}`}
                                            >
                                                {w.word}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : difficulty !== null || searchString !== '' ? (
                        <div className={cl.emptyAlert}>
                            <IconEyes />
                            <p className={cl.alertTitle}>
                                {t(`exercises.empty_search_words_title`)}
                            </p>
                            <p className={cl.alertSubtitle}>
                                {t(`exercises.empty_search_words_subtitle`)}
                            </p>
                        </div>
                    ) : (
                        <div className={cl.emptyAlert}>
                            <IconEyes />
                            <p className={cl.alertTitle}>
                                {t(`exercises.empty_fav_words_title`)}
                            </p>
                            <p className={cl.alertSubtitle}>
                                {t(`exercises.empty_fav_words_subtitle`)}
                            </p>
                        </div>
                    )}
                </>
            )}
            <CloseButton onClick={() => setVisible(false)} />
        </Modal>
    );
};

export default SelectTargetVocabulary;
