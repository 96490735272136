import React from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../App';
import { Login, Register } from '../../pages';
import Modal from '../UI/Modal/Modal';
import { AuthModes } from '../../store/auth';
import cl from './AuthModal.module.css';

const AuthModal = () => {
    const { isAuth, authMode } = authStore;

    return (
        <Modal
            visible={isAuth}
            setVisible={() => authStore.setIsAuth(false)}
            className={cl.authModal}
            addBlur
        >
            {isAuth && (
                <>{authMode === AuthModes.Login ? <Login /> : <Register />}</>
            )}
        </Modal>
    );
};

export default observer(AuthModal);
