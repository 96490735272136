import { makeAutoObservable } from 'mobx';

class UserVideosStore {
    page = 0;
    tracks = [];
    tracksCount = null;

    category = '';
    difficulty = null;
    searchString = '';
    paginationEnd = false;
    categoryOptions = [];

    currentTrack = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setTracks(tracks) {
        this.tracks = tracks;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setPage(page) {
        this.page = page;
    }

    setNextPage() {
        this.setPage(this.page + 1);
    }

    setTracksCount(tracksCount) {
        this.tracksCount = tracksCount;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setCategory(category) {
        this.category = category;
    }

    setPaginationEnd(paginationEnd) {
        this.paginationEnd = paginationEnd;
    }

    setCategoryOptions(data) {
        const options = data
            .map((cObj) => ({
                value: cObj.id,
                name: cObj.translation || cObj.title,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        this.categoryOptions = options;
    }

    setTrackData(data) {
        const { items, total } = data;
        if (!items.length) {
            this.setPaginationEnd(true);
        }
        this.setTracksCount(total);
        const tracks = (
            this.page > 0 ? [...this.tracks, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        this.setTracks(tracks);
    }

    setCurrentTrack(currentTrack) {
        this.currentTrack = currentTrack;
    }

    resetSearch() {
        this.setPaginationEnd(false);
        this.setPage(0);
        this.setTracks([]);
    }
}

const userVideosStore = new UserVideosStore();
export default userVideosStore;
