import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSearchBar } from './components';
import {
    DictionaryView,
    ExercisesView,
    SituationsView,
    TrackView,
} from './views';
import { ViewTitle } from '../../teacherComponents';
import { iconMap, titleMap } from './views/ExercisesView/data/constants';
import { Chapters } from '../TeacherLessons/data/constants';

const TeacherContent = ({ type }) => {
    const { t } = useTranslation();

    return (
        <>
            <ViewTitle text={t(titleMap[type])} icon={iconMap[type]} />
            <ContentSearchBar />
            <TrackView isActive={type === Chapters.Track} />
            <SituationsView />
            <DictionaryView />
            <ExercisesView />
        </>
    );
};

export default TeacherContent;
