import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import languagesStore from '../../../../store/interface';
import { useFetching } from '../../../../hooks/useFetching';
import { Loader, PrimaryButton } from '../../../../UI';
import { Select, ToggleButton, Toolbar } from '../../../../teacherComponents';
import TrackCard from '../TrackCard/TrackCard';
import Modal from '../../../../components/UI/Modal/Modal';
import TrackService from '../../../../api/TrackService';
import { LanguageLevels } from '../../../../data/common';
import cl from './PriorityTracks.module.css';

const DefaultLevel = 'A1';
const DefaultLanguage = 'en';

const PriorityTracks = () => {
    const { t } = useTranslation();

    const { languages } = languagesStore;

    const LANGUAGE_OPTIONS = [...languages];

    const [visible, setVisible] = useState(false);
    const [lang, setLang] = useState(DefaultLanguage);
    const [level, setLevel] = useState(DefaultLevel);
    const [tracks, setTracks] = useState([]);

    const [getPriorityTracks, isLoading] = useFetching(async () => {
        const { data } = await TrackService.getPriorityTracks({
            lang,
            difficulty: LanguageLevels.indexOf(level),
        });
        setTracks(data.items);
    });

    const updateTrack = (id, { difficulty, processed, categories, lang }) => {
        const newTracks = tracks.map((t) =>
            t.id === id
                ? {
                      ...t,
                      difficulty: difficulty ?? t.difficulty,
                      processed: processed ?? t.processed,
                      categories: categories ?? t.categories,
                      lang: lang ?? t.lang,
                  }
                : t
        );
        setTracks(newTracks);
    };

    const removeTrack = (id) => {
        setTracks(tracks.filter((t) => t.id !== id));
    };

    useEffect(() => {
        setLevel(DefaultLevel);
        setLang(DefaultLanguage);
        setTracks([]);
        if (visible) {
            getPriorityTracks();
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            getPriorityTracks();
        }
    }, [lang, level]);

    return (
        <>
            <PrimaryButton
                text={t('library.priority_tracks')}
                variant={'magenta'}
                onClick={() => setVisible(true)}
            />
            <Modal
                visible={visible}
                setVisible={setVisible}
                className={cl.priorityTracksModal}
            >
                <Toolbar>
                    <p>{t('library.priority_tracks')}</p>
                    <Select
                        height={300}
                        value={lang}
                        options={LANGUAGE_OPTIONS}
                        onChange={setLang}
                        variant={'white'}
                        ignoreOverflow
                    />
                    <ToggleButton
                        style={{ marginLeft: 0, marginRight: 'auto' }}
                        value={level}
                        options={LanguageLevels}
                        onChange={setLevel}
                    />
                </Toolbar>
                {isLoading && (
                    <Loader
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
                <div className={cl.tracksList}>
                    {tracks.length !== 0 ? (
                        <div className={cl.listInner}>
                            {tracks.map((t) => (
                                <TrackCard
                                    key={t.id}
                                    trackData={t}
                                    onChange={updateTrack}
                                    onDelete={removeTrack}
                                    showRating={true}
                                    priorityLevel={level}
                                />
                            ))}
                        </div>
                    ) : (
                        <p className={cl.emptyAlert}>
                            {t('errors.tracks_filter')}
                        </p>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default PriorityTracks;
