import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Chapters } from '../../../TeacherLessons/data/constants';
import exercisesStore from '../../store/exercisesStore';
import { ReactComponent as DictionaryIcon } from '../../../../assets/svg/dictionary-icon.svg';
import { ReactComponent as ExercisesIcon } from '../../../../assets/svg/exercises-icon.svg';
import { ReactComponent as SituationsIcon } from '../../../../assets/svg/situations-icon.svg';
import { ReactComponent as TrackIcon } from '../../../../assets/svg/videos-icon.svg';

import cl from './CreateView.module.css';

const CreateView = () => {
    const { t } = useTranslation();

    const views = {
        [Chapters.Track]: <TrackIcon />,
        [Chapters.Dictionary]: <DictionaryIcon />,
        [Chapters.Situation]: <SituationsIcon />,
        [Chapters.Exercise]: <ExercisesIcon />,
    };

    return (
        <div className={cl.sectionsContainer}>
            {Object.keys(views).map((s) => (
                <div
                    className={cl.sectionCard}
                    key={s}
                    onClick={() => exercisesStore.setView(s)}
                >
                    <p> {t(`exercises.${s}`)}</p>
                    {views[s]}
                </div>
            ))}
        </div>
    );
};

export default observer(CreateView);
