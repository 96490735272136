import React from 'react';
import { iconMap } from '../../../TeacherContent/views/ExercisesView/data/constants';
import cl from './ChapterPreview.module.css';

const ChapterPreview = ({ chapter, variant }) => {
    const { title, type } = chapter;
    return (
        <div className={`${cl.chapter} ${cl[variant]}`}>
            <div className={cl.icon}>{iconMap[type]}</div>
            <p className={cl.title}>{title}</p>
        </div>
    );
};

export default ChapterPreview;
