import { makeAutoObservable } from 'mobx';
import UserService from '../api/UserService';
import { Roles } from '../data/common';
import { ROUTES } from '../router';
import { ProcessedWordsKey } from '../pages/TeacherContent/views/DictionaryView/data/constants';

export const AuthModes = {
    Login: 'login',
    Register: 'register',
};

// TODO: temporary solution. remove or refactor after marketing research @saratovkin
const MarketingEmail = 'marketing@thepony.app';
const DefaultUserLanguage = 'en';

export default class AuthStore {
    user = {};
    role = Roles.Guest;
    isAuth = false;
    authMode = AuthModes.Login;

    constructor(languagesStore) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.languagesStore = languagesStore;
    }

    setAuthMode(authMode) {
        this.authMode = authMode;
    }

    setRole(role) {
        this.role = role;
    }

    setIsAuth(flag) {
        this.isAuth = flag;
    }

    setUser(data) {
        const user = this.mapUserData(data);

        this.user = user;
        this.syncLanguages(user);
        // TODO: temporary solution. remove or refactor after marketing research @saratovkin
        this.setRole(
            user.email === MarketingEmail ? Roles.Marketing : user.role
        );
    }

    syncLanguages({ role, lang, nativeLang }) {
        this.languagesStore.setNativeLang(
            role === Roles.User ? nativeLang : lang
        );
        this.languagesStore.setLang(lang);
    }

    mapUserData(data) {
        return {
            email: data.email,
            id: data.id,
            isActivated: true,
            isBlocked: data.isBanned,
            lang: data.lang || DefaultUserLanguage,
            nativeLang: data.nativeLang || DefaultUserLanguage,
            name: data.name,
            role: data.role || Roles.User,
        };
    }

    async checkAuth() {
        try {
            const res = await UserService.getMe();
            if (res.status === 200 && res.data?.id) {
                this.setUser(res.data);
            }
        } catch {
            this.setIsAuth(false);
        }
    }

    async logout(navigate) {
        localStorage.setItem(ProcessedWordsKey, '[]');
        this.setUser({ lang: DefaultUserLanguage, role: Roles.Guest });
        this.setIsAuth(false);
        navigate(ROUTES.login);
    }

    async login(email, password, navigate) {
        try {
            const res = await UserService.login(email, password);
            this.setIsAuth(false);
            if (res.status === 204) {
                await this.checkAuth();
                navigate(ROUTES.dashboard);
            }
        } catch {
            this.setIsAuth(false);
        }
    }
}
