import React from 'react';
import { PrimaryInput } from '..';
import cl from './LabeledInput.module.css';

const LabeledInput = ({
    placeholder,
    value,
    onChange,
    onEnterKey,
    onBlur,
    style,
    type,
    color,
    disabled,
    label,
}) => {
    return (
        <div>
            <p className={cl.label}>{label}</p>
            <PrimaryInput
                placeholder={placeholder}
                value={value}
                onEnterKey={onEnterKey}
                onBlur={onBlur}
                onChange={onChange}
                type={type}
                color={color}
                disabled={disabled}
                style={style}
            />
        </div>
    );
};

export default LabeledInput;
