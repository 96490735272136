import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
    ViewTitle,
} from '../../../../teacherComponents';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import PageWrapper from '../../../../components/UI/PageWrapper/PageWrapper';
import languagesStore from '../../../../store/interface';
import userExercisesStore from './store/userExercises';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { ContentContainer, UserChapterPreview } from '../../components';
import { ReactComponent as IconExercise } from '../../../../assets/svg/lessons-exercise.svg';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { LanguageLevels } from '../../../../data/common';
import ExerciseService from '../../../../api/ExerciseService';
import { ExerciseTypes } from '../../../../pages/ExercisesAdministration/data/constants';
import cl from './UserExercises.module.css';

const UserExercises = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const { currentExercise, difficulty, page, type, searchString, exercises } =
        userExercisesStore;

    const typeOptions = ExerciseTypes.map((type) => ({
        value: type,
        name: t(`exercises.${type}`),
    }));

    const [getExercises, isLoading] = useFetching(async () => {
        const params = {
            offset: 0,
            limit: 500,
            lang,
            searchString,
            type,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
        };
        const { data } = await ExerciseService.getExercises(params);
        userExercisesStore.setExercisesData(data);
    });

    useEffect(() => {
        getExercises();
    }, [page]);

    useEffect(() => {
        getExercises();
    }, [difficulty, searchString, type]);

    return (
        <PageWrapper additionalClass={cl.exercisesWrapper}>
            <ViewTitle
                text={t('user_view.my_exercises')}
                icon={<IconExercise />}
            />
            <Toolbar>
                <Input
                    value={searchString}
                    onChange={userExercisesStore.setSearchString}
                    placeholder={t('user_view.search')}
                />
                <Select
                    style={{ minWidth: 200 }}
                    variant={'white'}
                    value={type}
                    options={typeOptions}
                    onChange={userExercisesStore.setType}
                    label={t('exercises.type')}
                />
                <ToggleButton
                    value={difficulty}
                    options={LanguageLevels}
                    onChange={userExercisesStore.setDifficulty}
                    nullable
                />
            </Toolbar>
            <ContentContainer>
                <ChaptersList
                    chapters={exercises}
                    type={Chapters.Exercise}
                    current={currentExercise}
                    setCurrent={userExercisesStore.setCurrentExercise}
                    isLoading={isLoading}
                />
                <UserChapterPreview
                    chapter={currentExercise}
                    type={Chapters.Exercise}
                />
            </ContentContainer>
        </PageWrapper>
    );
};

export default observer(UserExercises);
