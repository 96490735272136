import React, { useEffect, useState } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../../TeacherLessons/store/lessonsStore';
import studentsStore from '../../store/studentsStore';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import { Loader } from '../../../../UI';
import { StudentTheme } from '..';
import { LanguageLevels } from '../../../../data/common';
import { StudentLessonStatuses } from '../../../TeacherLessons/data/constants';
import { ChapterPlug } from '../../../TeacherContent/components';
import { MockOnboardingStudentId } from '../../data';
import onboardingStore from '../../../../store/onboarding';
import cl from './StudentKanban.module.css';
import {
    BubblePositions,
    OnboardingKeys,
    OnboardingWrapper,
} from '../../../../teacherComponents/Onboarding';

const StudentKanban = () => {
    const { t, i18n } = useTranslation();

    const { language: lang } = i18n;

    const { themes: teacherThemes } = lessonsStore;
    const { currentStudent, lessonSearchString, lessonTheme } = studentsStore;
    const { id: studentId, level: studentLevel } = currentStudent;

    const [studentLessons, setStudentLessons] = useState([]);
    const [teacherLessons, setTeacherLessons] = useState([]);
    const [groupedLessons, setGroupedLessons] = useState([]);
    const [filteredLessons, setFilteredLessons] = useState([]);

    const [getStudentLessons, lessonsLoading] = useFetching(async () => {
        const { data } = await ProgressService.getStudentLessons({
            userId: studentId,
            lang,
        });

        setStudentLessons(data?.items ?? []);
        lessonsStore.setCurrentStudentLessons(data?.items ?? []);
    }, true);

    const [getStudentChapters] = useFetching(async () => {
        const { data: trackData } = await ProgressService.getStudentTracks({
            userId: studentId,
            lang,
        });
        const { data: exerciseData } =
            await ProgressService.getStudentExercises({
                userId: studentId,
                lang,
            });
        const { data: situationData } =
            await ProgressService.getStudentSituations({
                userId: studentId,
                lang,
            });
        const { data: wordData } = await ProgressService.getStudentDictionaries(
            {
                userId: studentId,
                lang,
            }
        );
        exercisesStore.setCurrentStudentChapters({
            situations: situationData?.items ?? [],
            tracks: trackData?.items ?? [],
            exercises: exerciseData?.items ?? [],
            dictionaries: wordData?.items ?? [],
            words: [],
        });
    });

    const extractLessonsFromThemes = (themes) =>
        themes.flatMap((theme) => theme.lessons);

    const formatGroupedLessons = () => {
        const teacherLessons = extractLessonsFromThemes(teacherThemes);

        const studentLessonsByStatus = studentLessons.reduce(
            (acc, lesson) => {
                if (lesson.status === StudentLessonStatuses.Unfinished) {
                    acc.unfinished.push(lesson.lessonId);
                } else if (lesson.status === StudentLessonStatuses.Done) {
                    acc.completed.push(lesson.lessonId);
                }
                return acc;
            },
            { unfinished: [], completed: [] }
        );

        const availableLessons = teacherLessons.filter(
            (lesson) =>
                lesson.difficulty === LanguageLevels.indexOf(studentLevel) &&
                !studentLessonsByStatus.unfinished.includes(lesson.id) &&
                !studentLessonsByStatus.completed.includes(lesson.id)
        );

        const createGroupedLessons = (status, title, lessonIds) => ({
            id: status,
            title: t(title),
            lessons: teacherLessons
                .filter((lesson) => lessonIds.includes(lesson.id))
                .map((lesson) => ({ ...lesson, status })),
        });

        setGroupedLessons([
            createGroupedLessons(
                StudentLessonStatuses.Available,
                'exercises.available',
                availableLessons.map((l) => l.id)
            ),
            createGroupedLessons(
                StudentLessonStatuses.Unfinished,
                'exercises.in_process',
                studentLessonsByStatus.unfinished
            ),
            createGroupedLessons(
                StudentLessonStatuses.Done,
                'exercises.done',
                studentLessonsByStatus.completed
            ),
        ]);

        formatThemeOptions(availableLessons);
    };

    const formatThemeOptions = (availableLessons) => {
        const uniqueThemes = Array.from(
            new Set(availableLessons.map((l) => l.topic))
        );

        studentsStore.setLessonThemeOptions(
            uniqueThemes.map((t) => ({ value: t, name: t }))
        );
    };

    const setEmptyGroupedLessons = () => {
        setGroupedLessons([
            {
                id: StudentLessonStatuses.Available,
                title: t('exercises.available'),
                lessons: [],
            },
            {
                id: StudentLessonStatuses.Unfinished,
                title: t('exercises.in_process'),
                lessons: [],
            },
            {
                id: StudentLessonStatuses.Done,
                title: t('exercises.done'),
                lessons: [],
            },
        ]);
    };

    const evaluateLesson = async ({ lessonId, status }) => {
        const movedLesson = groupedLessons[1].lessons.find(
            (l) => l.id === lessonId
        );

        setGroupedLessons(
            groupedLessons.map((g) =>
                g.id === StudentLessonStatuses.Unfinished
                    ? {
                          ...g,
                          lessons: g.lessons.filter((l) => l.id !== lessonId),
                      }
                    : g.id === StudentLessonStatuses.Done
                      ? {
                            ...g,
                            lessons: [
                                {
                                    ...movedLesson,
                                    status: StudentLessonStatuses.Done,
                                },
                                ...g.lessons,
                            ],
                        }
                      : g
            )
        );
        await ProgressService.evaluateLesson({
            studentId,
            lessonId,
            status,
        });
    };

    const assingLesson = async ({ lessonId }) => {
        const movedLesson = groupedLessons[0].lessons.find(
            (l) => l.id === lessonId
        );
        setGroupedLessons(
            groupedLessons.map((g) =>
                g.id === StudentLessonStatuses.Available
                    ? {
                          ...g,
                          lessons: g.lessons.filter((l) => l.id !== lessonId),
                      }
                    : g.id === StudentLessonStatuses.Unfinished
                      ? {
                            ...g,
                            lessons: [
                                {
                                    ...movedLesson,
                                    status: StudentLessonStatuses.Unfinished,
                                },
                                ...g.lessons,
                            ],
                        }
                      : g
            )
        );

        if (studentId === MockOnboardingStudentId) {
            onboardingStore.moveToNextComponent();
            return;
        }

        await ProgressService.assignLesson({
            studentId,
            lessonId,
        });

        await ProgressService.sendLessonData({
            studentId,
            lessonId,
        });
    };

    const changeLessonStatus = async ({ lessonId, oldStatus, newStatus }) => {
        if (
            oldStatus === StudentLessonStatuses.Unfinished &&
            newStatus === StudentLessonStatuses.Done
        ) {
            evaluateLesson({ lessonId, status: newStatus });
        }
        if (
            oldStatus === StudentLessonStatuses.Available &&
            newStatus === StudentLessonStatuses.Unfinished
        ) {
            assingLesson({ lessonId });
        }
    };

    const handleLessonDrag = async (result) => {
        const { destination, draggableId, source } = result;
        if (!source || !destination) return;

        const sourceTheme = groupedLessons.find(
            (g) => g.id === source.droppableId
        );
        const destinationTheme = groupedLessons.find(
            (g) => g.id === destination.droppableId
        );

        if (!sourceTheme || !destinationTheme) return;

        changeLessonStatus({
            lessonId: draggableId,
            oldStatus: sourceTheme.id,
            newStatus: destinationTheme.id,
        });
    };

    const filterLessons = () => {
        const filteredLessons = groupedLessons.map((g) => ({
            ...g,
            lessons: g.lessons
                .filter((l) => !lessonTheme || l.topic === lessonTheme)
                .filter(
                    (l) =>
                        !lessonSearchString ||
                        l.title
                            .toLowerCase()
                            .includes(lessonSearchString.toLowerCase())
                ),
        }));

        setFilteredLessons(filteredLessons);
    };

    useEffect(() => {
        if (!studentId) return;
        getStudentLessons();
        getStudentChapters();
    }, [studentId]);

    useEffect(() => {
        if (!teacherLessons.length) {
            setEmptyGroupedLessons();
        } else {
            formatGroupedLessons();
        }
    }, [currentStudent, studentLessons, teacherLessons]);

    useEffect(() => {
        if (!teacherThemes.length) {
            setTeacherLessons([]);
        } else {
            setTeacherLessons(extractLessonsFromThemes(teacherThemes));
        }
    }, [teacherThemes]);

    useEffect(() => {
        filterLessons();
    }, [lessonSearchString, lessonTheme, groupedLessons]);

    if (!studentId) return <ChapterPlug />;

    return (
        <DragDropContext onDragEnd={handleLessonDrag}>
            {lessonsLoading ? (
                <Loader style={{ margin: 'auto', height: '100%' }} />
            ) : (
                <OnboardingWrapper
                    onboardingKey={onboardingStore.isOnboarding(
                        OnboardingKeys.AwaitLessonDrag
                    )}
                    title={t('teacher_onboarding.drag_lesson_title')}
                    subtitle={t('teacher_onboarding.drag_lesson_subtitle')}
                    delay={600}
                    bubblePosition={{
                        x: BubblePositions.x.Left,
                        y: BubblePositions.y.TopBorder,
                    }}
                    preventClick
                >
                    <div className={cl.kanban}>
                        {filteredLessons.map((t) => (
                            <StudentTheme key={t.id} themeObj={t} />
                        ))}
                    </div>
                </OnboardingWrapper>
            )}
        </DragDropContext>
    );
};

export default observer(StudentKanban);
