import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import usePageTitle from '../hooks/usePageTitle';
import { authStore } from '../App';
import { getDefaultRouteByRole, getRoutesByRole } from '.';

const AppRouter = () => {
    const { role } = authStore;
    const routes = getRoutesByRole(role);
    const defaultRoute = getDefaultRouteByRole(role);

    usePageTitle(routes);

    return (
        <Routes>
            {routes.map((route) => (
                <Route
                    path={route.path}
                    exact={route.exact}
                    element={route.component}
                    key={route.path}
                />
            ))}
            <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
    );
};

export default observer(AppRouter);
