import React from 'react';
import { TeacherCard } from '..';
import cl from './TeachersGrid.module.css';

const TeachersGrid = ({ items }) => {
    return (
        <div className={cl.grid}>
            {items.map((t) => (
                <TeacherCard key={t.id} teacher={t} />
            ))}
        </div>
    );
};

export default TeachersGrid;
