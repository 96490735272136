import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import lessonsStore from '../../../TeacherLessons/store/lessonsStore';
import onboardingStore from '../../../../store/onboarding';
import Modal from '../../../../components/UI/Modal/Modal';
import { Button } from '../../../../teacherComponents';
import cl from './AddChapterInfo.module.css';

const AddChapterInfo = ({ type }) => {
    const { t } = useTranslation();

    const { currentLesson } = lessonsStore;
    const { isCompleted } = onboardingStore;

    const [isExample, setIsExample] = useState(false);
    const [wasDisplayed, setWasDisplayed] = useState(false);

    const videoSrc = require(`../../../../assets/mp4/teacher_${type}_demo.mp4`);

    const handleClose = () => {
        setWasDisplayed(true);
        localStorage.setItem(`${type}InfoModal`, JSON.stringify(true));
    };

    useEffect(() => {
        const storedFlag = JSON.parse(localStorage.getItem(`${type}InfoModal`));
        const flag = storedFlag ? JSON.parse(storedFlag) : false;

        setWasDisplayed(flag);
    }, [type]);

    if (!currentLesson || !isCompleted || wasDisplayed) return null;

    return (
        <Modal visible={true} setVisible={() => {}} className={cl.intro}>
            <p className={cl.title}>
                {t(`teacher_onboarding.lesson_add_${type}_title`)}
            </p>
            <div className={cl.subtitle}>
                {t(`teacher_onboarding.lesson_add_${type}_subtitle`)}
                <div className={cl.exmapleButtonWrapper}>
                    <Button
                        variant={'white'}
                        text={t(`teacher_onboarding.lesson_how_it_works`)}
                        onClick={() => setIsExample(true)}
                    />
                </div>
            </div>

            <Button
                variant={'purple'}
                text={t('teacher_onboarding.close_button')}
                onClick={handleClose}
            />
            {isExample && videoSrc && (
                <Modal
                    visible={isExample}
                    setVisible={setIsExample}
                    className={cl.videoContainer}
                >
                    <video src={videoSrc} style={{ width: '100%' }} autoPlay />
                </Modal>
            )}
        </Modal>
    );
};

export default observer(AddChapterInfo);
