import React from 'react';
import { observer } from 'mobx-react-lite';
import adminExercisesStore from '../../store/adminExercisesStore';
import { CloseButton } from '../../../../teacherComponents';
import { Preview } from '../../../TeacherContent/views/ExercisesView/components';
import Modal from '../../../../components/UI/Modal/Modal';
import cl from './ExercisePreview.module.css';

const ExercisePreview = () => {
    const { currentExercise } = adminExercisesStore;

    const handleClose = () => adminExercisesStore.setCurrentExercise(null);

    return (
        <Modal
            className={cl.exercisePreview}
            visible={currentExercise}
            setVisible={handleClose}
        >
            <Preview exerciseId={currentExercise.id} />
            <CloseButton onClick={handleClose} />
        </Modal>
    );
};

export default observer(ExercisePreview);
