import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useFetching } from '../../hooks/useFetching';
import ExerciseService from '../../api/ExerciseService';
import exercisesStore from '../TeacherContent/store/exercisesStore';
import lessonsStore from './store/lessonsStore';
import onboardingStore from '../../store/onboarding';
import teachersStore from '../Teachers/store/teachersStore';
import {
    AddTheme,
    Theme,
    EmptyTeacherLessons,
    LessonSearchBar,
    LessonsTitle,
} from './components';
import { Loader } from '../../UI';
import { filterThemes } from './helpers';
import { Views } from './data/constants';
import { ViewTitle } from '../../teacherComponents';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../teacherComponents/Onboarding';
import cl from './TeacherLessons.module.css';

const TeacherLessons = () => {
    const kanbanRef = useRef(null);

    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { currentTeacher } = teachersStore;

    const {
        searchString,
        age,
        difficulty,
        filterByStudent,
        themes,
        teacherThemes,
        isAddLesson,
        currentStudentLessons,
    } = lessonsStore;

    const { currentStudent, view } = exercisesStore;

    const [wasFetched, setWasFetched] = useState(false);
    const [filteredThemes, setFilteredThemes] = useState(themes);
    const [filteredTeacherThemes, setFilteredTeacherThemes] =
        useState(teacherThemes);

    const isTeacher = view === Views.Teachers && currentTeacher;

    const [getLessons, lessonsLoading, error, resetError] = useFetching(
        async () => {
            lessonsStore.setThemes([]);
            resetError();
            const { data } = await ExerciseService.getSavedLessons({
                offset: 0,
                limit: 100,
                lang,
            });
            setWasFetched(true);
            syncLessons(data?.items);
        }
    );

    const [getTeacherLessons, teacherLessonsLoading] = useFetching(async () => {
        if (!currentTeacher || !currentTeacher.id) return;
        const { data } = await ExerciseService.getTeacherLessons({
            id: currentTeacher.id,
        });
        setWasFetched(true);
        if (data && data.items) {
            const teacherLessons = await Promise.all(
                data.items
                    .filter((l) => l.lang === lang)
                    .map(async (item) => {
                        const lessonData = await ExerciseService.getLesson({
                            id: item.id,
                        });
                        return { ...item, chapters: lessonData.data.chapters };
                    })
            );
            syncTeacherLessons(teacherLessons);
        }
    });

    const [updateLesson] = useFetching(async ({ id, topic }) => {
        await ExerciseService.updateLesson({ id, topic });
    });

    const [updateLessonOrder] = useFetching(async ({ id, order }) => {
        await ExerciseService.updateLessonOrder({ id, order });
    });

    const parseLessonsIntoThemes = (lessons) => {
        const themeTitles = Array.from(new Set(lessons.map((l) => l.topic)));
        const themes = themeTitles.map((t) => ({
            id: uuid(),
            title: t,
            lessons: lessons
                .filter((l) => l.topic === t)
                .map((l) => ({
                    ...l,
                    chapters: l.chapters.map((c) => ({
                        ...c,
                        id: c.chapterId,
                    })),
                }))
                .sort((a, b) => a.order - b.order),
        }));
        return { themes };
    };

    const syncLessonsOrder = (lessonsByTheme) => {
        // TODO find more optimal way to update lessons order @saratovkin
        for (const lessons of lessonsByTheme) {
            lessons.forEach((l, i) => {
                updateLessonOrder({ id: l.id, order: i });
            });
        }
    };

    const syncLessons = (lessons) => {
        const { themes } = parseLessonsIntoThemes(lessons);
        lessonsStore.setThemes(themes);
    };

    const syncTeacherLessons = (lessons) => {
        const { themes } = parseLessonsIntoThemes(lessons);
        lessonsStore.setTeacherThemes(themes);
    };

    const handleLessonDrag = async (result) => {
        const { destination, draggableId, source } = result;

        if (!source || !destination) return;

        const sourceTheme = themes.find((t) => t.id === source.droppableId);
        const destinationTheme = themes.find(
            (t) => t.id === destination.droppableId
        );

        if (!sourceTheme || !destinationTheme) return;

        if (sourceTheme.id === destinationTheme.id) {
            const newLessons = [...sourceTheme.lessons];
            const [movedLesson] = newLessons.splice(source.index, 1);
            newLessons.splice(destination.index, 0, movedLesson);
            lessonsStore.setLessons(sourceTheme.id, newLessons);
            syncLessonsOrder([newLessons]);
            return;
        }

        const movedLesson = sourceTheme.lessons.find(
            (l) => l.id === draggableId
        );
        updateLesson({ id: movedLesson.id, topic: destinationTheme.title });
        const newSourceLessons = sourceTheme.lessons.filter(
            (l) => l.id !== draggableId
        );

        lessonsStore.setLessons(sourceTheme.id, newSourceLessons);

        const newDestinationLessons = [...destinationTheme.lessons];
        newDestinationLessons.splice(destination.index, 0, movedLesson);
        lessonsStore.setLessons(destinationTheme.id, newDestinationLessons);
        syncLessonsOrder([newSourceLessons, newDestinationLessons]);
    };

    useEffect(() => {
        const filtered = filterThemes(themes, {
            age,
            difficulty,
            selected: filterByStudent ? currentStudentLessons : null,
            searchString,
        });
        setFilteredThemes(filtered);
    }, [
        age,
        difficulty,
        searchString,
        currentStudentLessons,
        filterByStudent,
        themes,
    ]);

    useEffect(() => {
        const filtered = filterThemes(teacherThemes, {
            age,
            difficulty,
            searchString,
        });
        setFilteredTeacherThemes(filtered);
    }, [age, difficulty, searchString, teacherThemes]);

    useEffect(() => {
        currentTeacher ? getTeacherLessons() : getLessons();
        return () => {
            exercisesStore.setCurrentStudent(null);
        };
    }, [currentTeacher]);

    useEffect(() => {
        if (isAddLesson && kanbanRef.current) {
            kanbanRef.current.scrollLeft = kanbanRef.current.scrollWidth;
        }
    }, [isAddLesson]);

    useEffect(() => {
        lessonsStore.setDifficulty('');
        lessonsStore.setAge('');
        lessonsStore.setSearchString('');
        lessonsStore.setFilterByStudent(false);
    }, [currentStudent]);

    return (
        <>
            {(isTeacher ? teacherLessonsLoading : lessonsLoading) ||
            (!wasFetched && !error) ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <>
                    {isTeacher ? (
                        <LessonsTitle />
                    ) : (
                        <ViewTitle
                            text={t(`navbar.lessons`)}
                            style={{ marginBottom: 12 }}
                        />
                    )}
                    <LessonSearchBar
                        style={{ marginBottom: isTeacher ? -12 : '' }}
                    />
                    <DragDropContext onDragEnd={handleLessonDrag}>
                        <div className={cl.kanban} ref={kanbanRef}>
                            {(isTeacher
                                ? filteredTeacherThemes
                                : filteredThemes
                            )?.map((theme, index) => (
                                <Theme
                                    key={theme.id}
                                    themeObj={theme}
                                    isLast={index === filteredThemes.length - 1}
                                />
                            ))}
                            {!isTeacher && <AddTheme />}
                            {isTeacher &&
                                filteredTeacherThemes.length === 0 && (
                                    <EmptyTeacherLessons />
                                )}
                        </div>
                    </DragDropContext>
                </>
            )}
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.LessonsInfo
                )}
                title={t('teacher_onboarding.lessons_info')}
                subtitle={t('teacher_onboarding.lessons_info_subtitle')}
                delay={500}
            />
        </>
    );
};

export default observer(TeacherLessons);
