import React from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import { DownloadButtons, Typography } from '../';
import number1 from '../../../../assets/webp/number_1.webp';
import number2 from '../../../../assets/webp/number_2.webp';
import number3 from '../../../../assets/webp/number_3.webp';
import number4 from '../../../../assets/webp/number_4.webp';
import number5 from '../../../../assets/webp/number_5.webp';
import { Roles } from '../../../../data/common';
import cl from './Section.module.css';
import { Button } from '../../../../teacherComponents';
import { AuthModes } from '../../../../store/auth';

const Section = ({ index }) => {
    const { t } = useTranslation();

    const isGuest = authStore.role === Roles.Guest;

    const getImageSrc = () => {
        switch (index) {
            case 1:
                return number1;
            case 2:
                return number2;
            case 3:
                return number3;
            case 4:
                return number4;
            case 5:
                return number5;
            default:
                return '';
        }
    };

    const handleStartButtonClick = () => {
        authStore.setAuthMode(AuthModes.Register);
        authStore.setIsAuth(true);
    };

    return (
        <div className={cl.section}>
            <div className={cl.sectionInner}>
                <img
                    className={cl.sectionNumber}
                    src={getImageSrc()}
                    alt={`number ${index}`}
                    draggable="false"
                />
                <Typography variant={'h2'} style={{ marginBottom: 24 }}>
                    {t(`teacher_tutorial.section_${index}_title`)}
                </Typography>

                {index === 1 && (
                    <>
                        <Typography variant={'h3'} style={{ maxWidth: '95%' }}>
                            {t(`teacher_tutorial.section_${index}_subtitle_1`)}
                        </Typography>
                        <Typography
                            variant={'h3'}
                            style={{ maxWidth: '95%', color: 'var(--purple)' }}
                        >
                            {t(`teacher_tutorial.section_${index}_subtitle_2`)}
                        </Typography>
                    </>
                )}

                {index === 2 && (
                    <Typography variant={'h3'} style={{ maxWidth: '70%' }}>
                        {t(`teacher_tutorial.section_${index}_subtitle`)}
                    </Typography>
                )}
                {index === 3 && (
                    <DownloadButtons size={'large'} variant={'grey'} />
                )}
                {isGuest && index === 5 && (
                    <Button
                        style={{ marginTop: 45 }}
                        variant={'dark'}
                        text={t('teacher_tutorial.get_started')}
                        onClick={handleStartButtonClick}
                    />
                )}
            </div>
        </div>
    );
};

export default Section;
