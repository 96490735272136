import React, { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import player from '../../store/player';
import TrackService from '../../api/TrackService';
import { TextDisplay } from './components';
import PlayerControls from '../../components/PlayerControls/PlayerControls';
import YoutubePlayer from '../../components/YoutubePlayer/YoutubePlayer';
import { Loader } from '../../UI';
import { ROUTES } from '../../router';
import { Button } from '../../teacherComponents';
import cl from './Player.module.css';

const Player = ({ track, isAnimate }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const id = params.get('id') || location.state?.id || track?.id;

    const textRef = useRef(null);
    const timerRef = useRef();

    const [getTrack, isLoading, isError, resetError] = useFetching(async () => {
        if (!id) return;
        const { data } = await TrackService.getTrackSentences({
            id,
            lang: i18n.language,
        });
        player.parseTrackData({ ...data });
    });

    const refresh = () => {
        resetError();
        getTrack();
    };

    const setAutoScrollDelay = () => {
        clearTimeout(timerRef.current);
        window.autoScrollDelay = true;
        timerRef.current = setTimeout(() => {
            window.autoScrollDelay = false;
        }, 4000);
    };

    const handleArrowClick = (e) => {
        const dir =
            e.currentTarget.attributes.getNamedItem('data-direction').value;
        let curSenIndex = 0;
        if (player.currentSentenceObj) {
            player.sentences.forEach((el, i) => {
                if (el.key === player.currentSentenceObj.key) {
                    curSenIndex = i;
                    return;
                }
            });
        }
        if (!curSenIndex && !dir) return;
        const nextIndex = dir === 'prev' ? curSenIndex - 1 : curSenIndex + 1;
        const newSentenceObj = player.sentences[nextIndex];
        if (!newSentenceObj) return;
        clearTimeout(window.falseScrollTimeout);
        window.autoScrollDelay = false;
        player.setCurrentSentenceObj(newSentenceObj);
        player.handleChangeAudioTime(newSentenceObj.offset, true);
    };

    useEffect(() => {
        if (!id && !track) navigate(ROUTES.library);
        refresh();
        player.resetStates();
        player.setExternalId(id);
        getTrack();
    }, [id]);

    if (isError) {
        return (
            <div className={cl.trackErrorAlert}>
                <p>{t('library.request_error')}</p>
                <Button
                    text={t('buttons.refresh')}
                    variant={'grey'}
                    onClick={refresh}
                />
            </div>
        );
    }

    return (
        <>
            {isLoading || !id ? (
                <Loader style={{ margin: 'auto', height: '100%' }} />
            ) : (
                <div
                    className={`${cl.playerContainer} ${track ? '' : cl.ponyPlayer}`}
                    key={id}
                >
                    {track?.title && (
                        <p
                            className={`${cl.title} ${isAnimate ? cl.animated : ''}`}
                        >
                            {track.title}
                        </p>
                    )}
                    <div
                        className={`${cl.trackContainer} ${isAnimate ? cl.animated : ''}`}
                    >
                        <YoutubePlayer />
                        <PlayerControls onRewind={handleArrowClick} />
                    </div>

                    {track && (
                        <div className={cl.transcriptionTitle}>
                            <div className={cl.line} />
                            <p>
                                {t('dictionary_administration.transcription')}
                            </p>
                            <div className={cl.line} />
                        </div>
                    )}

                    <div className={cl.textContainer}>
                        <TextDisplay
                            textRef={textRef}
                            onScroll={setAutoScrollDelay}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(Player);
