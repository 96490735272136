import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import DictionaryService from '../../../../../../api/DictionaryService';
import { Loader, PrimaryButton } from '../../../../../../UI';
import { ReactComponent as IconClose } from '../../../../../../assets/svg/lessons-cross.svg';
import { ReactComponent as IconSound } from '../../../../../../assets/svg/icon-sound.svg';
import { CloseButton } from '../../../../../../teacherComponents';
import cl from './WordCard.module.css';

const WordCard = ({ currentWord, onClose, className }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const [wordObj, setWordObj] = useState({});
    const [audio] = useState(new Audio());

    const [partsOfSpeech, setPartsOfSpeech] = useState([]);

    const [getWord, isLoading, isError, resetError] = useFetching(async () => {
        if (!currentWord) return;
        resetError();

        const { data: wObj } = await DictionaryService.getWordByText({
            word: currentWord.word,
            sourceLang: lang,
            targetLang: lang,
        });

        setPartsOfSpeech(wObj?.data?.map((w) => w.partOfSpeech ?? ''));
        setWordObj({ ...wObj });
        if (!wObj.data) return;
        setPartsOfSpeech(wObj.data);
    });

    const handleAudioClick = (path) => {
        audio.pause();
        audio.src = path;
        setTimeout(() => {
            audio.play();
        });
    };

    const renderFormsBlock = ({ forms }) => {
        if (!forms || forms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.forms')}</p>
                <p className={cl.infoText}>{forms.join(', ')}</p>
            </div>
        );
    };

    const renderSynonymsBlock = ({ synonyms }) => {
        if (!synonyms || synonyms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.synonyms')}</p>
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            </div>
        );
    };

    useEffect(() => {
        getWord();
        if (currentWord) {
            audio.pause();
            audio.src = currentWord.voicePath ?? '';
        } else {
            audio.src = '';
        }
    }, [currentWord]);

    if (!currentWord) return null;

    if (isError)
        return (
            <div className={`${cl.wordCard} ${className ? className : ''}`}>
                <p
                    className={cl.exampleText}
                    style={{ margin: 'auto', textAlign: 'center' }}
                >
                    {`${t('dictionary_administration.empty_word')} (${currentWord.word})`}
                </p>
                {onClose && (
                    <div className={cl.close} onClick={onClose}>
                        <IconClose />
                    </div>
                )}
            </div>
        );

    return (
        <div className={`${cl.wordCard} ${className ? className : ''}`}>
            <div className={cl.wordText}>
                {wordObj.language === 'de' && wordObj.article}
                {wordObj?.word && <span>{wordObj.word}</span>}
                {wordObj?.voicePath && (
                    <PrimaryButton
                        onClick={() => handleAudioClick(wordObj.voicePath)}
                        icon={<IconSound className={cl.soundIcon} />}
                        variant={'white'}
                    />
                )}
            </div>

            {!isLoading ? (
                <>
                    {partsOfSpeech[0]?.pronunciation && (
                        <p
                            className={cl.wordText}
                        >{`${partsOfSpeech[0].pronunciation}`}</p>
                    )}
                    {partsOfSpeech.map((part) => (
                        <div className={cl.card} key={part.id}>
                            <p className={cl.partOfSpeech}>
                                {part.partOfSpeech}
                            </p>
                            {renderFormsBlock(part)}
                            {renderSynonymsBlock(part)}
                            {part.examples && part.examples.length !== 0 && (
                                <div className={cl.infoCont}>
                                    <p className={cl.infoLabel}>
                                        {t('demo_page.phrases')}
                                    </p>
                                    {part.examples.map((ex) => (
                                        <p
                                            className={cl.exampleText}
                                            key={ex.id}
                                        >
                                            <PrimaryButton
                                                onClick={() =>
                                                    handleAudioClick(
                                                        ex.voicePath
                                                    )
                                                }
                                                icon={
                                                    <IconSound
                                                        className={cl.soundIcon}
                                                    />
                                                }
                                                variant={'white'}
                                            />
                                            {ex.example}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </>
            ) : (
                <Loader className={cl.wordLoader} />
            )}
            {onClose && <CloseButton onClick={onClose} />}
        </div>
    );
};

export default observer(WordCard);
