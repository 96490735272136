import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import adminExercisesStore from './store/adminExercisesStore';
import { useFetching } from '../../hooks/useFetching';
import ExerciseService from '../../api/ExerciseService';
import { ExercisesPerPage } from './data/constants';
import { LanguageLevels } from '../../data/common';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { ExercisePreview, ExercisesList, SearchBar } from './components';

const ExercisesAdministration = () => {
    const { page, difficulty, language, type, searchString, currentExercise } =
        adminExercisesStore;

    const [getExercises, isLoading] = useFetching(async () => {
        const params = {
            offset: page * ExercisesPerPage,
            limit: ExercisesPerPage,
            lang: language,
            type,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
            searchString,
        };

        const { data } = await ExerciseService.getExercises(params);

        adminExercisesStore.setExercisesData(data);
    });

    useEffect(() => {
        getExercises();
    }, [page]);

    useEffect(() => {
        adminExercisesStore.setPage(0);
        getExercises();
    }, [searchString, difficulty, language, type]);

    return (
        <PageWrapper>
            <SearchBar />
            <ExercisesList isLoading={isLoading} />
            {currentExercise && <ExercisePreview />}
        </PageWrapper>
    );
};

export default observer(ExercisesAdministration);
