import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import languagesStore from '../../../../store/interface';
import userWordsStore, {
    PackSizeOptions,
    PlayBackOptions,
    WordCardModes,
    WordStatuses as LearnedStatuses,
} from './store/userWords';
import wordsStore from '../../../../store/words';
import { useFetching } from '../../../../hooks/useFetching';
import DictionaryService from '../../../../api/DictionaryService';
import ProgressService from '../../../../api/ProgressService';
import PageWrapper from '../../../../components/UI/PageWrapper/PageWrapper';
import {
    Button,
    Input,
    Range,
    ToggleButton,
    Toolbar,
    ViewTitle,
} from '../../../../teacherComponents';
import { Switch } from '../../../../UI';
import {
    AddWord,
    ContentContainer,
    LearnWords,
    UserChapterPreview,
} from '../../components';
import Modal from '../../../../components/UI/Modal/Modal';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { ReactComponent as IconWords } from '../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import {
    UserWordsCategory,
    WordStatuses,
} from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import cl from './UserWords.module.css';

const WordRequestInterval = 2000;
const Timeout = 20000;

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <Button
                variant={'grey'}
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
                icon={<IconSettings />}
            />

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                            />
                        </div>
                    ))}
                </div>
                <p className={cl.toggleLabel}>
                    {t('glossary_settings.words_in_pack')}
                </p>
                <div className={cl.packSizeSelector}>
                    <ToggleButton
                        value={wordsPerPack}
                        onChange={userWordsStore.setWordsPerPack}
                        options={PackSizeOptions}
                        isGrid
                        variant={'transparent'}
                    />
                </div>
                <p> {t('glossary_settings.playback_speed')}</p>
                <Range
                    value={playbackSpeed}
                    min={PlayBackOptions[0]}
                    step={PlayBackOptions[1] - PlayBackOptions[0]}
                    max={PlayBackOptions[PlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
            </Modal>
        </>
    );
});

const UserWords = ({ categoryId }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;

    const { user } = authStore;
    const { processedWords } = wordsStore;

    const {
        currentWord,
        page,
        pendingWords,
        searchString,
        status,
        dictionaryWords,
    } = userWordsStore;

    const [learnMode, setLearnMode] = useState(false);

    const [getAllWords, isLoading] = useFetching(async () => {
        const { data: wordsData } = await ProgressService.getWords({
            lang: user.lang,
        });

        const currentPendingWords = [];

        const processedWords = await Promise.all(
            wordsData.items.map(async (item) => {
                const { data: dictionaryData } =
                    await DictionaryService.getWordByText({
                        word: item.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });

                if (!dictionaryData) {
                    currentPendingWords.push(item);
                }

                return { ...item, ...dictionaryData };
            })
        );

        userWordsStore.setWordsData(processedWords);

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    });

    const [getCategoryWords] = useFetching(async () => {
        const { data } = await ProgressService.getCategoryWords({
            categoryId,
            lang: user.lang,
        });
        const currentPendingWords = [];

        await Promise.all(
            data.items.map(async (item) => {
                const { data } = await DictionaryService.getWordByText({
                    word: item.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                if (!data) {
                    currentPendingWords.push(item);
                }
            })
        );
        userWordsStore.setWordsData(
            data.items.map((w) => ({ ...w, status: LearnedStatuses.Active }))
        );

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    });

    const toggleLearnMode = () => setLearnMode(!learnMode);

    const getWords = categoryId ? getCategoryWords : getAllWords;

    const processPendingWords = async (currentPendingWords) => {
        userWordsStore.setPendingWords(currentPendingWords);

        for (const word of currentPendingWords) {
            try {
                await DictionaryService.translateWord({
                    word: word.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                const startTime = Date.now();

                while (Date.now() - startTime < Timeout) {
                    const { data } = await DictionaryService.getWordByText({
                        word: word.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });

                    if (data) {
                        userWordsStore.removePendingWord(word.wordId, data);
                        break;
                    }

                    await new Promise((resolve) =>
                        setTimeout(resolve, WordRequestInterval)
                    );
                }
            } catch (error) {
                console.error(
                    `Error processing pending word: ${word.word}`,
                    error
                );
            }
        }
    };

    const getWordsByStatus = () => {
        const currentWords = dictionaryWords
            .map((w) =>
                pendingWords.some(
                    (pW) => pW.wordId === w.id || pW.word === w.word
                )
                    ? { ...w, pending: true }
                    : w
            )
            .filter((w) => w.status === status);

        const currentProcessedWords =
            status === LearnedStatuses.Learned
                ? []
                : processedWords
                      .filter((w) => w.categoryId === UserWordsCategory)
                      .map((w) => ({ ...w, pending: true }));

        return [...currentWords, ...currentProcessedWords].sort((a, b) =>
            a.word.localeCompare(b.word)
        );
    };

    const addWord = (word) => {
        if (dictionaryWords.some((w) => w.word === word)) return;
        wordsStore.addWord({
            word,
            categoryId: UserWordsCategory,
            status: WordStatuses.Queued,
            targetLang: nativeLang,
        });
    };

    const syncNewWords = async (words) => {
        const currentPendingWords = [];

        for (const wObj of words) {
            const { word, categoryId } = wObj;

            if (dictionaryWords.some((w) => w.word === word)) return;

            const { data } = await DictionaryService.getWordByText({
                word,
                sourceLang: lang,
                targetLang: lang,
            });
            const wordId = data.data[0].id;

            const { data: translatedData } =
                await DictionaryService.getWordByText({
                    word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });

            wordsStore.deletePendingWord({ word, categoryId });
            userWordsStore.addNewDictionaryWord({
                word,
                id: wordId,
                wordId,
                lastIteration: 0,
                lang,
                status: LearnedStatuses.Active,
                ...translatedData,
            });

            if (!translatedData) {
                currentPendingWords.push({
                    word,
                    id: wordId,
                    wordId,
                    lang,
                });
            }
        }

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    };

    useEffect(() => {
        getWords();
        userWordsStore.setCurrentWord({});
    }, [page, lang, nativeLang]);

    useEffect(() => {
        userWordsStore.setCurrentWord({});
        if (page !== 0) {
            userWordsStore.setPage(0);
        } else {
            getWords();
        }
    }, [searchString]);

    useEffect(() => {
        userWordsStore.setCurrentWord({});
    }, [status]);

    useEffect(() => {
        const readyWords = processedWords.filter(
            (w) =>
                w.categoryId === UserWordsCategory &&
                w.status === WordStatuses.Ready
        );

        const newReadyWords = [];

        readyWords.forEach((wObj) => {
            if (dictionaryWords.some((w) => w.word === wObj.word)) {
                wordsStore.deletePendingWord({ word: wObj.word, categoryId });
            } else {
                newReadyWords.push(wObj);
            }
        });

        if (newReadyWords.length) {
            syncNewWords(newReadyWords);
        }
    }, [dictionaryWords, processedWords]);

    useEffect(() => {
        if (learnMode) userWordsStore.setCurrentWord({});
    }, [learnMode]);

    return (
        <PageWrapper
            additionalClass={`${cl.wordsWrapper} ${categoryId ? cl.modal : ''}`}
        >
            {!categoryId && (
                <>
                    <ViewTitle
                        text={t('user_view.my_words')}
                        icon={<IconWords />}
                    />
                    <Toolbar>
                        <Input
                            value={searchString}
                            onChange={userWordsStore.setSearchString}
                            placeholder={t('user_view.search')}
                        />
                        <AddWord onAdd={addWord} />
                    </Toolbar>
                </>
            )}

            <ContentContainer className={cl.wordsList}>
                <div className={cl.chaptersContainer}>
                    <ChaptersList
                        chapters={getWordsByStatus()}
                        type={Chapters.Dictionary}
                        current={currentWord}
                        setCurrent={
                            learnMode ? () => {} : userWordsStore.setCurrentWord
                        }
                        isLoading={isLoading}
                    />
                    {!categoryId && dictionaryWords.length !== 0 && (
                        <div className={cl.modesContainer}>
                            <ToggleButton
                                variant={'transparent'}
                                isGrid
                                value={status}
                                onChange={userWordsStore.setStatus}
                                options={Object.values(LearnedStatuses)}
                                withTranslations={'user_view'}
                                className={cl.modeToggle}
                            />
                        </div>
                    )}
                    {dictionaryWords.length !== 0 && (
                        <Button
                            variant={learnMode ? 'grey' : 'dark'}
                            text={t(
                                learnMode
                                    ? 'buttons.close'
                                    : 'glossary_settings.learn_words'
                            )}
                            className={cl.learnButton}
                            onClick={toggleLearnMode}
                        />
                    )}
                </div>

                {learnMode ? (
                    <LearnWords />
                ) : (
                    <UserChapterPreview
                        chapter={currentWord}
                        type={Chapters.Dictionary}
                    />
                )}
                <Settings />
            </ContentContainer>
        </PageWrapper>
    );
};

export default observer(UserWords);
