import React from 'react';
import { useTranslation } from 'react-i18next';
import cl from './QuestionsLabel.module.css';

const QuestionsLabel = ({ label }) => {
    const { t } = useTranslation();
    return (
        <p className={cl.questionsLabel}>
            <span>{t(`exercises.${label}`)}</span>
        </p>
    );
};

export default QuestionsLabel;
