import React from 'react';
import cl from './PrimaryInput.module.css';

const PrimaryInput = ({
    placeholder,
    value,
    onChange,
    onEnterKey,
    onBlur,
    style,
    type,
    color,
    disabled,
    autoFocus,
    autoComplete,
}) => {
    const classNames = [cl.input];

    if (color) {
        color = Array.isArray(color) ? color : color.split(' ');
        color.forEach((el) => {
            classNames.push(cl[el]);
        });
    }

    return (
        <input
            autoComplete={autoComplete}
            disabled={disabled}
            style={style}
            placeholder={placeholder}
            value={value}
            type={type || 'text'}
            className={classNames.join(' ')}
            onChange={(e) => onChange(e.target.value, e)}
            onKeyDown={onEnterKey}
            onBlur={onBlur}
            autoFocus={autoFocus}
        />
    );
};

export default PrimaryInput;
