import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cl from './ToggleButton.module.css';

const ToggleButton = ({
    title,
    options,
    nullable,
    value,
    availableOptions,
    onChange,
    style,
    variant,
    withTranslations,
    multiple,
    disabled,
    isGrid,
    icons,
    className,
}) => {
    const { t } = useTranslation();

    const [pressedIndex, setPressedIndex] = useState(null);

    const handleOptionClick = (option) => {
        if (
            availableOptions !== undefined &&
            !availableOptions?.includes(option) &&
            disabled
        )
            return;
        if (multiple) {
            onChange(
                value.includes(option)
                    ? value.filter((o) => o !== option)
                    : [...value, option]
            );
            return;
        }
        onChange(value === option && nullable ? null : option);
    };

    const getOptionClassName = (option, index) => {
        const classNames = [cl.option];
        if (!multiple && option === value) classNames.push(cl.active);
        if (multiple && value.includes(option)) classNames.push(cl.active);
        if (variant) classNames.push(cl[variant]);
        if (index === pressedIndex) {
            classNames.push(cl.pressed);
        }
        if (
            availableOptions !== undefined &&
            !availableOptions?.includes(option)
        ) {
            if (disabled) classNames.push(cl.disabled);
            classNames.push(cl.unavailable);
        }
        return classNames.join(' ');
    };

    return (
        <div
            className={`${cl.toggleButtonCont} ${cl[variant]} ${className ? className : ''}`}
            style={style}
        >
            {title && <p className={cl.sectionTitle}>{`${t(title)}:`}</p>}
            <div
                className={`${cl.optionsContainer} ${isGrid ? cl.grid : ''}`}
                style={{
                    gridTemplateColumns: isGrid
                        ? `repeat(${options.length}, 1fr)`
                        : 'unset',
                }}
            >
                {options.map((option, index) => (
                    <div
                        className={getOptionClassName(option, index)}
                        key={`${option}_${index}`}
                        onClick={() => handleOptionClick(option)}
                        onMouseDown={() => setPressedIndex(index)}
                        onMouseUp={() => setPressedIndex(null)}
                    >
                        {icons?.length && icons[index]}
                        {withTranslations
                            ? t(`${withTranslations}.${option.toLowerCase()}`)
                            : option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ToggleButton;
