import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import { LabeledInput, LabeledSelect, PrimaryButton } from '../../../../UI';
import { LoaderLine } from '..';
import Modal from '../../../../components/UI/Modal/Modal';
import { LANG_OPTIONS, YT_REGEX } from '../../data';
import cl from './AddTrackInput.module.css';

const AddTrackInput = ({ url, setUrl, setTrack }) => {
    const { t } = useTranslation();
    const [lang, setLang] = useState();
    const [isValid, setIsValid] = useState(false);

    const [addTrack, isLoading, error, resetError] = useFetching(async () => {
        const { data } = await TrackService.addTrackByUrl({
            url,
            lang,
        });
        setTrack(data);
    });

    const handleAddButton = () => {
        addTrack();
        resetError();
    };

    useEffect(() => {
        setIsValid(YT_REGEX.test(url) && lang);
    }, [url, lang]);

    return (
        <>
            <Modal visible={error} title={t(`errors.unexpected_error`)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 10,
                    }}
                >
                    <PrimaryButton
                        text={t('buttons.ok')}
                        color={'dark'}
                        onClick={resetError}
                    />
                </div>
            </Modal>
            <div className={cl.box}>
                <LabeledInput
                    style={{ minWidth: '50vw' }}
                    label={t('add_track_input.link')}
                    placeholder={'https://www.youtube.com/watch?v=example_id'}
                    value={url}
                    onChange={setUrl}
                />
                <LabeledSelect
                    label={t('podcasts.language')}
                    value={lang}
                    options={LANG_OPTIONS}
                    onChange={setLang}
                />
                <PrimaryButton
                    variant={'magenta'}
                    text={t('add_track_input.upload')}
                    isDisabled={!isValid}
                    onClick={handleAddButton}
                />
            </div>
            {isLoading && <LoaderLine />}
        </>
    );
};

export default AddTrackInput;
