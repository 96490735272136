import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ProgressService from '../../../../../../api/ProgressService';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../../../store/lessonsStore';
import { Button } from '../../../../../../teacherComponents';
import { StudentLessonStatuses, Views } from '../../../../data/constants';
import { ReactComponent as IconAssign } from '../../../../../../assets/svg/lessons-assign.svg';
import { ReactComponent as IconPlan } from '../../../../../../assets/svg/lessons-plan.svg';
import DemoPageLoader from '../../../../../../components/UI/DemoPageLoader/DemoPageLoader';
import cl from './ModalControls.module.css';

const ModalControls = ({ lesson, isEmpty }) => {
    const { t } = useTranslation();

    const [student, setStudent] = useState();

    const { currentStudentLessons, currentStudentLessonsLoading } =
        lessonsStore;

    const [isDone, setIsDone] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);

    const isStudents = exercisesStore.view === Views.Students;

    const handleLessonAssign = async () => {
        await ProgressService.assignLesson({
            studentId: student?.id,
            lessonId: lesson.id,
        });

        const newLessons = currentStudentLessons.some(
            (l) => l.lessonId === lesson.id
        )
            ? currentStudentLessons.map((l) =>
                  l.lessonId === lesson.id
                      ? { ...l, status: StudentLessonStatuses.Postponed }
                      : l
              )
            : [
                  ...currentStudentLessons,
                  {
                      lessonId: lesson.id,
                      status: StudentLessonStatuses.Postponed,
                  },
              ];

        lessonsStore.setCurrentStudentLessons(newLessons);
        setIsAssigned(true);
    };

    const handleLessonShare = async () => {
        await ProgressService.assignLesson({
            studentId: student?.id,
            lessonId: lesson.id,
        });

        await ProgressService.sendLessonData({
            studentId: student?.id,
            lessonId: lesson.id,
        });

        const newLessons = currentStudentLessons.some(
            (l) => l.lessonId === lesson.id
        )
            ? currentStudentLessons.map((l) =>
                  l.lessonId === lesson.id
                      ? { ...l, status: StudentLessonStatuses.Unfinished }
                      : l
              )
            : [
                  ...currentStudentLessons,
                  {
                      lessonId: lesson.id,
                      status: StudentLessonStatuses.Unfinished,
                  },
              ];

        lessonsStore.setCurrentStudentLessons(newLessons);
        setIsActive(true);
    };

    const handleLessonEvaluate = async () => {
        await ProgressService.evaluateLesson({
            studentId: student?.id,
            lessonId: lesson.id,
            status: StudentLessonStatuses.Done,
        });

        const newLessons = currentStudentLessons.some(
            (l) => l.lessonId === lesson.id
        )
            ? currentStudentLessons.map((l) =>
                  l.lessonId === lesson.id
                      ? { ...l, status: StudentLessonStatuses.Done }
                      : l
              )
            : [
                  ...currentStudentLessons,
                  { lessonId: lesson.id, status: StudentLessonStatuses.Done },
              ];
        lessonsStore.setCurrentStudentLessons(newLessons);
        setIsDone(true);
    };

    const renderControls = () => {
        if (!student) return null;
        if (student && isEmpty)
            return (
                <p className={cl.emptyAlert}>{t('exercises.empty_lesson')}</p>
            );
        if (currentStudentLessonsLoading)
            return <DemoPageLoader size={20} color={'var(--purple)'} />;
        if (isDone) return <>{t('exercises.lesson_done')}</>;
        if (!isActive)
            return (
                <div className={cl.studentButtons}>
                    {!isAssigned && (
                        <Button
                            style={{ gap: 12, width: 150 }}
                            isDisabled={isAssigned}
                            icon={<IconPlan className={cl.planIcon} />}
                            variant={'purple'}
                            onClick={handleLessonAssign}
                            text={t('exercises.plan_lesson')}
                        />
                    )}
                    <Button
                        style={{ gap: 12, width: 150 }}
                        text={t('exercises.assign_lesson')}
                        variant={'purple'}
                        icon={<IconAssign className={cl.planIcon} />}
                        onClick={handleLessonShare}
                    />
                </div>
            );

        if (isStudents)
            return (
                <Button
                    style={{ width: 150 }}
                    variant={'purple'}
                    text={t('exercises.mark_as_done')}
                    onClick={handleLessonEvaluate}
                />
            );
        return null;
    };

    useEffect(() => {
        const currentLesson = currentStudentLessons.find(
            (l) => l.lessonId === lesson.id
        );
        setIsDone(currentLesson?.status === StudentLessonStatuses.Done);
        setIsActive(currentLesson?.status === StudentLessonStatuses.Unfinished);
        setIsAssigned(
            currentLesson?.status === StudentLessonStatuses.Postponed
        );
    }, [currentStudentLessons]);

    useEffect(() => {
        setStudent(exercisesStore.currentStudent);
    }, [exercisesStore.currentStudent]);

    return <div className={cl.container}>{renderControls()}</div>;
};

export default observer(ModalControls);
