import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useRecorder from '../../../../hooks/useRecorder';
import { ReactComponent as IconMicrophone } from '../../../../assets/svg/microphone.svg';
import { ReactComponent as IconPause } from '../../../../assets/svg/lessons-pause.svg';
import cl from './AudioRecorder.module.css';

const AudioRecorder = ({ onStart, onStop }) => {
    const { recorderState, ...handlers } = useRecorder();
    const { audio, permission, initRecording } = recorderState;
    const { startRecording, saveRecording } = handlers;

    const handleStart = () => {
        startRecording();
        onStart();
    };

    useEffect(() => {
        if (audio) {
            onStop(audio);
        }
    }, [audio]);

    const handleRecordButtonClick = () => {
        initRecording ? saveRecording() : handleStart();
    };

    const getButtonClassName = () => {
        const classNames = [cl.button];
        if (!permission) {
            classNames.push(cl.disabled);
        }
        if (initRecording) {
            classNames.push(cl.active);
        }
        return classNames.join(' ');
    };

    return (
        <div className={cl.audioCont}>
            <div
                className={getButtonClassName()}
                onClick={handleRecordButtonClick}
            >
                {initRecording ? <IconPause /> : <IconMicrophone />}
            </div>
        </div>
    );
};

export default observer(AudioRecorder);
