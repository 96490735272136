import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import adminExercisesStore from '../../store/adminExercisesStore';
import languagesStore from '../../../../store/interface';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
} from '../../../../teacherComponents';
import { ExerciseTypes } from '../../data/constants';
import { LanguageLevels } from '../../../../data/common';
import cl from './SearchBar.module.css';

const SearchBar = () => {
    const { availableLanguages } = languagesStore;
    const { t } = useTranslation();
    const { difficulty, language, total, type, searchString } =
        adminExercisesStore;

    const typeOptions = ExerciseTypes.map((type) => ({
        value: type,
        name: t(`exercises.${type}`),
    }));

    return (
        <>
            <Toolbar>
                <Input
                    value={searchString}
                    onChange={adminExercisesStore.setSearchString}
                    placeholder={t('library.search')}
                />
                <Select
                    style={{ minWidth: 150 }}
                    variant={'white'}
                    value={language}
                    options={availableLanguages}
                    onChange={adminExercisesStore.setLanguage}
                    label={t('dictionary_administration.source_lang')}
                />
                <Select
                    style={{ minWidth: 200 }}
                    variant={'white'}
                    value={type}
                    options={typeOptions}
                    onChange={adminExercisesStore.setType}
                    label={t('exercises.type')}
                />
                <ToggleButton
                    nullable
                    label={t('users.level')}
                    options={LanguageLevels}
                    value={difficulty}
                    onChange={adminExercisesStore.setDifficulty}
                />
            </Toolbar>
            {total > 0 && (
                <p className={cl.total}>{`${t('exercises.total')} ${total}`}</p>
            )}
        </>
    );
};

export default observer(SearchBar);
