import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import languagesStore from '../../../../store/interface';
import userDialoguesStore from './store/userDialogues';
import SituationsService from '../../../../api/SituationsService';
import PageWrapper from '../../../../components/UI/PageWrapper/PageWrapper';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
    ViewTitle,
} from '../../../../teacherComponents';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { ContentContainer, UserChapterPreview } from '../../components';
import { ReactComponent as IconSituation } from '../../../../assets/svg/lessons-situation.svg';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { LanguageLevels } from '../../../../data/common';
import cl from './UserDialogues.module.css';

const UserDialogues = () => {
    const { t } = useTranslation();
    const { interfaceLang, lang, nativeLang } = languagesStore;

    const {
        currentSituation,
        categories,
        setCategories,
        difficulty,
        searchString,
        situations,
    } = userDialoguesStore;

    const [getSituations, isLoading] = useFetching(async () => {
        const { data } = await SituationsService.getAvailableCategories({
            language: lang.toUpperCase(),
            level: difficulty,
        });

        const situations = data.map((d) => d.situations).flat();

        userDialoguesStore.setSituations(situations);
        userDialoguesStore.setCategories(categories);
    });
    useEffect(() => {
        getSituations();
    }, [difficulty, searchString]);

    return (
        <PageWrapper additionalClass={cl.dialoguesWrapper}>
            <ViewTitle
                text={t('user_view.my_dialogues')}
                icon={<IconSituation />}
            />
            {/*   <Toolbar>
          <Input
                    value={searchString}
                    onChange={userExercisesStore.setSearchString}
                    placeholder={t('user_view.search')}
                />
                <Select
                    style={{ minWidth: 200 }}
                    variant={'white'}
                    value={type}
                    options={typeOptions}
                    onChange={userExercisesStore.setType}
                    label={t('exercises.type')}
                />
                <ToggleButton
                    value={difficulty}
                    options={LanguageLevels}
                    onChange={userExercisesStore.setDifficulty}
                    nullable
                /> 
            </Toolbar>*/}
            <ContentContainer>
                <ChaptersList
                    chapters={situations}
                    type={Chapters.Situation}
                    current={currentSituation}
                    setCurrent={userDialoguesStore.setCurrentSituation}
                    isLoading={isLoading}
                />
                <UserChapterPreview
                    chapter={currentSituation}
                    type={Chapters.Situation}
                />
            </ContentContainer>
        </PageWrapper>
    );
};

export default observer(UserDialogues);
