export const Chapters = {
    Track: 'track',
    Situation: 'situation',
    Exercise: 'exercise',
    Dictionary: 'words',
    File: 'file',
};

export const Views = {
    Lessons: 'lessons',
    Students: 'students',
    Teachers: 'teachers',
    Tutorial: 'tutorial',
};

export const ChapterModals = {
    [Chapters.Track]: 'track_modal',
    [Chapters.Situation]: 'situation_modal',
    [Chapters.Exercise]: 'exercise_modal',
    [Chapters.Dictionary]: 'words_modal',
};

export const StudentLessonStatuses = {
    Available: 'available',
    Done: 'done',
    Unfinished: 'unfinished',
};

export const StudentExerciseStatuses = {
    Unfinished: 'unfinished',
    AwaitingEvaluation: 'awaitingEvaluation',
    Done: 'done',
};

export const SelectWordsCategoryModalType = 'words_category_modal';
