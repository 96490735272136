import axios from 'axios';

const URL = 'v1/tracks';

const NEWS_CATEGORY_ID = '93103118-9771-4c7b-ba46-bc393afa107a';

export default class PodcastService {
    static async getList({
        offset,
        lang,
        levels,
        limit,
        searchString,
        tracksCount,
        autorecognized,
        promoted,
        processed,
        isNews,
        sortColumn,
        sortOrder,
    }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });

        const optional = {
            promoted,
            autorecognized,
            tracksCount,
            searchString,
            processed,
            lang,
            levels,
            sortColumn,
            sortOrder,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        if (isNews) {
            params.append('categories', [NEWS_CATEGORY_ID]);
        }

        const podcasts = await axios.get(
            `${URL}/podcasts?${params.toString()}`
        );
        return podcasts;
    }

    static async blacklistPodcast(id) {
        return axios.patch(`${URL}/podcasts/blacklist/${id}`, {});
    }

    static async unblacklistPodcast(id) {
        return axios.patch(`${URL}/podcasts/remove-from-blacklist/${id}`, {});
    }

    static async promotePodcast(id, rating) {
        return axios.patch(`${URL}/podcasts/promote/${id}`, {
            rating,
        });
    }

    static async unpromotePodcast(id) {
        return axios.patch(`${URL}/podcasts/unpromote/${id}`, {});
    }

    static async autorecognizePodcast(id) {
        return axios.patch(`${URL}/podcasts/autorecognize/${id}`, {});
    }

    static async unautorecognizePodcast(id) {
        return axios.patch(`${URL}/podcasts/disable-autorecognition/${id}`, {});
    }

    static async recognizeAllPodcastTracks(id, quantity) {
        return axios.post(`${URL}/podcasts/add-several`, {
            id,
            quantity,
        });
    }

    static async subscribe(id) {
        return axios.post(`${URL}/podcasts/subscribe/${id}`, {});
    }

    static async unsubscribe(id) {
        return axios.delete(`${URL}/podcasts/unsubscribe/${id}`);
    }

    static async updatePodcast(id, data) {
        return axios.patch(`${URL}/podcasts/${id}`, data);
    }

    static async deletePodcast(id) {
        return axios.delete(`${URL}/podcasts/${id}`);
    }

    static async getById(id) {
        return axios.get(`${URL}/podcasts/${id}`);
    }

    static getBlacklist({ offset, limit }) {
        return axios.get(
            `${URL}/podcasts/blacklist?offset=${offset}&limit=${limit}`
        );
    }

    static getPromoted() {
        return axios.get(`${URL}/podcasts/promoted`);
    }

    static getAutorecognized() {
        return axios.get(`${URL}/podcasts/autorecognized`);
    }

    static async pullPodcast({ videoId, lang }) {
        try {
            const response = await axios.post(`${URL}/podcasts/pull-by-video`, {
                videoId,
                lang,
            });
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.message;
            }
        }
    }

    static async refresh() {
        const response = await axios.patch(`${URL}/podcasts/refresh`, {});
        return response;
    }

    static async addNewsCategory({ id }) {
        const response = await axios.post(`${URL}/podcasts/${id}/category`, {
            categoryId: NEWS_CATEGORY_ID,
        });
        return response;
    }

    static async removeNewsCategory({ id }) {
        const response = await axios.delete(`${URL}/podcasts/${id}/category`, {
            data: {
                categoryId: NEWS_CATEGORY_ID,
            },
        });
        return response;
    }
}
