import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import cl from './SituationAlert.module.css';
import { CloseButton } from '../../../../../../teacherComponents';

const SituationAlert = ({ show, onClose }) => {
    const { t } = useTranslation();

    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    className={cl.alert}
                    initial={{ x: '-100%', opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: '100%', opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {t('exercises.situation_queued')
                        .split('.')
                        .map((s) => (
                            <p key={s}>{`${s}.`}</p>
                        ))}
                    <p></p>
                    <CloseButton
                        onClick={onClose}
                        top={8}
                        right={8}
                        size={10}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SituationAlert;
