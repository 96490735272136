import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import exercisesStore from '../../../../store/exercisesStore';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
    MediaOptions,
    Preview,
    TextExerciseOptions,
} from '..';
import {
    DefaultExerciseOptions,
    DefaultMediaExerciseOptions,
    GrammarExerciseTypes,
    MediaExerciseTypes,
    MediaTypes,
} from '../../../../data/constants';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { CloseButton } from '../../../../../../teacherComponents';
import { ReactComponent as IconHelp } from '../../../../../../assets/svg/lessons-help.svg';
import { Loader } from '../../../../../../UI';
import cl from './GenerateExerciseModal.module.css';

const GenerateExerciseModal = ({ visible, setVisible, onAdd }) => {
    const { t } = useTranslation();

    const [previewId, setPreviewId] = useState();
    const [previewType, setPreviewType] = useState(false);

    const [options, setOptions] = useState({
        ...DefaultExerciseOptions,
    });

    const [mediaOptions, setMediaOptions] = useState({
        ...DefaultMediaExerciseOptions,
    });

    const [getExample, exampleLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getExercises({
            offset: 0,
            limit: 1,
            type: previewType,
        });
        if (data?.items && data.items.length) {
            setPreviewId(data.items[0].id);
        }
    });

    const handleOptionsTypeSelect = (type) => {
        setMediaOptions({ ...DefaultMediaExerciseOptions });
        setOptions({ ...options, type });
    };

    const handleMediaOptionsTypeSelect = (type, mediaType) => {
        setOptions({ ...DefaultExerciseOptions });
        setMediaOptions({ ...mediaOptions, type, mediaType });
    };

    const handleExerciseCreate = (data) => {
        if (onAdd) {
            onAdd({ id: data.id, title: data.title });
            return;
        }
        exercisesStore.setChaptersModalType('');
        exercisesStore.setExercises([data, ...exercisesStore.exercises]);
        setTimeout(() => {
            exercisesStore.setCurrentExercise(data);
        }, 300);
    };

    const handleCloseButton = () => {
        if (!options.type && !mediaOptions.type) {
            setVisible(false);
        } else {
            setOptions(DefaultExerciseOptions);
            setMediaOptions(DefaultMediaExerciseOptions);
        }
    };

    const resetPreview = () => {
        setPreviewType();
        setPreviewId();
    };

    const renderExample = () => {
        return (
            <Modal
                className={cl.previewModal}
                visible={previewType}
                setVisible={resetPreview}
            >
                <div className={cl.modalInner}>
                    {exampleLoading ? (
                        <Loader style={{ height: '100%', margin: 'auto' }} />
                    ) : previewId ? (
                        <Preview exerciseId={previewId} hideEdit />
                    ) : (
                        <p className={cl.noPreviewAlert}>
                            {t('exercises.no_exercise_preview')}
                        </p>
                    )}
                </div>
                <CloseButton onClick={resetPreview} />
            </Modal>
        );
    };

    const renderTitle = () => {
        if (options.type) {
            return (
                <p className={cl.stepTitle}>{t(`exercises.${options.type}`)}</p>
            );
        }
        if (mediaOptions.type) {
            return (
                <p className={cl.stepTitle}>
                    {t(`exercises.${mediaOptions.type}`)}
                </p>
            );
        }
    };

    const renderExerciseCard = (exerciseType, mediaType) => {
        const onClick = Object.values(MediaExerciseTypes).includes(exerciseType)
            ? handleMediaOptionsTypeSelect
            : handleOptionsTypeSelect;

        const formattedMediaType =
            mediaType === MediaTypes.Video
                ? 'video'
                : mediaType === MediaTypes.GeneratedText
                  ? 'text'
                  : '';

        return (
            <div
                className={cl.exerciseTypeCard}
                key={exerciseType}
                onClick={() => onClick(exerciseType, mediaType)}
            >
                <IconHelp
                    className={cl.helpButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        setPreviewType(exerciseType);
                    }}
                />
                <ExerciseIcon
                    type={exerciseType}
                    additionalClass={cl.modalIcon}
                />
                <ExerciseMediaIcon
                    type={formattedMediaType}
                    style={{ top: 10, right: 10 }}
                />

                <div className={cl.textBlock}>
                    <div className={cl.titleCont}>
                        <p>{t(`exercises.${exerciseType}`)}</p>
                    </div>
                    <span>
                        {t(
                            `exercises.${exerciseType}${
                                mediaType === MediaTypes.GeneratedText
                                    ? '_text'
                                    : ''
                            }_desc`
                        )}
                    </span>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (
            options.type &&
            Object.values(GrammarExerciseTypes).includes(options.type)
        ) {
            return (
                <TextExerciseOptions
                    options={options}
                    setOptions={setOptions}
                    onCreate={handleExerciseCreate}
                />
            );
        }

        if (
            mediaOptions.type &&
            Object.values(MediaExerciseTypes).includes(mediaOptions.type)
        ) {
            return (
                <MediaOptions
                    options={mediaOptions}
                    setOptions={setMediaOptions}
                    onCreate={handleExerciseCreate}
                />
            );
        }

        return (
            <div className={cl.exercisesContainer}>
                <div className={cl.textBlock}>
                    <p>{t('exercises.grammar_exercises')}</p>
                    <span>{t('exercises.grammar_exercises_desc')}</span>
                </div>
                <div className={cl.typesContainer}>
                    {Object.values(GrammarExerciseTypes).map((exerciseType) =>
                        renderExerciseCard(exerciseType, exerciseType)
                    )}
                </div>
                <div className={cl.textBlock}>
                    <p>{t('exercises.media_exercises')}</p>
                    <span>{t('exercises.media_exercises_desc')}</span>
                </div>
                <div className={cl.typesContainer}>
                    {Object.values(MediaExerciseTypes).map((exerciseType) =>
                        renderExerciseCard(exerciseType, MediaTypes.Video)
                    )}
                </div>

                <div className={cl.textBlock}>
                    <p>{t('exercises.text_exercises')}</p>
                    <span>{t('exercises.text_exercises_desc')}</span>
                </div>
                <div className={cl.typesContainer}>
                    {Object.values(MediaExerciseTypes).map((exerciseType) =>
                        renderExerciseCard(
                            exerciseType,
                            MediaTypes.GeneratedText
                        )
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        setOptions({ ...DefaultExerciseOptions, type: options.type });
        exercisesStore.setGeneratedText(null);
    }, [options.type]);

    useEffect(() => {
        setMediaOptions({
            ...DefaultMediaExerciseOptions,
            mediaType: mediaOptions.mediaType,
            type: mediaOptions.type,
        });
    }, [mediaOptions.type]);

    useEffect(() => {
        setOptions(DefaultExerciseOptions);
        setMediaOptions(DefaultMediaExerciseOptions);
    }, [visible]);

    useEffect(() => {
        if (!previewType) return;
        getExample();
    }, [previewType]);

    return (
        <Modal
            className={`${cl.createExercise} ${
                !options.type && !mediaOptions.type && cl.large
            }`}
            visible={visible}
            setVisible={setVisible}
        >
            {renderTitle()}
            {renderContent()}
            {renderExample()}
            <CloseButton onClick={handleCloseButton} />
        </Modal>
    );
};

export default observer(GenerateExerciseModal);
