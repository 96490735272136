import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../store/exercisesStore';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import TrackService from '../../../../api/TrackService';
import {
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersList,
    ContentContainer,
    SelectVideoModal,
} from '../../components';
import { Player } from '../../..';
import { LanguageLevels } from '../../../../data/common';
import {
    ChapterModals,
    Chapters,
    Views,
} from '../../../TeacherLessons/data/constants';
import player from '../../../../store/player';
import { AddVideoModal } from './components';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../../../teacherComponents/Onboarding';
import onboardingStore from '../../../../store/onboarding';

const TrackView = ({ isActive, isModal, alreadyAdded, onSelect }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const {
        categoryOptions,
        chaptersModalType,
        difficulty,
        existingCategoryOptions,
        trackSearch,
        trackCategory,
        unassigned,
        view,
    } = exercisesStore;

    const isLesson = view === Views.Lessons;

    const [tracks, setTracks] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [currentTrack, setCurrentTrack] = useState();

    const [getTracks, isLoading] = useFetching(async () => {
        const { data } = await TrackService.getFavorites({
            offset: 0,
            limit: 500,
            lang,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
            searchString: trackSearch,
            categories: trackCategory ? [trackCategory] : null,
            unassigned,
        });
        handleTracksResponse(data);
    });

    const handleTracksResponse = (data) => {
        const tracks = data.items ?? [];
        const withoutExisting = alreadyAdded?.length
            ? tracks.filter((e) => !alreadyAdded.some((a) => a?.id === e?.id))
            : tracks;
        setTracks(withoutExisting);
        setCurrentTrack(withoutExisting[0]);
    };

    const filterCategories = () => {
        const categoryIds = Array.from(
            new Set(tracks.flatMap((t) => t.categories).filter((c) => c))
        );

        const categoryTrackCounts = tracks.reduce((acc, track) => {
            track.categories?.forEach((categoryId) => {
                if (acc[categoryId]) {
                    acc[categoryId]++;
                } else {
                    acc[categoryId] = 1;
                }
            });
            return acc;
        }, {});

        const filteredCategoryOptions = categoryOptions
            .filter((c) => categoryIds.includes(c.value))
            .map((c) => ({
                ...c,
                name: `${c.name} (${categoryTrackCounts[c.value] || 0})`,
            }));

        exercisesStore.setExistingCategoryOptions(filteredCategoryOptions);
    };

    const [addFavoriteTrack] = useFetching(async ({ id }) => {
        const { data } = await ProgressService.addTrack({
            trackId: id,
            language: lang,
        });
        if (!data || !data.trackId) {
            getTracks();
            return;
        }
        const { data: trackData } = await TrackService.getTrack(data.trackId);
        setTracks([trackData, ...tracks]);
        setCurrentTrack(trackData);
    });

    const [addNewFavoriteTrack] = useFetching(async (trackId) => {
        await ProgressService.addTrack({
            trackId,
            language: lang,
        });
        getTracks();
    });

    const handleTrackDelete = (id) => {
        setTracks(tracks.filter((t) => t.id !== id));
    };

    const playerRenderAvailable = () =>
        isActive && chaptersModalType !== Chapters.Track;

    const handleTrackSelect = onSelect
        ? () => {
              if (!currentTrack) return;
              const { id, title } = currentTrack;
              onSelect({ id, title });
          }
        : null;

    useEffect(() => {
        setCurrentTrack();
        getTracks();
        setIsFilter(difficulty || trackSearch);
    }, [lang, difficulty, trackSearch, trackCategory, unassigned]);

    useEffect(() => {
        if (chaptersModalType === Chapters.Track) {
            player.playerRef?.destroy();
            player.setPlayerRef(null);
            player.setIsPlaying(false);
        }
    }, [chaptersModalType]);

    useEffect(() => {
        if (existingCategoryOptions.length) return;
        filterCategories();
    }, [tracks, categoryOptions]);

    return (
        <ContentContainer view={isModal ? '' : Chapters.Track}>
            <ChaptersList
                type={Chapters.Track}
                isLoading={isLoading}
                chapters={tracks}
                current={currentTrack}
                setCurrent={setCurrentTrack}
                onDelete={handleTrackDelete}
                onSelect={handleTrackSelect}
                isFilter={isFilter}
            />
            {currentTrack ? (
                <ChapterPreviewContainer>
                    {playerRenderAvailable() ? (
                        <Player track={currentTrack} />
                    ) : null}
                </ChapterPreviewContainer>
            ) : (
                <ChapterPlug />
            )}
            <AddVideoModal
                lang={lang}
                visible={chaptersModalType === ChapterModals[Chapters.Track]}
                setVisible={exercisesStore.setChaptersModalType}
                onAdd={addNewFavoriteTrack}
            />
            {!isLesson && (
                <SelectVideoModal
                    visible={chaptersModalType === Chapters.Track}
                    setVisible={exercisesStore.setChaptersModalType}
                    onClick={addFavoriteTrack}
                    alreadyAdded={tracks}
                />
            )}
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.VideosInfo
                )}
                title={t('teacher_onboarding.tracks_info')}
                subtitle={t('teacher_onboarding.tracks_info_subtitle')}
                delay={500}
            />
        </ContentContainer>
    );
};

export default observer(TrackView);
