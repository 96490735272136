import React from 'react';
import Modal from '../../../../components/UI/Modal/Modal';
import cl from './GenerateChapterModal.module.css';
import { CloseButton } from '../../../../teacherComponents';

const GenerateChapterModal = ({
    visible,
    setVisible,
    title,
    subtitle,
    children,
}) => {
    return (
        <Modal
            className={cl.generateModal}
            visible={visible}
            setVisible={setVisible}
        >
            {title && <p className={cl.title}>{title}</p>}
            {subtitle && <p className={cl.subtitle}>{subtitle}</p>}
            {children}
            <CloseButton onClick={() => setVisible(false)} />
        </Modal>
    );
};

export default GenerateChapterModal;
