import { LanguageLevels } from '../../../data/common';

export const filterThemes = (themes, filter) => {
    if (!themes) return [];
    const { age, searchString, difficulty, selected } = filter;

    const filteredBySearchString = searchString
        ? themes.filter(
              (t) =>
                  t.title?.toLowerCase().includes(searchString.toLowerCase()) ||
                  t.lessons?.some((l) =>
                      l.title
                          ?.toLowerCase()
                          .includes(searchString.toLowerCase())
                  )
          )
        : themes;

    const filteredByLessonParams = filteredBySearchString.map((t) => {
        const themeMatchesSearch = t.title
            ?.toLowerCase()
            .includes(searchString.toLowerCase());

        const filteredLessons = t.lessons?.filter((l) => {
            const matchesSearch = searchString
                ? l.title?.toLowerCase().includes(searchString.toLowerCase())
                : true;
            const matchesAge = typeof age === 'number' ? l.age === age : true;
            const matchesDifficulty = difficulty
                ? l.difficulty === LanguageLevels.indexOf(difficulty)
                : true;
            const matchesSelected = selected
                ? selected.some((s) => s.lessonId === l.id)
                : true;

            return (
                (themeMatchesSearch || matchesSearch) &&
                matchesAge &&
                matchesDifficulty &&
                matchesSelected
            );
        });

        return {
            ...t,
            lessons: filteredLessons,
        };
    });

    return age || searchString || difficulty || selected
        ? filteredByLessonParams.filter((t) => t.lessons.length)
        : filteredByLessonParams;
};
