import React from 'react';
import cl from './ErrorBoundary.module.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ errorInfo });
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={cl.errorBoundary}>
                    <h1>Something went wrong.</h1>
                    <p>Please refresh the page and try again</p>
                    <details className={cl.details}>
                        <summary>
                            Click to see error details. We would appreciate it
                            if you could share the details of this error with
                            us.
                        </summary>
                        <p>
                            <strong>Error:</strong>{' '}
                            {this.state.error?.toString()}
                        </p>
                        <p>
                            <strong>Stack Trace:</strong>{' '}
                            {this.state.errorInfo?.componentStack}
                        </p>
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
