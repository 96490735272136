import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from '../../../../UI';
import LANGUAGE_OPTIONS from '../../helpers/languageOptions';
import cl from './ControlsBar.module.css';

const ControlsBar = ({ sourceLang, setSourceLang }) => {
    const { t } = useTranslation();

    return (
        <div className={cl.controlsBar}>
            <LabeledSelect
                label={t('dictionary_administration.source_lang')}
                value={sourceLang}
                useNames={true}
                isMultiSelect={false}
                onChange={(selected) => {
                    setSourceLang(selected);
                }}
                langsSelector={true}
                options={LANGUAGE_OPTIONS}
                style={{ width: '100%', minWidth: 150 }}
            />
        </div>
    );
};

export default ControlsBar;
