import React from 'react';
import { ReactComponent as AppStoreButton } from '../../../../assets/svg/appstore-download-btn.svg';
import { ReactComponent as PlaymarketButton } from '../../../../assets/svg/playmarket-download-btn.svg';
import cl from './DownloadButton.module.css';

export const APPSTORE_LINK =
    'https://apps.apple.com/by/app/pony-learn-languages/id6475050494';

export const PLAYMARKET_LINK =
    'https://play.google.com/store/apps/details?id=app.thepony.front.android';

const DownloadButton = ({ variant, size, type }) => {
    return (
        <a
            href={type === 'appstore' ? APPSTORE_LINK : PLAYMARKET_LINK}
            target="_blank"
            rel="noreferrer"
        >
            <div className={`${cl.button} ${cl[variant]} ${cl[size]}`}>
                {type === 'appstore' ? (
                    <AppStoreButton />
                ) : (
                    <PlaymarketButton />
                )}
            </div>
        </a>
    );
};

export default DownloadButton;
