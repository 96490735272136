const isObject = (obj) => obj && typeof obj === 'object';

export const deepEqual = (obj1, obj2) => {
    if (isObject(obj1) && isObject(obj2)) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
            return false;
        }

        for (const key in obj1) {
            if (!deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    } else {
        return obj1 === obj2;
    }
};
