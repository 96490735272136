import { makeAutoObservable } from 'mobx';

export const PlayBackOptions = [0.6, 0.7, 0.8, 0.9, 1];
const DialoguesPlaybackKey = 'dialoguesPlaybackKey';

class UserDialoguesStore {
    situations = [];
    categories = [];

    difficulty = null;
    searchString = '';

    currentSituation = {};
    playbackSpeed = PlayBackOptions[PlayBackOptions.length - 1];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        this.init();
    }

    init() {
        this.playbackSpeed = localStorage.getItem(DialoguesPlaybackKey)
            ? JSON.parse(localStorage.getItem(DialoguesPlaybackKey))
            : this.playbackSpeed;
    }

    setSituations(situations) {
        this.situations = situations;
    }

    setCategories(categories) {
        this.categories = categories;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setExercisesData(data) {
        const { items, total } = data;

        this.setExercisesCount(total);
        const exercises = (
            this.page > 0 ? [...this.exercises, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        this.setExercises(exercises);
    }

    setCurrentSituation(currentSituation) {
        this.currentSituation = currentSituation;
    }

    resetSearch() {
        this.setSituations([]);
    }

    setPlaybackSpeed(playbackSpeed) {
        this.playbackSpeed = playbackSpeed;
        localStorage.setItem(
            DialoguesPlaybackKey,
            JSON.stringify(playbackSpeed)
        );
    }
}
const userDialoguesStore = new UserDialoguesStore();
export default userDialoguesStore;
