import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AdjustableTextarea } from '../../../../../../../../components';
import InputLabel from '../../../../../../../../components/UI/InputLabel/InputLabel';
import { AdvancedQuizStatuses } from '../../../../../../data/constants';
import { ToggleButton } from '../../../../../../../../teacherComponents';
import { QuestionsLabel } from '../../../Preview/components';
import { AddButton } from '../../../';
import cl from './AdvancedQuizEditor.module.css';

const AdvancedQuizEditor = ({ exerciseData, disableSave, onChange }) => {
    const { t } = useTranslation();
    const questions = exerciseData.questions || [];

    const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
    const [isInvalidQuestion, setIsInvalidQuestion] = useState(false);

    const handleQuestionAdd = () => {
        onChange({
            questions: [...questions, { id: uuid(), text: '', options: [] }],
        });
    };

    const handleQuestionTextChange = (questionId, text) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId ? { ...q, text } : q
            ),
        });
    };

    const handleQuestionStatusChange = (questionId, status) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId ? { ...q, status } : q
            ),
        });
    };

    const handleQuestionDelete = (questionId) => {
        onChange({
            questions: questions.filter((q) => q.questionId !== questionId),
        });
    };

    useEffect(() => {
        if (!questions.length) {
            setIsEmptyQuestion(false);
            return;
        }
        if (questions[questions.length - 1]) {
            if (!questions[questions.length - 1].text.length) {
                setIsEmptyQuestion(true);
            } else {
                setIsEmptyQuestion(false);
            }

            setIsInvalidQuestion(!questions[questions.length - 1]?.status);
        }
    }, [questions]);

    useEffect(() => {
        disableSave(isEmptyQuestion || isInvalidQuestion);
    }, [isEmptyQuestion, isInvalidQuestion]);

    return (
        <div className={cl.advancedQuizEditor}>
            <QuestionsLabel label={'edit_questions_answers'} />
            {questions?.length ? (
                questions.map((q, qIdx) => (
                    <div className={cl.questionCont} key={q.id}>
                        <p className={cl.questionNumber}>{`${qIdx + 1}.`}</p>
                        <AdjustableTextarea
                            className={cl.question}
                            placeholder={t('exercises.add_question')}
                            value={q.text}
                            onChange={(text) =>
                                handleQuestionTextChange(q.id, text)
                            }
                            onDelete={() => handleQuestionDelete(q.id)}
                            isFocus={!q.text.length}
                            mode={'sentence'}
                        />
                        <ToggleButton
                            isGrid
                            variant={'filled'}
                            options={AdvancedQuizStatuses}
                            value={q.status}
                            onChange={(s) =>
                                handleQuestionStatusChange(q.id, s)
                            }
                            withTranslations={'exercises'}
                            style={{ maxHeight: 25 }}
                        />
                    </div>
                ))
            ) : (
                <InputLabel text={t('exercises.no_questions')} />
            )}
            <AddButton
                text={t(
                    questions.length
                        ? 'exercises.new_question'
                        : 'exercises.first_question'
                )}
                isDisabled={isEmptyQuestion}
                onClick={handleQuestionAdd}
            />
        </div>
    );
};

export default AdvancedQuizEditor;
