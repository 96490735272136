import { useState, useEffect } from 'react';

async function startRecording(setRecorderState) {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });
        setRecorderState((prevState) => {
            return {
                ...prevState,
                initRecording: true,
                mediaStream: stream,
            };
        });
    } catch (err) {
        console.error(err);
        setRecorderState((prevState) => {
            return {
                ...prevState,
                permission: false,
            };
        });
    }
}

function saveRecording(recorder) {
    if (recorder.state !== 'inactive') recorder.stop();
}

const MAX_RECORDER_TIME = 30;

const initialState = {
    recordingSeconds: 0,
    initRecording: false,
    mediaStream: null,
    mediaRecorder: null,
    audio: null,
    permission: true,
};

export default function useRecorder() {
    const [recorderState, setRecorderState] = useState(initialState);

    useEffect(() => {
        let recordingInterval = null;
        if (recorderState.initRecording)
            recordingInterval = setInterval(() => {
                setRecorderState((prevState) => {
                    if (prevState.recordingSeconds === MAX_RECORDER_TIME) {
                        clearInterval(recordingInterval);
                        return prevState;
                    }
                    return {
                        ...prevState,
                        recordingSeconds: prevState.recordingSeconds + 1,
                    };
                });
            }, 1000);
        else clearInterval(recordingInterval);
        return () => {
            clearInterval(recordingInterval);
        };
    });

    useEffect(() => {
        if (recorderState.mediaStream)
            setRecorderState((prevState) => {
                return {
                    ...prevState,
                    mediaRecorder: new MediaRecorder(prevState.mediaStream),
                };
            });
    }, [recorderState.mediaStream]);

    useEffect(() => {
        const recorder = recorderState.mediaRecorder;
        let chunks = [];

        if (recorder && recorder.state === 'inactive') {
            recorder.start();

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/webm' });
                chunks = [];

                setRecorderState((prevState) => {
                    if (prevState.mediaRecorder)
                        return {
                            ...initialState,
                            audio: blob,
                        };
                    else return initialState;
                });
            };
        }

        return () => {
            if (recorder)
                recorder.stream
                    .getAudioTracks()
                    .forEach((track) => track.stop());
        };
    }, [recorderState.mediaRecorder]);

    return {
        recorderState,
        startRecording: () => startRecording(setRecorderState),
        cancelRecording: () => setRecorderState(initialState),
        saveRecording: () => saveRecording(recorderState.mediaRecorder),
    };
}
