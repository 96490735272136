import React from 'react';
import { useTranslation } from 'react-i18next';
import cl from './EmptyAlert.module.css';

const EmptyAlert = ({ text }) => {
    const { t } = useTranslation();
    return <p className={cl.alert}>{t(text)}</p>;
};

export default EmptyAlert;
