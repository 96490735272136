import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../../../teacherComponents';
import { useTranslation } from 'react-i18next';
import { GenerateChapterModal } from '../../../../pages/TeacherContent/components';
import cl from './AddWord.module.css';

const AddWord = ({ onAdd }) => {
    const { t } = useTranslation();

    const [word, setWord] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [visible, setVisible] = useState(false);

    const handleWordsGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                word: !word,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addWord();
        });
    };

    const addWord = () => {
        onAdd(word);
        setVisible(false);
    };

    useEffect(() => {
        setWord('');
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [word]);

    return (
        <>
            <Button
                variant={'words'}
                text={t('exercises.add_word_to_category')}
                onClick={() => setVisible(true)}
            />
            <GenerateChapterModal
                visible={visible}
                setVisible={setVisible}
                title={t('user_view.add_word')}
            >
                <Input
                    value={word}
                    onChange={setWord}
                    variant={formErrors['word'] ? 'errored' : 'outlined'}
                    placeholder={t('user_view.add_word_placeholder')}
                />
                <Button
                    className={cl.addWordButton}
                    text={t('exercises.add_word_to_category')}
                    onClick={handleWordsGenerate}
                    variant={'words'}
                />
            </GenerateChapterModal>
        </>
    );
};

export default AddWord;
