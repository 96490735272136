const dots = ['.', '?', '!', '？', '。'];
const dotsNonZh = ['.', '?', '!'];
const dotsZh = ['？', '。'];
const punctuationNonQuote = ['.', '?', '!', '？', '。', '，', ','];
const allPunctuation = ['.', '?', '!', '？', '。', '，', ',', '"', "'"];
module.exports = {
    dots,
    dotsNonZh,
    dotsZh,
    allPunctuation,
    punctuationNonQuote,
};
