import React from 'react';
import { ReactComponent as IconText } from '../../../../../../assets/svg/lessons-text.svg';
import { ReactComponent as IconVideo } from '../../../../../../assets/svg/lessons-video.svg';
import { ReactComponent as IconCloze } from '../../../../../../assets/svg/exercises-cloze.svg';
import { ReactComponent as IconSubstitution } from '../../../../../../assets/svg/exercises-substitution.svg';
import { ReactComponent as IconMultichoice } from '../../../../../../assets/svg/exercises-multichoice.svg';
import { GrammarExerciseTypes } from '../../../../data/constants';
import cl from './ExerciseMediaIcon.module.css';

const ExerciseMediaIcon = ({ type, style }) => {
    if (type === 'video')
        return <IconVideo className={cl.iconSmall} style={style} />;
    if (type === 'text') return <IconText className={cl.icon} style={style} />;

    switch (type) {
        case GrammarExerciseTypes.Cloze:
            return <IconCloze className={cl.icon} style={style} />;
        case GrammarExerciseTypes.Substitution:
            return <IconSubstitution className={cl.icon} style={style} />;
        case GrammarExerciseTypes.Multichoice:
            return <IconMultichoice className={cl.icon} style={style} />;
        default:
            return null;
    }
};

export default ExerciseMediaIcon;
