import { makeAutoObservable } from 'mobx';
import { LANGUAGES } from '../data/constants';

class Situations {
    language = LANGUAGES[0];
    languageOptions = [];
    level = null;

    categories = [];
    currentCategory = null;
    currentSituation = null;

    searchString = '';
    playbackRate = 1;

    isQueue = false;
    wasChanged = false;
    backBtnDisabled = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        this.playBackSpeed =
            localStorage.getItem('situation_statements_playback_speed') || 0;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setLanguage(language) {
        this.language = language;
        this.setSearchString('');
        this.setLevel(null);
    }

    setLevel(level) {
        this.level = level;
    }

    setWasChanged(flag) {
        this.wasChanged = flag;
    }

    setCategories(categories) {
        this.categories = categories;
    }

    setCurrentCategory(category) {
        this.currentCategory = category;
    }

    setCurrentSituation(currentSituation) {
        if (currentSituation && !currentSituation.statements) {
            currentSituation.statements = [];
        }
        this.currentSituation = currentSituation;
    }

    setPlaybackRate(playbackRate) {
        this.playbackRate = playbackRate;
        localStorage.setItem(
            'situation_statements_playback_rate',
            playbackRate
        );
    }

    setIsQueue(flag) {
        this.isQueue = flag;
    }

    setLanguageOptions(data) {
        this.languageOptions = data
            .map((lObj) => ({
                value: lObj.code.toUpperCase(),
                name: lObj.title,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    setBackBtnDisabled(flag) {
        this.backBtnDisabled = flag;
    }

    setCurrentCategoryTitle(title) {
        this.setCurrentCategory({ ...this.currentCategory, title });
    }

    resetSituations() {
        this.setLevel(null);
        this.setCurrentCategory(null);
        this.setCurrentSituation(null);
        this.setSearchString('');
    }
}

const situationsStore = new Situations();
export default situationsStore;
