import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import onboardingStore from '../../../../store/onboarding';
import DownloadButtons from '../DownloadButtons/DownloadButtons';
import { TEACHER_NAVIGATION } from '../../../../components/Header/Header';
import { ReactComponent as TeacherLogo } from '../../../../assets/svg/lessons-logo.svg';
import { OnboardingKeys } from '../../../../data/onboardingKeys';
import { Roles } from '../../../../data/common';
import cl from './Footer.module.css';

const Footer = () => {
    const { t } = useTranslation();

    const isOnboarding = onboardingStore.isOnboarding(OnboardingKeys.Start);
    const isGuest = authStore.role === Roles.Guest;

    const hideNavigation = isOnboarding || isGuest;

    return (
        <div className={cl.footer}>
            <div className={cl.navigationBlock}>
                <div className={cl.logo}>
                    <TeacherLogo className={cl.teacherLogo} />
                </div>
                <div
                    className={`${cl.navigation} ${hideNavigation ? cl.hidden : ''}`}
                >
                    {TEACHER_NAVIGATION.map((l) => (
                        <NavLink key={l.to} className={cl.link} to={l.to}>
                            {t(l.text)}
                        </NavLink>
                    ))}
                </div>
                <DownloadButtons size={'medium'} variant={'grey'} />
            </div>
            <div className={cl.greyBlock}>
                <p className={cl.link}>©Pony, 2024</p>
                <p className={cl.link}>{t('landing.commercial_terms')}</p>
                <p className={cl.link}>{t('landing.contact_us')}</p>
            </div>
        </div>
    );
};

export default Footer;
