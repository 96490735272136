import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { PrimaryInput } from '../../../../UI';
import lessonsStore from '../../store/lessonsStore';
import onboardingStore from '../../../../store/onboarding';
import { OnboardingKeys } from '../../../../data/onboardingKeys';
import {
    BubblePositions,
    OnboardingWrapper,
} from '../../../../teacherComponents/Onboarding';
import { Button } from '../../../../teacherComponents';
import { ReactComponent as IconClose } from '../../../../assets/svg/close.svg';
import cl from './AddTheme.module.css';

const AddTheme = () => {
    const { t } = useTranslation();
    const { isAddLesson } = lessonsStore;

    const onboardingKey = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitThemeAdd
    );

    const ref = useRef();

    const [title, setTitle] = useState();

    const addNewTheme = () => {
        if (!title.length) return;

        lessonsStore.addTheme({
            id: uuid(),
            title,
            lessons: [],
        });
        lessonsStore.setIsAddLesson(false);

        onboardingKey &&
            setTimeout(() => onboardingStore.moveToNextComponent());
    };

    const handleAddClick = () => {
        setTimeout(() => lessonsStore.setIsAddLesson(true));
    };

    const handleClose = () => {
        if (onboardingKey) return;

        lessonsStore.setIsAddLesson(false);
    };

    useEffect(() => {
        setTitle('');
    }, [isAddLesson]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (onboardingKey) return;
            if (ref.current && !ref.current.contains(event.target)) {
                if (title) addNewTheme();
                if (!onboardingKey) {
                    lessonsStore.setIsAddLesson(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, title, onboardingKey]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (title) addNewTheme();
                if (!onboardingKey && title) {
                    lessonsStore.setIsAddLesson(false);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [title, onboardingKey]);

    return (
        <OnboardingWrapper
            onboardingKey={onboardingKey}
            title={t('teacher_onboarding.add_theme')}
            bubblePosition={{
                x: BubblePositions.x.Center,
                y: BubblePositions.y.Bottom,
            }}
            preventClick
            delay={1000}
            bubbleAutoWidth
        >
            {isAddLesson ? (
                <div className={cl.emptyCard} ref={ref}>
                    <PrimaryInput
                        value={title}
                        placeholder={t('exercises.theme_title')}
                        onChange={setTitle}
                        autoFocus={true}
                    />
                    <div className={cl.buttons}>
                        <Button
                            text={t('exercises.add_theme')}
                            onClick={addNewTheme}
                            variant={'purple'}
                        />
                        <Button
                            icon={
                                <IconClose
                                    style={{ minWidth: 14, marginLeft: -2 }}
                                />
                            }
                            onClick={handleClose}
                            variant={'grey'}
                        />
                    </div>
                </div>
            ) : (
                <div className={cl.addTheme} onClick={handleAddClick}>
                    <p>{`+ ${t('exercises.add_theme')}`}</p>
                </div>
            )}
        </OnboardingWrapper>
    );
};

export default observer(AddTheme);
