import { makeAutoObservable } from 'mobx';

class StudentsStore {
    currentStudent = {};

    notifications = [];

    age = '';
    difficulty = '';
    studentSearch = '';

    collapseCards = true;

    lessonSearchString = '';
    lessonTheme = '';
    lessonThemeOptions = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setCurrentStudent(currentStudent) {
        this.currentStudent = currentStudent;
    }

    setNotifications(notifications) {
        this.notifications = notifications;
    }

    setLessonSearchString(lessonSearchString) {
        this.lessonSearchString = lessonSearchString;
    }

    setStudentSearch(studentSearch) {
        this.studentSearch = studentSearch;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setAge(age) {
        this.age = age;
    }

    toggleCollapseCards() {
        this.collapseCards = !this.collapseCards;
    }

    setLessonTheme(lessonTheme) {
        this.lessonTheme = lessonTheme;
    }

    setLessonThemeOptions(lessonThemeOptions) {
        this.lessonThemeOptions = lessonThemeOptions;
    }

    resetState() {
        this.currentStudent = {};

        this.notifications = [];

        this.age = '';
        this.difficulty = '';
        this.studentSearch = '';

        this.collapseCards = true;

        this.lessonSearchString = '';
        this.lessonTheme = '';
        this.lessonThemeOptions = [];
    }
}

const studentsStore = new StudentsStore();
export default studentsStore;
