import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import SituationsService from '../../../../api/SituationsService';
import FileUploader, {
    FileTypes,
} from '../../../../components/UI/FileUploader/FileUploader';
import Modal from '../../../../components/UI/Modal/Modal';
import { PrimaryButton, PrimaryInput } from '../../../../UI';
import cl from './AddCategory.module.css';

const AddCategory = ({ visible, setVisible, onAdd }) => {
    const { t } = useTranslation();

    const [title, setTitle] = useState('');
    const [addFile, setAddFile] = useState(false);
    const [file, setFile] = useState(null);

    const [addCategory, isCategoryLoading, error, resetError] = useFetching(
        async () => {
            const res = await SituationsService.addCategory({ title, file });
            if (res.status === 201) {
                onAdd();
                setVisible(false);
            }
        }
    );

    useEffect(() => {
        resetError();
    }, [title]);

    useEffect(() => {
        if (!visible) {
            setTitle('');
            setFile(null);
            setAddFile(false);
        }
    }, [visible]);

    const handleAddImage = () => {
        setAddFile(true);
    };

    return (
        <Modal visible={visible} setVisible={setVisible}>
            <div className={cl.modalInner}>
                <p className={cl.title}>{t('situations.category_add_btn')}</p>
                <PrimaryInput
                    value={title}
                    onChange={setTitle}
                    color={error ? 'red' : 'light'}
                    placeholder={t('situations.category_title')}
                />
                <div
                    className={`${cl.imageCont} ${file ? '' : cl.placeholder}`}
                    onClick={handleAddImage}
                >
                    {file ? (
                        <img
                            src={URL.createObjectURL(file)}
                            alt="category preview"
                        />
                    ) : (
                        <p>{t('courses.add_cover')}</p>
                    )}
                </div>
                <PrimaryButton
                    variant={'dark'}
                    text={t('buttons.add')}
                    onClick={addCategory}
                    isLoading={isCategoryLoading}
                    isDisabled={!title || !file}
                />
                <FileUploader
                    type={FileTypes.Image}
                    visible={addFile}
                    setVisible={setAddFile}
                    key="categoryImagePicker"
                    onSave={setFile}
                />
            </div>
        </Modal>
    );
};

export default AddCategory;
