import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import exercisesStore from '../../store/exercisesStore';
import onboardingStore from '../../../../store/onboarding';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import SituationsService from '../../../../api/SituationsService';
import {
    GenerateSituationModal,
    SelectSituationModal,
    Situation,
    SituationAlert,
} from './components';
import {
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersList,
    ContentContainer,
} from '../../components';
import {
    ChapterModals,
    Chapters,
} from '../../../TeacherLessons/data/constants';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../../../teacherComponents/Onboarding';

const SituationsView = ({ alreadyAdded, isModal, onSelect }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const {
        chaptersModalType,
        difficulty,
        situationSearch,
        unassigned,
        situations,
        situationCategory,
    } = exercisesStore;

    const [isGenerated, setIsGenerated] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [currentSituation, setCurrentSituation] = useState();
    const [isFilter, setIsFilter] = useState(false);

    const [getSituations, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getSituations({
            lang,
            unassigned,
        });
        const ids = data.items.map((s) => s.situationId);

        const { data: categories } =
            await SituationsService.getAvailableCategories({
                language: lang.toUpperCase(),
            });
        setCategories(categories);

        const existingCategories = categories
            .map((c) => ({
                ...c,
                situations: c.situations.filter((s) => ids.includes(s.id)),
            }))
            .filter((c) => c.situations.length);

        exercisesStore.setExistingSituationCategoryOptions(
            existingCategories.map((c) => ({
                value: c.id,
                name: `${c.title} (${c.situations.length})`,
            }))
        );

        const allSituations = existingCategories
            .map((c) => c.situations.filter((s) => ids.includes(s.id)))
            .flat()
            .filter((s) => (difficulty ? s.level.includes(difficulty) : s));
        const withoutExisting = alreadyAdded?.length
            ? allSituations.filter(
                  (e) => !alreadyAdded.some((a) => a.id === e.id)
              )
            : allSituations;
        exercisesStore.setSituations(withoutExisting);
    });

    const [addFavoriteSituation] = useFetching(async ({ id }) => {
        const { data } = await ProgressService.addSituation({
            situationId: id,
            language: lang,
        });
        if (!data || !data.situationId) {
            getSituations();
            return;
        }

        const newSituation = categories
            .map((c) => c.situations)
            .flat()
            .find((s) => s.id === data.situationId);
        if (!newSituation) {
            getSituations();
            return;
        }
        exercisesStore.setSituations([newSituation, ...situations]);
        setCurrentSituation(newSituation);
    });

    const handleSituationDelete = (id) => {
        exercisesStore.setSituations(situations.filter((s) => s.id !== id));
    };

    const handleSituationSelect = onSelect
        ? () => {
              if (!currentSituation) return;
              const { id, title } = currentSituation;
              onSelect({ id, title });
          }
        : null;

    useEffect(() => {
        setCurrentSituation();
        getSituations();
    }, [lang, difficulty, unassigned]);

    useEffect(() => {
        const filteredSituations = situations.filter((s) => {
            const matchesName = situationSearch
                ? s.title.toLowerCase().includes(situationSearch.toLowerCase())
                : true;

            const matchesDifficulty = difficulty
                ? s.level.includes(difficulty)
                : true;

            const matchesCategory = situationCategory
                ? s.categoryId === situationCategory
                : true;

            return matchesName && matchesDifficulty && matchesCategory;
        });
        setFiltered(filteredSituations);
        setIsFilter(difficulty || situationSearch);
    }, [difficulty, situationSearch, situations, situationCategory]);

    useEffect(() => {
        setCurrentSituation(filtered[0]);
    }, [filtered]);

    useEffect(() => {
        if (isGenerated) {
            setTimeout(() => {
                setIsGenerated(false);
            }, 5000);
        }
    }, [isGenerated]);

    return (
        <ContentContainer view={isModal ? '' : Chapters.Situation}>
            <ChaptersList
                type={Chapters.Situation}
                isLoading={isLoading}
                chapters={filtered}
                onDelete={handleSituationDelete}
                onSelect={handleSituationSelect}
                current={currentSituation}
                setCurrent={setCurrentSituation}
                isFilter={isFilter}
            />
            {currentSituation ? (
                <ChapterPreviewContainer>
                    <Situation situation={currentSituation} />
                </ChapterPreviewContainer>
            ) : (
                <ChapterPlug />
            )}
            <SelectSituationModal
                visible={chaptersModalType === Chapters.Situation}
                setVisible={exercisesStore.setChaptersModalType}
                onClick={addFavoriteSituation}
                alreadyAdded={situations}
            />
            <GenerateSituationModal
                visible={
                    chaptersModalType === ChapterModals[Chapters.Situation]
                }
                setVisible={exercisesStore.setChaptersModalType}
                onGenerate={() => setIsGenerated(true)}
            />
            <SituationAlert
                show={isGenerated}
                onClose={() => setIsGenerated(false)}
            />
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.SituationsInfo
                )}
                title={t('teacher_onboarding.situations_info')}
                subtitle={t('teacher_onboarding.situations_info_subtitle')}
                delay={500}
            />
        </ContentContainer>
    );
};

export default observer(SituationsView);
