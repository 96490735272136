import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import lessonsStore from '../../store/lessonsStore';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import { LanguageLevels } from '../../../../data/common';
import { AgeOptions } from '../../../TeacherContent/data/constants';
import {
    Button,
    ControlButton,
    Input,
    Select,
    Separator,
    ToggleButton,
    Toolbar,
} from '../../../../teacherComponents';
import { Views } from '../../data/constants';
import { ReactComponent as IconAdd } from '../../../../assets/svg/lessons-add.svg';
import teachersStore from '../../../Teachers/store/teachersStore';
import {
    OnboardingWrapper,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../../../../teacherComponents/Onboarding';
import onboardingStore from '../../../../store/onboarding';
import cl from './LessonSearchBar.module.css';

const LessonSearchBar = ({ style }) => {
    const { t } = useTranslation();
    const { age, collapseCards, difficulty, isAddLesson, searchString } =
        lessonsStore;
    const { currentTeacher } = teachersStore;
    const { view } = exercisesStore;

    const isTeacher = view === Views.Teachers && currentTeacher;
    const isOnboarding = onboardingStore.isOnboarding(OnboardingKeys.AddTheme);

    const handleCollapseClick = () => {
        lessonsStore.toggleCollapseCards();
    };

    const handleAddLesson = () => {
        if (isAddLesson) return;
        if (isOnboarding) {
            lessonsStore.setIsAddLesson(true);
            return;
        }

        lessonsStore.resetSearch();
        setTimeout(() => lessonsStore.setIsAddLesson(true));
    };

    return (
        <div className={cl.searchContainer} style={style}>
            <Toolbar>
                {!isTeacher && (
                    <>
                        <OnboardingWrapper
                            onboardingKey={onboardingStore.isOnboarding(
                                OnboardingKeys.AddTheme
                            )}
                            title={t('teacher_onboarding.add_first_theme')}
                            subtitle={t('teacher_onboarding.press_to_continue')}
                            delay={500}
                            bubblePosition={{
                                x: BubblePositions.x.LeftBorder,
                                y: BubblePositions.y.Bottom,
                            }}
                            arrowPosition={ArrowPositions.Bottom}
                        >
                            <div>
                                <Button
                                    variant={'white'}
                                    icon={<IconAdd className={cl.plusIcon} />}
                                    text={t('exercises.add_theme')}
                                    onClick={handleAddLesson}
                                />
                            </div>
                        </OnboardingWrapper>
                        <Separator />
                    </>
                )}
                <ToggleButton
                    nullable
                    options={LanguageLevels}
                    value={difficulty}
                    onChange={lessonsStore.setDifficulty}
                    variant={'round'}
                />
                <Select
                    variant={'white'}
                    style={{ minWidth: 140 }}
                    label={t('users.age')}
                    value={age}
                    options={AgeOptions}
                    onChange={lessonsStore.setAge}
                />
                <Separator />
                <Input
                    search
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={lessonsStore.setSearchString}
                    showReset
                />
                <Separator />
                <ControlButton
                    type={collapseCards ? 'uncollapse' : 'collapse'}
                    onClick={handleCollapseClick}
                />
            </Toolbar>
        </div>
    );
};

export default observer(LessonSearchBar);
