import React, { useEffect, useState } from 'react';
import shuffleArray from '../../../../../utils/shuffleArray';
import { Button } from '../../../../../teacherComponents';
import { ReactComponent as IconQuestion } from '../../../../../assets/svg/question.svg';
import cl from './LettersCard.module.css';

const LettersCard = ({ wordObj, onComplete }) => {
    const [typedWord, setTypedWord] = useState([]);
    const [letterOptions, setLetterOptions] = useState([]);
    const [isCorrect, setIsCorrect] = useState(null);
    const [isHint, setIsHint] = useState(false);
    const [audio] = useState(new Audio());

    const { word, translations, synonyms, voicePath } = wordObj;

    const handleLetterClick = (lObj) => {
        if (!lObj || isCorrect) return;
        if (!typedWord.some((w) => w.idx === lObj.idx)) {
            setTypedWord([...typedWord, lObj]);
            setLetterOptions(letterOptions.filter((w) => w.idx !== lObj.idx));
        } else {
            setTypedWord(typedWord.filter((w) => w.idx !== lObj.idx));
            setLetterOptions([...letterOptions, lObj]);
        }
    };

    const handleHintClick = () => {
        setIsHint(true);
        setTypedWord(
            word
                .toLowerCase()
                .split('')
                .map((letter, idx) => ({ letter, idx }))
        );
        setLetterOptions([]);
    };

    useEffect(() => {
        setIsHint(false);
        setTypedWord([]);
        setLetterOptions(
            shuffleArray(
                word
                    .toLowerCase()
                    .split('')
                    .map((letter, idx) => ({ letter, idx }))
            )
        );
        setIsCorrect(null);
    }, [word]);

    useEffect(() => {
        if (typedWord.length === word.length) {
            const flag =
                typedWord.map((lObj) => lObj.letter).join('') ===
                word.toLowerCase();
            setIsCorrect(flag);
        } else {
            setIsCorrect(null);
        }
    }, [typedWord]);

    useEffect(() => {
        if (voicePath) {
            audio.pause();
            audio.src = voicePath;
        } else {
            audio.src = '';
        }
    }, [voicePath]);

    useEffect(() => {
        if (isCorrect) {
            audio.play();

            const handleAudioEnd = () => {
                !isHint && onComplete();
            };

            audio.addEventListener('ended', handleAudioEnd);

            return () => {
                audio.removeEventListener('ended', handleAudioEnd);
            };
        }
    }, [isCorrect, isHint]);

    return (
        <div className={cl.lettersCard}>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(word.length)
                    .fill('')
                    .map((_t, i) => (
                        <span
                            className={cl.letter}
                            key={`answer_${i}`}
                            onTouchEnd={(e) => {
                                e.preventDefault();
                                handleLetterClick(typedWord[i]);
                            }}
                            onClick={() => handleLetterClick(typedWord[i])}
                        >
                            {typedWord[i]?.letter || ''}
                        </span>
                    ))}
            </div>
            <div className={cl.translation}>
                <p className={cl.translationText}>{translations.join(', ')}</p>
            </div>
            {isCorrect && synonyms.length && (
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            )}
            <Button
                className={`${cl.hintButton} ${isCorrect ? cl.hidden : ''}`}
                icon={<IconQuestion />}
                variant={'grey'}
                onClick={handleHintClick}
            />
            <div
                className={cl.lettersCont}
                style={{
                    opacity: isCorrect ? 0 : 1,
                    pointerEvents: isCorrect ? 'none' : 'all',
                }}
            >
                {letterOptions.map((lObj) => (
                    <div
                        className={`${cl.letter} ${
                            typedWord.some((w) => w.idx === lObj.idx)
                                ? cl.active
                                : ''
                        }`}
                        onTouchEnd={(e) => {
                            e.preventDefault();
                            handleLetterClick(lObj);
                        }}
                        onClick={() => handleLetterClick(lObj)}
                        key={`option_${lObj.idx}`}
                    >
                        {lObj.letter}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LettersCard;
