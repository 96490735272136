import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import userWordsStore from '../../pages/UserWords/store/userWords';
import { DictionaryCards } from '..';
import cl from './LearnWords.module.css';

const LearnWords = () => {
    const { dictionaryWords } = userWordsStore;

    const [isLearned, setIsLearned] = useState(null);

    const getClassName = () => {
        if (isLearned === true) return cl.continue;
        if (isLearned === false) return cl.repeat;
        return '';
    };

    return (
        <div className={`${cl.learnWords} ${getClassName()}`}>
            <DictionaryCards
                words={dictionaryWords}
                isLearned={isLearned}
                setIsLearned={setIsLearned}
            />
        </div>
    );
};

export default observer(LearnWords);
