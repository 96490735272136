export function unselectSpan() {
    document.querySelectorAll('.selectedSpan').forEach((span) => {
        span.classList.remove('selectedSpan', 'lastSelected');
    });
}

export function selectSpan(elements) {
    if (!elements) return;
    const spans = Array.isArray(elements) ? elements : [elements];

    spans.forEach((span, index) => {
        span.classList.add('selectedSpan');
        if (index === spans.length - 1) {
            span.classList.add('lastSelected');
        } else {
            span.classList.remove('lastSelected');
        }
    });
}
