import React, { useState } from 'react';
import DemoPageLoader from '../../components/UI/DemoPageLoader/DemoPageLoader';
import cl from './Button.module.css';

const LoaderColorMap = {
    grey: 'var(--black)',
    white: 'var(--black)',
    red: 'var(--primary-red)',
};

const DefaultLoaderHeight = 20;
const LoaderHeightOffset = 6;

const Button = ({
    variant,
    text,
    icon,
    onClick,
    isDisabled,
    className,
    isLoading,
    style,
    size,
}) => {
    const [isPressed, setIsPressed] = useState(false);

    const classNames = [cl.button, cl[variant]];

    if (size) classNames.push(cl[size]);
    if (isPressed) classNames.push(cl.pressed);
    if (className) classNames.push(className);

    const getLoaderColor = () => LoaderColorMap[variant] || 'var(--white)';

    const getLoaderSize = () =>
        style?.height ? style.height - LoaderHeightOffset : DefaultLoaderHeight;

    return (
        <button
            disabled={isDisabled}
            style={{ ...style, color: isLoading && 'transparent' }}
            className={classNames.join(' ')}
            onClick={onClick}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onMouseLeave={() => setIsPressed(false)}
            onTouchStart={() => setIsPressed(true)}
            onTouchEnd={() => setIsPressed(false)}
        >
            {icon && <div className={cl.icon}>{icon}</div>}
            {text}
            {isLoading && (
                <DemoPageLoader
                    size={getLoaderSize()}
                    color={getLoaderColor()}
                />
            )}
        </button>
    );
};

export default Button;
