import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

const FADE_IN_DURATION = 0.5;
const VOLUME_INCREMENT = 0.1;

const ClickableAudio = ({
    src,
    className,
    onEnd,
    onClick = () => {},
    mode = 'card',
    isPlayAvailable,
    children,
    style,
}) => {
    const [audio, setAudio] = useState(null);
    const interval = useRef(null);

    useEffect(() => {
        if (src) {
            const audioObj = new Audio(src);
            setAudio(audioObj);
            return () => {
                if (audioObj) {
                    audioObj.pause();
                    audioObj.currentTime = 0;
                }
            };
        }
    }, [src]);

    useEffect(() => {
        if (!audio) return;
        audio.addEventListener('ended', onEnd);
        return () => {
            audio.removeEventListener('ended', onEnd);
            clearInterval(interval.current);
        };
    }, [audio]);

    useEffect(() => {
        if (!audio) return;
        audio.pause();
    }, [isPlayAvailable]);

    const playRange = () => {
        audio.volume = 0;
        if (audio.paused) {
            audio.pause();
            audio.volume = 1;
            audio.currentTime = -FADE_IN_DURATION;
            audio.play();
            interval.current = setInterval(
                () => {
                    if (audio.volume + VOLUME_INCREMENT > 1) {
                        audio.volume = 1;
                        clearInterval(interval.current);
                        interval.current = null;
                        return;
                    }
                    audio.volume += VOLUME_INCREMENT;
                },
                (FADE_IN_DURATION * 1000) /
                    (FADE_IN_DURATION / VOLUME_INCREMENT)
            );
        }
    };

    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={() => {
                onClick();
                if (isPlayAvailable) {
                    setTimeout(() => {
                        playRange();
                    }, 100);
                }
            }}
        >
            {children}
        </div>
    );
};

export default observer(ClickableAudio);
