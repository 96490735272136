import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/UI/Modal/Modal';
import { DeleteConfirmation } from '../../../TeacherLessons/components';
import {
    Button,
    CloseButton,
    Input,
    Label,
    ToggleButton,
} from '../../../../teacherComponents';
import cl from './StudentCardModal.module.css';
import StudentNotes from '../StudentNotes/StudentNotes';
import { useFetching } from '../../../../hooks/useFetching';
import UserService from '../../../../api/UserService';
import { LanguageLevels } from '../../../../data/common';

const StudentCardModal = ({ student, onDelete, onChange }) => {
    const { t } = useTranslation();
    const { id } = student;

    const [isDelete, setIsDelete] = useState(false);
    const [visible, setVisible] = useState(false);
    const [nickname, setNickname] = useState(student.name);
    const [difficulty, setDifficulty] = useState(student.level);
    const [comment, setComment] = useState('');
    const [initialComment, setInitialComment] = useState('');
    const [wasChanged, setWasChanged] = useState(false);

    const [updateStudent, isLoading] = useFetching(async () => {
        await UserService.updateStudent({
            id,
            nickname,
            level: LanguageLevels.indexOf(difficulty),
        });
    });

    const handleStudentRemove = () => {
        setIsDelete(false);
        onDelete();
    };

    const handleCommentChange = (text) => {
        setComment(text);
        setWasChanged(true);
    };

    const handleSave = async () => {
        const storedComments =
            JSON.parse(localStorage.getItem('mockStudentComments')) || {};
        const updatedComments = { ...storedComments, [id]: comment };
        localStorage.setItem(
            'mockStudentComments',
            JSON.stringify(updatedComments)
        );

        await updateStudent();
        onChange({ ...student, name: nickname, level: difficulty });

        setWasChanged(false);
        setVisible(false);
    };

    useEffect(() => {
        const storedComments =
            JSON.parse(localStorage.getItem('mockStudentComments')) || {};
        if (id && storedComments[id]) {
            setComment(storedComments[id]);
            setInitialComment(storedComments[id]);
        }

        setWasChanged(false);
    }, [id]);

    useEffect(() => {
        const initialNickname = student.name;
        const initialDifficulty = student.level;

        if (
            nickname !== initialNickname ||
            difficulty !== initialDifficulty ||
            comment !== initialComment
        ) {
            setWasChanged(true);
        } else {
            setWasChanged(false);
        }
    }, [nickname, difficulty, comment, student]);

    return (
        <>
            <div className={cl.actionButton} onClick={() => setVisible(true)}>
                <span /> <span /> <span />
            </div>
            <Modal
                className={cl.studentCardModal}
                visible={visible}
                setVisible={setVisible}
            >
                <p className={cl.title}>{t('exercises.student_info')}</p>
                <div className={cl.horizontalCont}>
                    <div>
                        <Label text={t('users.name')} />
                        <Input
                            value={nickname}
                            onChange={setNickname}
                            variant={'outlined'}
                        />
                    </div>
                    <div>
                        <Label text={t('users.level')} />
                        <ToggleButton
                            style={{ width: '100%', height: 42 }}
                            isGrid
                            label={t('users.level')}
                            options={LanguageLevels}
                            value={difficulty}
                            variant={'outlined'}
                            onChange={setDifficulty}
                        />
                    </div>
                </div>

                <StudentNotes
                    comment={comment}
                    onChange={handleCommentChange}
                />
                {wasChanged ? (
                    <Button
                        isLoading={isLoading}
                        variant={'dark'}
                        text={t('buttons.save')}
                        onClick={handleSave}
                    />
                ) : (
                    <div className={cl.plug} />
                )}
                <Button
                    variant={'red'}
                    text={t('exercises.delete_student')}
                    style={{ padding: 6 }}
                    onClick={() => setIsDelete(true)}
                />
                <DeleteConfirmation
                    visible={isDelete}
                    setVisible={setIsDelete}
                    onDelete={handleStudentRemove}
                    title={t('exercises.delete_student_confirmation')}
                    subtitle={t(
                        'exercises.delete_student_confirmation_subtitle'
                    )}
                />
                <CloseButton onClick={() => setVisible(false)} />
            </Modal>
        </>
    );
};

export default StudentCardModal;
