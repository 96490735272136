import React from 'react';
import cl from './DemoPageLoader.module.css';

const DemoPageLoader = ({ className, size = 64, color = 'var(--black)' }) => {
    const style = { '--size': `${size}px`, '--color': color };
    return (
        <div
            className={`${cl.loader} ${className ? className : ''}`}
            style={style}
        >
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default DemoPageLoader;
