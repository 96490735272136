import React from 'react';
import { ReactComponent as IconCollapse } from '../../assets/svg/lessons-collapse.svg';
import { ReactComponent as IconUncollapse } from '../../assets/svg/lessons-uncollapse.svg';
import { ReactComponent as IconHelp } from '../../assets/svg/lessons-help.svg';
import cl from './Toolbar.module.css';

const Toolbar = ({ children }) => {
    return <div className={cl.toolbar}>{children}</div>;
};

const Separator = () => <span className={cl.separator} />;

const ControlButton = ({ type, onClick, style }) => {
    const getIcon = () => {
        switch (type) {
            case 'help':
                return <IconHelp style={style} />;
            case 'collapse':
                return <IconCollapse style={style} />;
            case 'uncollapse':
                return <IconUncollapse style={style} />;
            default:
                return null;
        }
    };
    return (
        <div className={cl.control} onClick={onClick}>
            {getIcon()}
        </div>
    );
};

export default Toolbar;
export { ControlButton, Separator };
