import React from 'react';
import cl from './CloseButton.module.css';

const CloseButton = ({ onClick, style, variant }) => {
    return (
        <div
            style={style}
            className={`${cl.closeButton} ${cl[variant] || ''}`}
            onMouseDown={onClick}
        />
    );
};

export default CloseButton;
