import { allPunctuation } from '../data/punctuation';

export default function replaceTrailingPunctuation(text) {
    text = text.trim();
    if (allPunctuation.includes(text[text.length - 1])) {
        text = text.substring(0, text.length - 1);
        return replaceTrailingPunctuation(text);
    }
    if (allPunctuation.includes(text[0])) {
        text = text.substring(1, text.length);
        return replaceTrailingPunctuation(text);
    }
    return text;
}
