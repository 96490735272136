import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../../../store/lessonsStore';
import languagesStore from '../../../../../../store/interface';
import teachersStore from '../../../../../Teachers/store/teachersStore';
import {
    Select,
    Input,
    MicroButton,
    Button,
} from '../../../../../../teacherComponents';
import {
    Ages,
    AgeOptions,
    LevelOptions,
} from '../../../../../TeacherContent/data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import { Views } from '../../../../data/constants';
import { ReactComponent as IconCheck } from '../../../../../../assets/svg/lessons-check.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/svg/lessons-edit.svg';
import { ReactComponent as IconDelete } from '../../../../../../assets/svg/lessons-delete.svg';
import { ReactComponent as IconEye } from '../../../../../../assets/svg/icon-eye.svg';

import cl from './ModalHeader.module.css';

const ModalHeader = ({ lesson, onUpdate }) => {
    const { t } = useTranslation();

    const { currentTeacher } = teachersStore;
    const { availableLanguages, lang, languages, nativeLang } = languagesStore;
    const { isStudentPreview } = lessonsStore;

    const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

    const [title, setTitle] = useState(lesson?.title ?? '');
    const [age, setAge] = useState(lesson?.age ?? '');
    const [difficulty, setDifficulty] = useState(lesson?.difficulty ?? '');
    const [isEdit, setIsEdit] = useState(false);

    const handleLessonUpdate = () => {
        setIsEdit(false);
        onUpdate({ title, age, difficulty });
    };

    useEffect(() => {
        setIsEdit(false);
    }, []);

    return (
        <div className={cl.lessonHeader}>
            {isEdit ? (
                <>
                    <div className={cl.inputContainer}>
                        <Input
                            variant={'filled'}
                            value={title}
                            onChange={setTitle}
                            autoFocus={true}
                        />
                    </div>

                    <Select
                        style={{ minWidth: 80, width: 80 }}
                        variant={'grey'}
                        size={'narrow'}
                        label={t('users.age')}
                        value={age}
                        options={AgeOptions}
                        onChange={setAge}
                        hideReset
                    />
                    <Select
                        style={{ minWidth: 80, width: 80 }}
                        variant={'grey'}
                        size={'narrow'}
                        label={t('users.level')}
                        value={difficulty}
                        options={LevelOptions}
                        onChange={setDifficulty}
                        hideReset
                    />
                    <MicroButton
                        icon={<IconCheck style={{ minWidth: 14 }} />}
                        size={'regular'}
                        variant={'grey'}
                        isDisabled={!title}
                        onClick={handleLessonUpdate}
                    />
                    <MicroButton
                        icon={<IconDelete style={{ minWidth: 9 }} />}
                        size={'regular'}
                        variant={'grey'}
                        onClick={() => {
                            setIsEdit(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <div className={cl.lessonInfo}>
                        <p className={cl.title}>{title}</p>

                        {typeof age === 'number' && (
                            <p className={cl.lessonInfoText}>{Ages[age]}</p>
                        )}
                        {typeof difficulty === 'number' && (
                            <p className={cl.lessonInfoText}>
                                {LanguageLevels[difficulty]}
                            </p>
                        )}

                        {!isTeacher && (
                            <IconEdit
                                onClick={() => setIsEdit(true)}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                        <Button
                            className={cl.switchModeButton}
                            variant={isStudentPreview ? 'dark' : 'white'}
                            onClick={() =>
                                lessonsStore.setIsStudentPreview(
                                    !isStudentPreview
                                )
                            }
                            text={t(
                                isStudentPreview
                                    ? 'exercises.student_preview_end'
                                    : 'exercises.student_preview_mode'
                            )}
                            icon={isStudentPreview ? null : <IconEye />}
                        />
                        {isStudentPreview && (
                            <Select
                                className={cl.targetLangSelect}
                                label={t(
                                    'dictionary_administration.target_lang'
                                )}
                                value={nativeLang}
                                options={languages.filter(
                                    (l) => l.value !== lang
                                )}
                                onChange={languagesStore.setNativeLang}
                                variant={'white'}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default observer(ModalHeader);
