import React, { useEffect, useRef, useState } from 'react';
import cl from './PrimarySelect.module.css';

const PrimarySelect = ({ value, options = [], onChange, style, height }) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef();

    function handleSelectClick(e) {
        const elClasses = e.target.classList;
        if (
            !elClasses.contains(cl.primarySelect) &&
            !elClasses.contains(cl.arrowUp) &&
            !elClasses.contains(cl.arrowDown) &&
            !elClasses.contains(cl.value)
        ) {
            return;
        }
        setExpanded(!expanded);
    }

    const handleOptionClick = (value) => {
        onChange(value);
        setExpanded(false);
    };

    const getDisplayedValue = () => {
        if (!options.length) return '';
        const current = options.find((o) => o.value === value);
        return current?.name || '';
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div
            className={`${cl.primarySelect} ${expanded ? cl.expanded : ''}`}
            style={style}
            onClick={handleSelectClick}
            ref={ref}
        >
            <div className={cl.value}>{getDisplayedValue()}</div>
            {!expanded ? (
                <div className={cl.arrowDown} />
            ) : (
                <>
                    <div className={cl.arrowUp} />
                    <div
                        className={cl.options}
                        style={height ? { height } : {}}
                    >
                        {options.map((o) => (
                            <div
                                className={cl.optionCont}
                                key={o.value}
                                onClick={() => handleOptionClick(o.value)}
                            >
                                <p
                                    className={`${cl.option} ${
                                        value === o.value ? cl.active : ''
                                    }`}
                                >
                                    {o.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default PrimarySelect;
