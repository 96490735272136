export const DictionaryCodes = {
    NotFound: '044',
    InvalidWord: '045',
    NoTranslation: '046',
    Ok: 200,
    Created: 201,
    TooEarly: 425,
};

export const DefaultWordsParams = {
    title: '',
    topic: '',
    difficulty: '',
    wordsAmount: 5,
};

export const WordsRange = {
    Min: 5,
    Max: 25,
    Step: 1,
};

export const WordStatuses = {
    Queued: 'queued',
    Pending: 'pending',
    Ready: 'ready',
    Invalid: 'invalid',
};

export const ProcessedWordsKey = 'dictionaryProcessedWords';

export const UserWordsCategory = 'userWordsCategory';
