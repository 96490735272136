import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import dictionaryStore from '../../store/dictionaryStore';
import { useTranslation } from 'react-i18next';
import DictionaryService from '../../../../api/DictionaryService';
import { useFetching } from '../../../../hooks/useFetching';
import { SearchBar, WordCard, WordChip } from '..';
import List from '../../../../components/UI/List/List';
import { WordsPaginationCount, WordStatuses } from '../../data/constants';
import { mapSort } from '../../helpers/mapSort';
import cl from './Words.module.css';

const ARROW_KEYS = {
    arrowDown: 'ArrowDown',
    arrowUp: 'ArrowUp',
};

const Words = () => {
    const { t } = useTranslation();

    const {
        page,
        paginationEnd,
        words,
        searchString,
        partOfSpeech,
        language,
        status,
        checked,
        markedIds,
        markOnOpen,
        multiselect,
        sort,
        disableAutoScroll,
        active,
    } = dictionaryStore;

    const lastScroll = useRef(0);
    const activeChipRef = useRef();

    const [getWords, isLoading] = useFetching(async () => {
        const { sortCol, sortDir } = mapSort(sort);
        const { data } = await DictionaryService.getWords({
            limit: WordsPaginationCount,
            offset: page * WordsPaginationCount,
            searchString,
            partOfSpeech,
            language,
            status,
            checked,
            sortCol,
            sortDir,
        });
        dictionaryStore.setWordsData(data);
    });

    const [markWords] = useFetching(async () => {
        const res = await DictionaryService.markWords({
            ids: markedIds,
            status: true,
        });

        if (res) {
            markedIds.forEach((id) => dictionaryStore.markWord(id));
            dictionaryStore.setMarkedIds([]);
        }
    });

    const handleListScroll = (e) => {
        if (paginationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > lastScroll.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                dictionaryStore.setNextPage();
            }
        }
        lastScroll.current = list.scrollLeft;
    };

    const scrollChipIntoView = () => {
        if (disableAutoScroll || !activeChipRef.current) return;
        activeChipRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const saveMarkedId = (word) => {
        if (!word || !word.id) return;
        if (!markOnOpen) return;
        if (checked) return;
        if (markedIds.includes(word.id)) return;
        if (word.status !== WordStatuses.ok) return;

        dictionaryStore.addMarkedId(word.id);
    };

    const handleWordChipClick = (wordObj, index) => {
        dictionaryStore.setActive({ ...wordObj, index });
    };

    const handleWordSelect = (wordObj) => {
        dictionaryStore.processSelectedWord(wordObj);
    };

    useEffect(() => {
        getWords();
    }, [page]);

    useEffect(() => {
        dictionaryStore.resetSearch();
        getWords();
    }, [searchString, partOfSpeech, language, status, checked, sort]);

    useEffect(() => {
        scrollChipIntoView();

        saveMarkedId(active);

        const handleKeyPress = (e) => {
            if (!active || !active.id) return;
            if (e.key === ARROW_KEYS.arrowDown) {
                e.preventDefault();
                const next = active.index + 1;
                if (!words[next]) return;
                dictionaryStore.setActive({ ...words[next], index: next });
            }
            if (e.key === ARROW_KEYS.arrowUp) {
                e.preventDefault();
                const prev = active.index - 1;
                if (prev < 0) return;
                dictionaryStore.setActive({ ...words[prev], index: prev });
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [active]);

    useEffect(() => {
        if (!markOnOpen) {
            dictionaryStore.setMarkedIds([]);
        }
    }, [markOnOpen]);

    useEffect(() => {
        if (!markedIds.length) return;
        markWords();
    }, [markedIds]);

    useEffect(() => {
        scrollChipIntoView();
    }, [disableAutoScroll]);

    return (
        <>
            <SearchBar />
            {words.length !== 0 ? (
                <div className={cl.wordsContainer}>
                    <List
                        onScroll={handleListScroll}
                        isLoading={isLoading}
                        style={{
                            padding: 0,
                            gap: 6,
                            overflow: 'hidden visible',
                        }}
                    >
                        {words.map((w, index) => (
                            <WordChip
                                ref={activeChipRef}
                                multiselect={multiselect}
                                key={w.id}
                                wordObj={{ ...w, index }}
                                isActive={w.id === active.id}
                                onClick={() => handleWordChipClick(w, index)}
                                onSelect={() => handleWordSelect(w)}
                            />
                        ))}
                    </List>
                    <WordCard />
                </div>
            ) : (
                <p className={cl.emptyAlert}>
                    {t('dictionary_administration.empty_list')}
                </p>
            )}
        </>
    );
};

export default observer(Words);
