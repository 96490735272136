import React from 'react';
import cl from './ContentContainer.module.css';

const ContentContainer = ({ className, children }) => {
    return (
        <div className={`${cl.contentContainer} ${className ?? ''}`}>
            {children}
        </div>
    );
};

export default ContentContainer;
