import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../teacherComponents';
import cl from './StudentNotes.module.css';

const StudentNotes = ({ comment, onChange }) => {
    const { t } = useTranslation();
    const ref = useRef();

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <>
            <div>
                <Label text={t('exercises.comments')} />
                <textarea
                    className={cl.note}
                    ref={ref}
                    placeholder={t('exercises.comments')}
                    onChange={handleChange}
                    value={comment ?? ''}
                    rows={1}
                />
            </div>
        </>
    );
};

export default StudentNotes;
