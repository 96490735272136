import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionsLabel } from '..';
import { AdjustableTextarea } from '../../../../../../../../components';

import cl from './FreeFormQuestionsPreview.module.css';

const FreeFormQuestionsPreview = ({ exerciseObj, results, showInput }) => {
    const { t } = useTranslation();
    const { questions } = exerciseObj?.data;

    const [answer, setAnswer] = useState('');

    useEffect(() => {
        setAnswer('');
    }, [exerciseObj]);

    if (results && results.enteredText) {
        return (
            <>
                <QuestionsLabel label={'student_answer'} />
                <p className={cl.studentText}>{results.enteredText}</p>
            </>
        );
    }

    if (results && results.userAnswers) {
        const answers = Object.values(results.userAnswers);
        return (
            <>
                <div className={cl.questions}>
                    {questions.map((q, i) => (
                        <Fragment key={q.id}>
                            <p
                                className={cl.questionGrey}
                            >{`${i + 1}. ${q.question}`}</p>
                            <p className={cl.studentText}>{answers[i]}</p>
                        </Fragment>
                    ))}
                </div>
            </>
        );
    }

    return questions ? (
        <>
            <QuestionsLabel label={'questions'} />
            <div className={cl.questions}>
                {questions.map((q, i) => (
                    <p
                        className={cl.question}
                        key={q.id}
                    >{`${i + 1}. ${q.question}`}</p>
                ))}
            </div>
            <AdjustableTextarea
                className={cl.answers}
                placeholder={t('exercises.answer')}
                value={answer}
                onChange={setAnswer}
            />
        </>
    ) : null;
};

export default FreeFormQuestionsPreview;
