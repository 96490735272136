import React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import onboardingStore from '../../../../store/onboarding';
import {
    ArrowPositions,
    BubblePositions,
    OnboardingKeys,
    OnboardingWrapper,
} from '../../../../teacherComponents/Onboarding';
import { StudentLesson } from '..';
import { StudentLessonStatuses } from '../../../TeacherLessons/data/constants';
import cl from './StudentTheme.module.css';

const onboardingContentMap = (t) => ({
    [StudentLessonStatuses.Available]: {
        onboardingKey: OnboardingKeys.AvailableColumn,
        title: t('teacher_onboarding.available_column_title'),
        subtitle: t('teacher_onboarding.available_column_subtitle'),
        altTitle: t('teacher_onboarding.available_column_alt_title'),
        altSubtitle: t('teacher_onboarding.available_column_alt_subtitle'),
    },
    [StudentLessonStatuses.Done]: {
        onboardingKey: OnboardingKeys.ShowDoneColumn,
        title: t('teacher_onboarding.done_column_title'),
    },
});

const StudentTheme = ({ themeObj }) => {
    const { t } = useTranslation();
    const { id, title, lessons } = themeObj;

    const getOnboardingContent = () => {
        const content = onboardingContentMap(t)[id] || {
            onboardingKey: null,
            title: '',
            subtitle: '',
            altTitle: '',
            altSubtitle: '',
        };

        const isAltOnboardingActive =
            id === StudentLessonStatuses.Available &&
            onboardingStore.isOnboarding(OnboardingKeys.AvailableLessons);

        return {
            onboardingKey:
                onboardingStore.isOnboarding(content.onboardingKey) ||
                (id === StudentLessonStatuses.Available &&
                    onboardingStore.isOnboarding(
                        OnboardingKeys.AvailableLessons
                    )),
            title: isAltOnboardingActive ? content.altTitle : content.title,
            subtitle: isAltOnboardingActive
                ? content.altSubtitle
                : content.subtitle,
            x: BubblePositions.x.Left,
            y: BubblePositions.y.Center,
        };
    };

    const {
        onboardingKey,
        title: onboardingTitle,
        subtitle: onboardingSubtitle,
        x,
        y,
    } = getOnboardingContent();

    const getAlert = (theme) => t(`exercises.no_${theme}_lessons`);

    const getOnboardingKey = (index) => {
        if (index !== 0 || id !== StudentLessonStatuses.Available) return null;
        return onboardingStore.isOnboarding(OnboardingKeys.DragLessonInfo);
    };

    return (
        <OnboardingWrapper
            onboardingKey={onboardingKey}
            title={onboardingTitle}
            subtitle={onboardingSubtitle}
            delay={id === StudentLessonStatuses.Done ? 1200 : 1000}
            bubblePosition={{
                x,
                y,
            }}
            showButton
            arrowPosition={ArrowPositions.LeftClose}
        >
            <div className={cl.themeWrap}>
                <Droppable droppableId={id}>
                    {(droppableProvided) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                            className={cl.theme}
                        >
                            <p className={cl.title}>{title}</p>
                            <div className={cl.lessons}>
                                {lessons?.length ? (
                                    lessons
                                        .sort((a, b) =>
                                            a.title.localeCompare(b.title)
                                        )
                                        .map((lesson, index) => (
                                            <OnboardingWrapper
                                                key={`${lesson.id}_${index}`}
                                                onboardingKey={getOnboardingKey(
                                                    index
                                                )}
                                                title={t(
                                                    'teacher_onboarding.share_lesson_title'
                                                )}
                                                subtitle={t(
                                                    'teacher_onboarding.share_lesson_subtitle'
                                                )}
                                                delay={500}
                                                bubblePosition={{
                                                    x: BubblePositions.x.Left,
                                                    y: BubblePositions.y.Center,
                                                }}
                                                showButton
                                            >
                                                <div style={{ minHeight: 62 }}>
                                                    <StudentLesson
                                                        lessonObj={lesson}
                                                        index={index}
                                                        themeId={id}
                                                    />
                                                </div>
                                            </OnboardingWrapper>
                                        ))
                                ) : (
                                    <p className={cl.emptyThemeAlert}>
                                        {getAlert(id)}
                                    </p>
                                )}
                                {droppableProvided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        </OnboardingWrapper>
    );
};

export default observer(StudentTheme);
