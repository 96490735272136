import { Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { AdjustableTextarea } from '../../../../../../../../../components';
import { ReactComponent as IconDrag } from '../../../../../../../../../assets/svg/exercises-drag.svg';
import cl from './SentenceCard.module.css';

const SentenceCard = ({ sentence, index, onChange, onDelete }) => {
    const { t } = useTranslation();

    const { id, text } = sentence;

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <div
                    className={cl.sentenceCont}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <IconDrag />
                    <AdjustableTextarea
                        className={cl.sentence}
                        placeholder={t('exercises.add_sentence')}
                        value={text}
                        onChange={(text) => onChange(index, text)}
                        onDelete={() => onDelete(index)}
                        isFocus={!text.length}
                        mode={'sentence'}
                    />
                </div>
            )}
        </Draggable>
    );
};

export default SentenceCard;
