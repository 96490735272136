import React from 'react';
import cl from './ViewTitle.module.css';

const ViewTitle = ({ text, icon, children, style }) => {
    return (
        <div className={cl.titleContainer} style={style}>
            {children}
            {icon && <div className={cl.icon}>{icon}</div>}
            <p className={cl.title}>{text}</p>
        </div>
    );
};

export default ViewTitle;
