import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { onboardingStore } from '../OnboardingWrapper/OnboardingWrapper';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../Button/Button';
import cl from './OnboardingModal.module.css';

const OnboardingModal = ({
    onboardingKey,
    title,
    subtitle,
    delay,
    style,
    onClose,
}) => {
    const { t } = useTranslation();

    const { isDelay } = onboardingStore;

    const handleButtonClick = () => {
        onClose ? onClose() : onboardingStore.moveToNextComponent();
    };

    useEffect(() => {
        if (delay && onboardingKey) {
            onboardingStore.setIsDelay(true);

            setTimeout(() => {
                onboardingStore.setIsDelay(false);
            }, delay);
        }
    }, [onboardingKey, delay]);

    if (!onboardingKey || isDelay) return null;

    return (
        <Modal
            visible={true}
            setVisible={() => {}}
            className={cl.intro}
            style={style}
            addBlur
        >
            <p className={cl.title}>{title}</p>
            <p className={cl.subtitle}>{subtitle}</p>
            <Button
                variant={'purple'}
                text={t('teacher_onboarding.close_button')}
                onClick={handleButtonClick}
            />
        </Modal>
    );
};

export default observer(OnboardingModal);
