import { makeAutoObservable } from 'mobx';

class TeacherStore {
    currentTeacher = null;

    searchString = '';
    teacherSearchString = '';
    difficulty = '';

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setCurrentTeacher(currentTeacher) {
        this.currentTeacher = currentTeacher;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setTeacherSearchString(teacherSearchString) {
        this.teacherSearchString = teacherSearchString;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }
}

const teachersStore = new TeacherStore();
export default teachersStore;
