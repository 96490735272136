import { makeAutoObservable } from 'mobx';
import ProgressService from '../api/ProgressService';
import DictionaryService from '../api/DictionaryService';
import {
    DictionaryCodes,
    ProcessedWordsKey,
    UserWordsCategory,
    WordStatuses,
} from '../pages/TeacherContent/views/DictionaryView/data/constants';

class WordsStore {
    processedWords = [];
    intervalRef = null;
    lang = 'en';

    constructor() {
        makeAutoObservable(this);
    }

    init(lang) {
        this.lang = lang;
        this.getProcessedWords();
    }

    setLang(lang) {
        this.lang = lang;
    }

    setProcessedWords(processedWords) {
        this.processedWords = processedWords;
        localStorage.setItem(ProcessedWordsKey, JSON.stringify(processedWords));
    }

    getProcessedWords() {
        const savedData = localStorage.getItem(ProcessedWordsKey);
        const processedWords = savedData ? JSON.parse(savedData) : [];
        this.setProcessedWords(processedWords);
    }

    addWord({ word, categoryId, status }) {
        if (!word) return;

        const newProcessedWords = [
            ...this.processedWords.filter(
                (w) => !(w.word === word && w.categoryId === categoryId)
            ),
            { word, categoryId, status },
        ];
        this.setProcessedWords(newProcessedWords);
    }

    deletePendingWord({ word, categoryId }) {
        const newProcessedWords = this.processedWords.filter(
            (w) => !(w.word === word && w.categoryId === categoryId)
        );
        this.setProcessedWords(newProcessedWords);
    }

    updatePendingWord({ word, categoryId, status }) {
        this.setProcessedWords(
            this.processedWords.map((w) =>
                w.word === word && w.categoryId === categoryId
                    ? { ...w, status }
                    : w
            )
        );
    }

    async processWord(wObj) {
        if (![WordStatuses.Queued, WordStatuses.Pending].includes(wObj.status))
            return;
        const { word, categoryId, targetLang } = wObj;
        const {
            data: wordObj,
            status,
            errorCode,
        } = await DictionaryService.getWordByText({
            word,
            sourceLang: this.lang,
            targetLang: targetLang ?? this.lang,
        });

        if (status === DictionaryCodes.Ok) {
            const wordId = wordObj.data[0].id;

            await this.keepWord({ word, wordId, categoryId });
        } else {
            if (errorCode === DictionaryCodes.InvalidWord) {
                this.updatePendingWord({
                    word,
                    categoryId,
                    status: WordStatuses.Invalid,
                });
                return;
            }
            if (wObj.status === WordStatuses.Queued) {
                await this.sendAddRequest({ word, targetLang });
                this.updatePendingWord({
                    word,
                    categoryId,
                    status: WordStatuses.Pending,
                });
            }
        }
    }

    async sendAddRequest({ word, targetLang }) {
        await DictionaryService.addWord({
            word,
            sourceLang: this.lang,
            targetLang: targetLang
                ? targetLang
                : this.lang === 'en'
                  ? 'de'
                  : 'en',
        });
    }

    async keepWord({ word, wordId, categoryId }) {
        await ProgressService.addFavoriteWord({
            id: wordId,
            word,
            lang: this.lang,
        });

        if (categoryId !== UserWordsCategory) {
            try {
                await ProgressService.addCategoryWord({
                    categoryId,
                    word,
                    lang: this.lang,
                });
            } catch (e) {
                console.error(e);
            }
        }

        this.updatePendingWord({
            word,
            categoryId,
            status: WordStatuses.Ready,
        });
    }

    processWords() {
        const active = this.processedWords.filter(
            (w) =>
                ![WordStatuses.Invalid, WordStatuses.Ready].includes(w.status)
        );
        for (const word of active) {
            this.processWord(word);
        }
    }

    startProcessingWords() {
        clearInterval(this.intervalRef);

        this.intervalRef = setInterval(() => {
            this.processWords();
        }, 3000);
    }

    stopProcessingWords() {
        clearInterval(this.intervalRef);
    }
}

const wordsStore = new WordsStore();
export default wordsStore;
