import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import shuffleArray from '../../../../../../../../utils/shuffleArray';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { ReactComponent as IconDrag } from '../../../../../../../../assets/svg/exercises-drag.svg';
import cl from './SentenceOrderPreview.module.css';

const SentenceCard = ({ id, index, text, showAnswers, isCorrect }) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div
                    className={`${cl.sentenceCont} ${
                        showAnswers ? (isCorrect ? cl.correct : cl.wrong) : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <IconDrag />
                    {text}
                </div>
            )}
        </Draggable>
    );
};

const SentenceOrderPreview = ({ exerciseObj, results, showAnswers }) => {
    const { data } = exerciseObj;
    const { sentences } = data;
    const [previewSentences, setPreviewSentences] = useState([]);

    const displayAnswers = () => {
        setPreviewSentences(sentences);
    };

    useEffect(() => {
        if (!sentences || !sentences.length) return;
        let newSentences = shuffleArray(sentences);
        if (sentences.length > 2) {
            while (JSON.stringify(sentences) === JSON.stringify(newSentences)) {
                newSentences = shuffleArray(sentences);
            }
        }
        setPreviewSentences(newSentences);
    }, [sentences]);

    useEffect(() => {
        showAnswers && displayAnswers();
    }, [showAnswers]);

    useEffect(() => {
        if (results && results.userAnswers) {
            setPreviewSentences(
                results.userAnswers.map((s, i) => ({ id: i, text: s }))
            );
        }
    }, [results]);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newSentences = [...previewSentences];
        const [removedSentence] = newSentences.splice(source.index, 1);
        newSentences.splice(destination.index, 0, removedSentence);
        setPreviewSentences(newSentences);
    };

    return (
        <>
            {results && <QuestionsLabel label={'student_answers'} />}
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sentence-list">
                    {(provided) => (
                        <div
                            className={`${cl.answersCont} ${results ? cl.blocked : ''}`}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {previewSentences.map((s, index) => (
                                <SentenceCard
                                    key={s.id}
                                    id={s.id}
                                    index={index}
                                    text={s.text ?? ''}
                                    showAnswers={showAnswers || results}
                                    isCorrect={sentences[index].text === s.text}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default SentenceOrderPreview;
