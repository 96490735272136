export const OnboardingKeys = {
    Start: 'start',
    Confirmation: 'confirmation',
    StudentsNavigation: 'students_navigation',
    StudentsInfo: 'students_info',
    AddStudent: 'add_student',
    NewStudent: 'new_student',
    AvailableColumn: 'available_column',
    LessonsNavigation: 'lessons_navigation',
    LessonsInfo: 'lessons_info',
    AddTheme: 'add_theme',
    AwaitThemeAdd: 'await_theme_add',
    AddLesson: 'add_lesson',
    AwaitLessonAdd: 'await_lesson_add',
    SelectLesson: 'select_lesson',
    LessonAddChapter: 'lesson_add_chapter',
    ExercisesContentSelect: 'exercises_content_select',
    AddChapterInfo: 'add_chapter_info',
    OpenPonyLibrary: 'open_pony_library',
    ChapterCard: 'chapter_card',
    AwaitAddChapter: 'await_add_chapter',
    ChapterAdded: 'chapter_added',
    AwaitLessonModalClose: 'await_lesson_modal_close',
    StudentsKanban: 'students_kanban',
    AvailableLessons: 'available_lessons',
    DragLessonInfo: 'drag_lesson_info',
    AwaitLessonDrag: 'await_lesson_drag',
    ShowDoneColumn: 'show_done_column',
    StudentsDone: 'students_done',
    ContentNavigation: 'content_navigation',
    TeachersNavigation: 'teachers_navigation',
    OnboardingDone: 'onboarding_done',
};
