import { makeAutoObservable } from 'mobx';

class AdminExercisesStore {
    exercises = [];
    total = 0;

    page = 0;
    difficulty = null;
    language = null;
    type = null;
    searchString = '';
    paginationEnd = false;

    currentExercise = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setExercises(exercises) {
        this.exercises = exercises;
    }

    setTotal(total) {
        this.total = total;
    }

    setPaginationEnd(paginationEnd) {
        this.paginationEnd = paginationEnd;
    }

    setExercisesData(data) {
        const { items, total } = data;
        if (!items.length) {
            this.setPaginationEnd(true);
        }
        this.setTotal(total);
        const exercises = (
            this.page > 0 ? [...this.exercises, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        this.setExercises(exercises);
    }

    setPage(page) {
        this.page = page;
    }

    setNextPage() {
        this.setPage(this.page + 1);
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setLanguage(language) {
        this.language = language;
    }

    setType(type) {
        this.type = type;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    updateExercise(exercise) {
        this.setExercises(
            this.exercises.map((e) => (e.id === exercise.id ? exercise : e))
        );
    }

    deleteExercise(exercise) {
        this.setExercises(this.exercises.filter((e) => e.id !== exercise.id));
        this.setTotal(this.total - 1);
    }

    setCurrentExercise(currentExercise) {
        this.currentExercise = currentExercise;
    }
}

const adminExercisesStore = new AdminExercisesStore();
export default adminExercisesStore;
