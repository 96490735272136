import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import exercisesStore from '../../../../store/exercisesStore';
import {
    Button,
    CloseButton,
    Input,
    Label,
    ToggleButton,
} from '../../../../../../teacherComponents';
import {
    AdvancedQuizEditor,
    ClozeEditor,
    MultichoiceEditor,
    QuestionsEditor,
    QuizEditor,
    SentenceOrderEditor,
    SubstitutionEditor,
    SummaryEditor,
    WordsOrderEditor,
} from './components';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import { AdjustableTextarea } from '../../../../../../components';
import { DeleteConfirmation } from '../../../../../TeacherLessons/components';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { deepEqual } from '../../../../../../utils/deepEqual';
import cl from './EditExerciseModal.module.css';

const EditExerciseModal = ({ currentExercise, onChange }) => {
    const { t } = useTranslation();

    const { editMode } = exercisesStore;
    const {
        id,
        type,
        categoryId,
        difficulty,
        title,
        text,
        trackId,
        description,
        data,
    } = currentExercise;

    const [newData, setNewData] = useState({});

    const [isConfirm, setIsConfirm] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [wasChanged, setWasChanged] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [newTitle, setNewTitle] = useState(title);
    const [newDesc, setNewDesc] = useState(description);
    const [newText, setNewText] = useState(text);
    const [newDifficulty, setNewDifficulty] = useState(difficulty);

    const [updateExercise, updateLoading] = useFetching(async () => {
        setIsConfirm(false);
        const { data: exerciseObj } = await ExerciseService.updateExercise({
            id,
            type,
            categoryId,
            difficulty: newDifficulty,
            title: newTitle,
            data: newData,
            description: newDesc,
            text: newText,
        });
        onChange({ ...exerciseObj, data: newData });

        exercisesStore.updateExercise({ ...exerciseObj, data: newData });
    });

    const [deleteExercise, deleteLoading] = useFetching(async () => {
        const res = await ExerciseService.deleteExercise({ id });
        if (res) {
            exercisesStore.deleteExercise(id);
        }
    });

    const handleTitleChange = (title) => {
        setNewTitle(title);
    };

    const handleDescChange = (description) => {
        setNewDesc(description);
    };

    const handleTextChange = (text) => {
        setNewText(text);
    };

    const handleDifficultyChange = (difficulty) => {
        setNewDifficulty(LanguageLevels.indexOf(difficulty));
    };

    const renderEditor = () => {
        switch (type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoiceEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozeEditor exerciseData={newData} onChange={setNewData} />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <QuestionsEditor
                        exerciseData={newData}
                        disableSave={setSaveDisabled}
                        onChange={setNewData}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizEditor
                        exerciseData={newData}
                        onChange={setNewData}
                        disableSave={setSaveDisabled}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );

            case MediaExerciseTypes.Summary:
                return (
                    <SummaryEditor
                        exerciseData={newData}
                        onChange={setNewData}
                        disableSave={setSaveDisabled}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizEditor
                        exerciseData={newData}
                        disableSave={setSaveDisabled}
                        onChange={setNewData}
                    />
                );
            default:
                return <></>;
        }
    };

    const isSaveDisabled = () => {
        if (!title?.length) return true;
        if (saveDisabled) return true;
        return false;
    };

    const handleDeleteButton = (e) => {
        e.stopPropagation();
        setIsDelete(true);
    };

    const resetData = () => {
        setNewData({});
        setWasChanged(false);
    };

    const handleClose = () => {
        if (!wasChanged) {
            exercisesStore.setEditMode(false);
        } else {
            setIsConfirm(true);
        }
    };

    const handleEditingReset = () => {
        exercisesStore.setEditMode(false);
        setIsConfirm(false);
    };

    const checkIfChanged = () => {
        return (
            !deepEqual(currentExercise, newData) ||
            title !== newTitle ||
            description !== newDesc ||
            text !== newText ||
            difficulty !== newDifficulty
        );
    };

    useEffect(() => {
        resetData();

        if (!currentExercise.id || !editMode) return;

        setSaveDisabled(false);
        return () => {
            exercisesStore.setEditMode(false);
        };
    }, [currentExercise.id, editMode]);

    useEffect(() => {
        setWasChanged(checkIfChanged());
    }, [data, newData, newTitle, newDesc, newText, newDifficulty]);

    useEffect(() => {
        setNewData(data);
    }, [data]);

    useEffect(() => {
        setNewTitle(title);
        setNewDesc(description);
        setNewText(text);
        setNewDifficulty(difficulty);
    }, [title, description, text, difficulty]);

    return (
        Object.keys(currentExercise).length !== 0 && (
            <>
                <Modal
                    visible={editMode}
                    setVisible={handleClose}
                    className={cl.editModal}
                    style={{ zIndex: 100 }}
                >
                    <p className={cl.title}>{t('exercises.edit_exercise')}</p>
                    <div className={cl.editorContainer}>
                        <div className={cl.horizontalCont}>
                            <div style={{ width: '100%' }}>
                                <Label text={t('exercises.exercise_title')} />
                                <Input
                                    variant={'outlinedSmall'}
                                    style={{ width: '100%' }}
                                    value={newTitle}
                                    placeholder={t(
                                        'exercises.title_placeholder'
                                    )}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <ToggleButton
                                options={LanguageLevels}
                                value={LanguageLevels[newDifficulty]}
                                onChange={handleDifficultyChange}
                                variant={'filled'}
                            />
                        </div>
                        <div>
                            <Label text={t('exercises.exercise_explanatory')} />
                            <Input
                                variant={'outlinedSmall'}
                                style={{ width: '100%' }}
                                value={newDesc}
                                placeholder={t(
                                    'exercises.explanatory_placeholder'
                                )}
                                onChange={handleDescChange}
                            />
                        </div>
                        {newText !== null && !trackId && (
                            <div>
                                <Label text={t('exercises.text')} />
                                <AdjustableTextarea
                                    value={newText}
                                    placeholder={t('exercises.text')}
                                    onChange={handleTextChange}
                                    style={{ fontSize: 12 }}
                                />
                            </div>
                        )}
                        {renderEditor()}
                    </div>
                    <div className={cl.buttonsContainer}>
                        {wasChanged && (
                            <Button
                                text={t('buttons.save')}
                                variant={'dark'}
                                onClick={updateExercise}
                                isLoading={updateLoading}
                                isDisabled={isSaveDisabled()}
                            />
                        )}
                        <Button
                            text={t('buttons.close')}
                            variant={'grey'}
                            onClick={handleClose}
                        />
                        <Button
                            text={t('buttons.delete')}
                            variant={'red'}
                            onClick={handleDeleteButton}
                            isLoading={deleteLoading}
                            style={{ marginLeft: 'auto' }}
                        />
                    </div>
                    <DeleteConfirmation
                        title={t(
                            `exercises.delete_exercise_confirmation_title`
                        )}
                        visible={isDelete}
                        setVisible={setIsDelete}
                        onDelete={deleteExercise}
                    />
                    <CloseButton
                        onClick={() => exercisesStore.setEditMode(false)}
                    />
                </Modal>
                <DeleteConfirmation
                    title={t(`exercises.update_exercise_confirmation_title`)}
                    visible={isConfirm}
                    setVisible={setIsConfirm}
                    onReset={handleEditingReset}
                    onDelete={updateExercise}
                    isSave={true}
                    style={{ zIndex: 100 }}
                />
            </>
        )
    );
};

export default observer(EditExerciseModal);
