import React, { useEffect, useRef, useState } from 'react';
import shuffleArray from '../../../../../utils/shuffleArray';
import { Button } from '../../../../../teacherComponents';
import { ReactComponent as IconQuestion } from '../../../../../assets/svg/question.svg';
import cl from './PhraseCard.module.css';

const PhraseCard = ({ wordObj, onComplete }) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [isCorrect, setIsCorrect] = useState(null);
    const [wordsAmount, setWordsAmount] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [options, setOptions] = useState([]);
    const [isHint, setIsHint] = useState(false);
    const [audio] = useState(new Audio());

    const timeoutRef = useRef();

    const examples = wordObj.examples.map((eObj) => eObj.example);
    const translations = wordObj.examples.map((eObj) => eObj.translation);
    const sentence = examples[currentIdx] || '';

    const handleWordClick = (wObj) => {
        if (!wObj) return;
        if (!answers.some((w) => w.idx === wObj.idx)) {
            setAnswers([...answers, wObj]);
            setOptions(options.filter((w) => w.idx !== wObj.idx));
        } else {
            setAnswers(answers.filter((w) => w.idx !== wObj.idx));
            setOptions([...options, wObj]);
        }
    };

    const checkAnswer = () => {
        const flag =
            answers
                .map((wObj) => wObj.word)
                .join(' ')
                .toLowerCase() === sentence.toLowerCase();
        setIsCorrect(flag);
    };

    const playAudio = () => {
        audio.pause();
        const voicePath = wordObj.examples[currentIdx]?.voicePath;
        audio.src = voicePath;
        audio.play();
    };

    const handleNextSentence = () => {
        if (currentIdx < examples.length - 1) {
            setCurrentIdx(currentIdx + 1);
        } else {
            isHint ? setCurrentIdx(currentIdx + 1) : onComplete();
        }
    };

    const handleHintClick = () => {
        setIsHint(true);
        setAnswers(
            sentence.split(' ').map((word, idx) => ({
                word,
                idx,
            }))
        );
        setOptions([]);
    };

    useEffect(() => {
        setIsHint(false);
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
    }, [wordObj]);

    useEffect(() => {
        setAnswers([]);
        if (!sentence.length) {
            setOptions([]);
            setWordsAmount(0);
            return;
        }
        const optionsArr = shuffleArray(sentence.split(' ')).map(
            (word, idx) => ({
                word,
                idx,
            })
        );
        setOptions(optionsArr);
        setWordsAmount(optionsArr.length);
    }, [currentIdx]);

    useEffect(() => {
        if (isCorrect) {
            playAudio();

            audio.addEventListener('ended', handleNextSentence);

            return () => {
                audio.removeEventListener('ended', handleNextSentence);
            };
        }
    }, [isCorrect]);

    useEffect(() => {
        if (answers.length === wordsAmount) {
            checkAnswer();
        } else {
            setIsCorrect(null);
        }
    }, [answers]);

    return (
        <div className={cl.phraseCard}>
            <div className={cl.translationList}>
                {examples
                    .filter((_e, i) => i < currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText} ${
                                currentIdx > idx
                                    ? cl.correct
                                    : currentIdx === idx
                                      ? cl.active
                                      : ''
                            }`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(wordsAmount)
                    .fill('')
                    .map((_w, idx) => (
                        <span
                            className={cl.word}
                            style={{ minWidth: answers[idx] ? 'unset' : 75 }}
                            key={`answer_${idx}`}
                            onClick={() => handleWordClick(answers[idx])}
                        >
                            {answers[idx]?.word || ''}
                        </span>
                    ))}
            </div>
            <div className={cl.translationList}>
                {translations
                    .filter((_e, i) => i >= currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText}  ${idx === 0 ? cl.active : ''}`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            <Button
                className={`${cl.hintButton} ${isCorrect ? cl.hidden : ''}`}
                icon={<IconQuestion />}
                variant={'grey'}
                onClick={handleHintClick}
            />
            {translations[currentIdx] && (
                <div className={cl.optionsCont}>
                    {options.map((wObj, idx) => (
                        <span
                            className={cl.word}
                            key={`option_${idx}`}
                            onClick={() => handleWordClick(wObj)}
                        >
                            {wObj.word}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhraseCard;
