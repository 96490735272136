import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppStoreUrl, PlayMarketUrl, ShareUrl } from './data/constants';
import { BaseUrl } from '../../data/common';

const SituationShare = () => {
    const location = useLocation();
    const [fallbackUrl, setFallbackUrl] = useState('');

    const isAndroid = () => /Android/i.test(navigator.userAgent);
    const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

    useEffect(() => {
        if (isAndroid()) {
            setFallbackUrl(PlayMarketUrl);
            return;
        }
        if (isIOS()) {
            setFallbackUrl(AppStoreUrl);
            return;
        }
        setFallbackUrl(BaseUrl);
    }, []);

    useEffect(() => {
        window.location.href = `${ShareUrl}${location.search}`;
    }, []);

    return <></>;
};

export default SituationShare;
