import React from 'react';
import cl from './AddButton.module.css';

const AddButton = ({ text, isDisabled, onClick }) => {
    return (
        <p
            onClick={onClick}
            className={`${cl.addButton} ${isDisabled ? cl.disabled : ''}`}
        >
            {text}
        </p>
    );
};

export default AddButton;
