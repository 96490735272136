import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../../../App';
import { useFetching } from '../../../../../../hooks/useFetching';
import exercisesStore from '../../../../store/exercisesStore';
import SituationsService from '../../../../../../api/SituationsService';
import {
    Button,
    Input,
    Label,
    Select,
    ToggleButton,
} from '../../../../../../teacherComponents';
import { Checkbox } from '../../../../../../UI';
import FileUploader, {
    FileTypes,
} from '../../../../../../components/UI/FileUploader/FileUploader';
import {
    GENDERS,
    LEVELS,
    MODALITIES,
} from '../../../../../SituationsAdministration/data/constants';
import { ICONS } from '../../../../../SituationsAdministration/data/icons';
import { ReactComponent as IconCloud } from '../../../../../../assets/svg/lessons-upload.svg';
import DefaultFile from '../../../../../../assets/webp/situation-cover.webp';
import { GenerateChapterModal } from '../../../../components';
import { Chapters } from '../../../../../TeacherLessons/data/constants';
import cl from './GenerateSituationModal.module.css';

const DefaultModality = ['Friendly'];

const GenerateSituationModal = ({ visible, onGenerate, setVisible }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const { situationCategoryOptions } = exercisesStore;

    const [addFile, setAddFile] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [level, setLevel] = useState('');
    const [maleModalities, setMaleModalities] = useState(DefaultModality);
    const [femaleModalities, setFemaleModalities] = useState(DefaultModality);
    const [requestText, setRequestText] = useState('');
    const [file, setFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const isSendDisabled = () => {
        if (![...maleModalities, ...femaleModalities].length) return true;
        return false;
    };

    const formatSituationsByModalities = () => {
        const userId = authStore.user?.id;
        return [
            ...maleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: language.toUpperCase(),
                gender: GENDERS[0],
                modality,
                level,
                userId,
            })),
            ...femaleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: language.toUpperCase(),
                gender: GENDERS[1],
                modality,
                level,
                userId,
            })),
        ];
    };

    const [addSituation, isSituationLoading, error, resetError] = useFetching(
        async () => {
            resetError();
            const situations = formatSituationsByModalities();

            const { data } = await SituationsService.addSituationsBatch({
                situations,
                file,
            });
            /* 

    {
        "id": "3d396b73-f3a8-4324-9ec8-b4c3421c8eb7",
        "title": "Sanctuary and Sirens",
        "language": "EN",
        "gender": "Male",
        "modality": "Friendly",
        "situationId": "f216afe1-994a-4c1d-ab4c-9282205fb9cc",
        "available": false,
        "processed": false
    },
*/
            if (data && data.length) {
                const info = data[0];
                onGenerate({ id: info.situationId, title: info.title });
                setVisible(false);
            }
        }
    );

    const handleAddImage = () => {
        setAddFile(true);
    };

    const renderInfoIcons = () => {
        return [
            ...maleModalities.map((m, i) => {
                const modality = m.toLowerCase();
                const key = `male_icon_${i}`;
                return (
                    <div className={cl.infoObj} key={key}>
                        {'M'}
                        <div className={`${cl.icon} ${cl[modality]}`}>
                            {ICONS[modality]}
                        </div>
                        {level}
                    </div>
                );
            }),
            ...femaleModalities.map((m, i) => {
                const modality = m.toLowerCase();
                const key = `female_icon_${i}`;
                return (
                    <div className={cl.infoObj} key={key}>
                        {'F'}
                        <div className={`${cl.icon} ${cl[modality]}`}>
                            {ICONS[modality]}
                        </div>
                        {level}
                    </div>
                );
            }),
        ];
    };

    const getModality = (modality, gender) => {
        const modalities =
            gender === GENDERS[0] ? maleModalities : femaleModalities;
        const flag = modalities.includes(modality);
        return flag;
    };

    const setModality = (modality, gender) => {
        const setModalities =
            gender === GENDERS[0] ? setMaleModalities : setFemaleModalities;

        setModalities([modality]);
    };

    const setDefaultFile = async () => {
        const fileResponse = await fetch(DefaultFile);
        const blob = await fileResponse.blob();
        setFile(blob);
    };

    const handleSituationGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                level: !level,
                requestText: !requestText,
                categoryId: !categoryId,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addSituation();
        });
    };

    useEffect(() => {
        setLevel('');
        setMaleModalities(DefaultModality);
        setFemaleModalities(DefaultModality);
        setRequestText('');
        setDefaultFile();
    }, [visible]);

    useEffect(() => {
        resetError();
    }, [level, requestText]);

    useEffect(() => {
        setCategoryOptions(situationCategoryOptions);
        setCategoryId(situationCategoryOptions[0]?.value);
    }, [situationCategoryOptions]);

    useEffect(() => {
        setFormErrors({});
    }, [categoryId, level, requestText, visible]);

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('situations.situation_add_title')}
            subtitle={t('situations.situation_add_desc')}
        >
            <div>
                <Label
                    text={t('situations.request')}
                    isError={formErrors['requestText']}
                />
                <Input
                    value={requestText}
                    variant={
                        formErrors['requestText']
                            ? 'erroredSmall'
                            : 'outlinedSmall'
                    }
                    onChange={setRequestText}
                    placeholder={t('situations.request_example')}
                />
            </div>

            <div className={cl.horizontalContainer} style={{ gap: 25 }}>
                <div style={{ flexGrow: 1 }}>
                    <Label
                        text={t('exercises.category')}
                        isError={formErrors['categoryId']}
                    />
                    <Select
                        variant={'grey'}
                        label={t('exercises.category')}
                        value={categoryId}
                        options={categoryOptions}
                        onChange={setCategoryId}
                        style={{ width: '100%' }}
                        height={300}
                    />
                </div>
                <div>
                    <Label
                        text={t('situations.level')}
                        isError={formErrors['level']}
                    />
                    <ToggleButton
                        variant={formErrors['level'] ? 'error' : 'outlined'}
                        isGrid
                        options={LEVELS}
                        value={level}
                        onChange={setLevel}
                    />
                </div>
            </div>

            <div className={cl.horizontalContainer}>
                <p className={cl.genderLabel}> {t('situations.male')}</p>
                {MODALITIES.map((m, i) => (
                    <Checkbox
                        variant={'checkmark'}
                        color={Chapters.Situation}
                        key={`male_${m}`}
                        style={{ width: 100 }}
                        label={t(`situations.${m.toLowerCase()}`)}
                        value={getModality(m, GENDERS[0])}
                        onChange={() => setModality(m, GENDERS[0])}
                    />
                ))}
            </div>

            <div className={cl.horizontalContainer}>
                <p className={cl.genderLabel}> {t('situations.female')}</p>
                {MODALITIES.map((m) => (
                    <Checkbox
                        variant={'checkmark'}
                        color={Chapters.Situation}
                        key={`female_${m}}`}
                        style={{ width: 100 }}
                        label={t(`situations.${m.toLowerCase()}`)}
                        value={getModality(m, GENDERS[1])}
                        onChange={() => setModality(m, GENDERS[1])}
                    />
                ))}
            </div>
            <div
                className={`${cl.imageCont} ${file ? '' : cl.placeholder}`}
                onClick={handleAddImage}
            >
                {error && (
                    <p className={cl.error}>{t('situations.conflict_error')}</p>
                )}
                {file ? (
                    <>
                        {requestText && (
                            <p className={cl.entityTitle}>{requestText}</p>
                        )}
                        <img
                            src={URL.createObjectURL(file)}
                            alt={'situation preview'}
                        />
                        <div className={cl.infoCont}>{renderInfoIcons()}</div>
                        <div className={cl.changeCoverButton}>
                            <IconCloud style={{ minWidth: 16 }} />
                            {t('courses.change_cover')}
                        </div>
                    </>
                ) : (
                    <Button
                        style={{ gap: 12 }}
                        icon={<IconCloud style={{ minWidth: 16 }} />}
                        text={t('courses.add_cover')}
                        variant="grey"
                        onClick={() => {}}
                    />
                )}
            </div>
            <Button
                variant={Chapters.Situation}
                text={t('exercises.add_new_words')}
                onClick={handleSituationGenerate}
                isLoading={isSituationLoading}
                isDisabled={isSendDisabled()}
            />
            <FileUploader
                type={FileTypes.Image}
                visible={addFile}
                setVisible={setAddFile}
                key="categoryImagePicker"
                onSave={setFile}
                isLessons
            />
        </GenerateChapterModal>
    );
};

export default observer(GenerateSituationModal);
