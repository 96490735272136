import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Input,
    Label,
    Range,
    Select,
    ToggleButton,
} from '../../../../../../teacherComponents';
import { AdjustableTextarea } from '../../../../../../components';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import exercisesStore from '../../../../store/exercisesStore';
import {
    MediaTypes,
    TextRange,
    TextGenres,
    TextModes,
} from '../../../../data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import SelectTargetVocabulary from '../SelectTargetVocabulary/SelectTargetVocabulary';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import { ReactComponent as IconText } from '../../../../../../assets/svg/lessons-text_alt.svg';
import { Chapters } from '../../../../../TeacherLessons/data/constants';
import cl from './GenerateText.module.css';

const DefaultTextParams = {
    level: '',
    vocabulary: '',
    genre: TextGenres.Text,
    grammarTopic: '',
    textTopic: '',
    sentencesAmount: 10,
};

const GenresByLevel = {
    [LanguageLevels[0]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
    ],
    [LanguageLevels[1]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
    ],
    [LanguageLevels[2]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
        TextGenres.Blog,
    ],
    [LanguageLevels[3]]: Object.values(TextGenres),
    [LanguageLevels[4]]: Object.values(TextGenres),
    [LanguageLevels[5]]: Object.values(TextGenres),
};

const GenerateText = ({ mediaType, allowEmptyGrammar }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const [modeSelect, setModeSelect] = useState(true);
    const [textParams, setTextParams] = useState(DefaultTextParams);
    const [formErrors, setFormErrors] = useState({});
    const [vocabularyModal, setVocabularyModal] = useState(false);

    const [generateText, generateLoading] = useFetching(async () => {
        const {
            level,
            grammarTopic,
            textTopic,
            genre,
            vocabulary,
            sentencesAmount,
        } = textParams;

        const { data } = await ExerciseService.generateText({
            lang,
            difficulty: LanguageLevels.indexOf(level),
            grammarTopic,
            textTopic,
            genre,
            vocabulary: vocabulary.split(/ .,!?/),
            sentencesAmount,
        });

        exercisesStore.setGeneratedText(data.text);
    });

    const setSentencesAmount = (e) => {
        setTextParams({ ...textParams, sentencesAmount: e.target.value });
    };

    const handleTextGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                level: !textParams.level,
                textTopic: !textParams.textTopic,
                grammarTopic: !allowEmptyGrammar && !textParams.grammarTopic,
                sentencesAmount: !textParams.sentencesAmount,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            generateText();
        });
    };

    const getGenreOptions = () => {
        const availableGenres = textParams.level
            ? GenresByLevel[textParams.level]
            : GenresByLevel[LanguageLevels[0]];

        return availableGenres.map((g) => ({
            value: g,
            name: t(`exercises.${g}`),
        }));
    };

    useEffect(() => {
        if (exercisesStore.textMode === TextModes.Generated) return;
        if (exercisesStore.generatedText === null) {
            setTextParams(DefaultTextParams);
        }
    }, [exercisesStore.generatedText]);

    useEffect(() => {
        setFormErrors({});
    }, [textParams]);

    if (mediaType !== MediaTypes.GeneratedText) return null;

    if (modeSelect)
        return (
            <>
                <p className={cl.subtitle}>
                    {t('exercises.generating_text_info')}
                </p>
                <div className={cl.modeButtonsContainer}>
                    <div className={cl.buttonContainer}>
                        <Button
                            style={{ gap: 12 }}
                            icon={<IconStars className={cl.icon} />}
                            variant={Chapters.Exercise}
                            text={t('exercises.generate_text')}
                            onClick={() => {
                                exercisesStore.setTextMode('generated');
                                setModeSelect(false);
                            }}
                        />
                    </div>
                    <div className={cl.buttonContainer}>
                        <Button
                            style={{ gap: 12 }}
                            icon={<IconText className={cl.icon} />}
                            variant={Chapters.Exercise}
                            text={t('exercises.use_own')}
                            onClick={() => {
                                exercisesStore.setTextMode('custom');
                                exercisesStore.setGeneratedText('');
                                setModeSelect(false);
                            }}
                        />
                    </div>
                </div>
            </>
        );

    return exercisesStore.generatedText !== null ? (
        <>
            <AdjustableTextarea
                placeholder={t('exercises.media_placeholder')}
                value={exercisesStore.generatedText ?? ''}
                onChange={exercisesStore.setGeneratedText}
                className={cl.text}
            />
        </>
    ) : (
        <>
            <p className={cl.subtitle}>{t('exercises.generating_text_info')}</p>
            <div className={cl.generateText}>
                <div className={cl.horizontalContainer}>
                    <div style={{ flex: 1 }}>
                        <Label text={t('exercises.genre')} />
                        <Select
                            style={{ width: '100%' }}
                            label={t('exercises.genre')}
                            variant={'grey'}
                            value={textParams.genre}
                            options={getGenreOptions()}
                            onChange={(genre) =>
                                setTextParams({ ...textParams, genre })
                            }
                            ignoreOverflow
                        />
                    </div>
                    <div>
                        <Label
                            text={t('exercises.exercise_level')}
                            isError={formErrors['level']}
                        />
                        <ToggleButton
                            style={{ width: 212 }}
                            label={t('users.level')}
                            options={LanguageLevels}
                            value={textParams.level}
                            variant={formErrors['level'] ? 'error' : 'outlined'}
                            onChange={(level) =>
                                setTextParams({ ...textParams, level })
                            }
                        />
                    </div>
                </div>
                <div>
                    <Label
                        text={t('exercises.topic')}
                        isError={formErrors['textTopic']}
                    />
                    <Input
                        variant={
                            formErrors['textTopic']
                                ? 'erroredSmall'
                                : 'outlinedSmall'
                        }
                        placeholder={t('exercises.topic_example')}
                        value={textParams.textTopic}
                        onChange={(textTopic) =>
                            setTextParams({ ...textParams, textTopic })
                        }
                    />
                </div>
                <div>
                    <Label
                        text={t('exercises.text_theme')}
                        isError={formErrors['grammarTopic']}
                    />
                    <Input
                        variant={
                            formErrors['grammarTopic']
                                ? 'erroredSmall'
                                : 'outlinedSmall'
                        }
                        placeholder={t('exercises.text_theme_example')}
                        value={textParams.grammarTopic}
                        onChange={(grammarTopic) =>
                            setTextParams({ ...textParams, grammarTopic })
                        }
                    />
                </div>
                <div>
                    <Label text={t('exercises.vocabulary')} />
                    <div className={cl.inputContainer}>
                        <Input
                            variant={'outlinedSmall'}
                            label={t('exercises.vocabulary')}
                            placeholder={
                                'social media, communication, information...'
                            }
                            value={textParams.vocabulary}
                            onChange={(vocabulary) =>
                                setTextParams({ ...textParams, vocabulary })
                            }
                        />
                        <Button
                            variant={Chapters.Exercise}
                            text={t('exercises.use_word_pack')}
                            onClick={() => setVocabularyModal(true)}
                        />
                    </div>
                </div>
                <div>
                    <Label text={t('exercises.text_sentence_count')} />
                    <Range
                        color={'var(--blue)'}
                        style={{ marginTop: 15 }}
                        value={textParams.sentencesAmount}
                        min={TextRange.Min}
                        step={TextRange.Step}
                        max={TextRange.Max}
                        onChange={setSentencesAmount}
                    />
                </div>
                <div className={cl.buttonsContainer}>
                    <Button
                        style={{ width: '100%' }}
                        text={t('exercises.generate_text')}
                        variant={Chapters.Exercise}
                        onClick={handleTextGenerate}
                        isLoading={generateLoading}
                    />
                </div>
                <SelectTargetVocabulary
                    visible={vocabularyModal}
                    setVisible={setVocabularyModal}
                    onSelect={(vocabulary) =>
                        setTextParams({ ...textParams, vocabulary })
                    }
                />
            </div>
        </>
    );
};

export default observer(GenerateText);
