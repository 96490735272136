import axios from 'axios';
import { DEVICE_ID } from '../utils/getDeviceId';

const URL = 'v1/translation';

const headers = {
    'x-actor-device': DEVICE_ID,
};

export default class TranslationService {
    static async translateSentence({ text, sourceLang, targetLang }) {
        return axios.post(
            `${URL}/translate-sentence`,
            { text, sourceLang, targetLang },
            {}
        );
    }

    static async translateTextArray({ sourceArr, targetLang, sourceLang }) {
        return axios.post(
            `${URL}/translate-text-array`,
            { sourceArr, sourceLang, targetLang },
            {}
        );
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/healthcheck`, { headers });

            return response;
        } catch (e) {
            return null;
        }
    }
}
