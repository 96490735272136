import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import adminExercisesStore from '../../store/adminExercisesStore';
import { ExerciseCard } from '..';
import List from '../../../../components/UI/List/List';
import cl from './ExercisesList.module.css';

const ExercisesList = ({ isLoading }) => {
    const { t } = useTranslation();
    const scrollRef = useRef(0);
    const { exercises, paginationEnd } = adminExercisesStore;

    const handleTrackListScroll = (e) => {
        if (paginationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > scrollRef.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                adminExercisesStore.setNextPage();
            }
        }
        scrollRef.current = list.scrollTop;
    };

    if (!exercises.length && !isLoading) {
        return <p className={cl.emptyAlert}>{t('errors.tracks_filter')}</p>;
    }

    return (
        <List onScroll={handleTrackListScroll} isLoading={isLoading}>
            {exercises.map((e) => (
                <ExerciseCard key={e.id} exercise={e} />
            ))}
        </List>
    );
};

export default observer(ExercisesList);
