import React from 'react';
import languagesStore from '../../../../store/interface';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import podcastsStore from '../../store/podcastsStore';
import {
    PrimaryButton,
    PrimaryInput,
    LabeledSelect,
    RefreshButton,
} from '../../../../UI';
import { AddPodcast } from '..';
import { DEFAULT_SEARCH_OPTION } from '../../data/constants';
import cl from './SearchBar.module.css';
import { LEVELS } from '../../../SituationsAdministration/data/constants';

const SearchBar = ({ onRefresh }) => {
    const { languages } = languagesStore;
    const { t } = useTranslation();
    const {
        blacklist,
        searchString,
        language,
        level,
        podcastsCount,
        promoted,
        status,
    } = podcastsStore;

    const LANGUAGE_OPTIONS = [
        { value: DEFAULT_SEARCH_OPTION.value, name: t('track_statuses.all') },
        ...languages,
    ];

    const PODCAST_PROCESSED_STATES = [
        { value: 'all', name: t('podcasts.all') },
        { value: 'processed', name: t('podcasts.processed') },
        { value: 'unprocessed', name: t('podcasts.unprocessed') },
        { value: 'news', name: t('podcasts.news') },
    ];

    const LEVEL_OPTIONS = [
        { value: 'all', name: t('podcasts.all') },
        ...LEVELS.map((l, i) => ({ value: i, name: l })),
    ];

    return (
        <>
            <div className={cl.searchBar}>
                <div
                    className={`${cl.regularOptions} ${blacklist ? cl.disabled : ''}`}
                >
                    <PrimaryInput
                        style={{
                            flex: '45%',
                            width: 150,
                        }}
                        onChange={podcastsStore.setSearchString}
                        value={searchString}
                        placeholder={t('library.search')}
                    />
                    <LabeledSelect
                        value={language}
                        onChange={podcastsStore.setLanguage}
                        options={LANGUAGE_OPTIONS}
                        style={{ width: '100%', minWidth: 150 }}
                        label={t('podcasts.language')}
                    />
                    <LabeledSelect
                        value={status}
                        onChange={podcastsStore.setStatus}
                        options={PODCAST_PROCESSED_STATES}
                        style={{ width: 'auto', minWidth: 150 }}
                        label={t('podcasts.status')}
                    />
                    <LabeledSelect
                        value={level}
                        onChange={podcastsStore.setLevel}
                        options={LEVEL_OPTIONS}
                        style={{ width: 'auto', minWidth: 150 }}
                        label={t('situations.level')}
                    />
                    <PrimaryButton
                        text={t('podcasts.high_rating')}
                        variant={promoted ? 'dark' : 'light'}
                        onClick={podcastsStore.togglePromoted}
                    />
                </div>
                <PrimaryButton
                    text={t(
                        blacklist
                            ? 'podcasts.all_podcasts'
                            : 'podcasts.blacklist'
                    )}
                    variant={blacklist ? 'dark' : 'light'}
                    onClick={podcastsStore.toggleBlacklist}
                />
                <AddPodcast />
                <RefreshButton onClick={onRefresh} />
            </div>
            {podcastsCount > 0 && (
                <p className={cl.amount}>{`${t(
                    'podcasts.amount_by_filter'
                )} ${podcastsCount}`}</p>
            )}
        </>
    );
};

export default observer(SearchBar);
