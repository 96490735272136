import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ExerciseService from '../../../../../../api/ExerciseService';
import { useFetching } from '../../../../../../hooks/useFetching';
import exercisesStore from '../../../../store/exercisesStore';
import onboardingStore from '../../../../../../store/onboarding';
import { Preview } from '..';
import {
    ExercisesPaginationCount,
    FilterModes,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '../../../../components';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
} from '../../../../../../teacherComponents';
import { OnboardingKeys } from '../../../../../../data/onboardingKeys';
import { Chapters, Views } from '../../../../../TeacherLessons/data/constants';
import cl from './SelectExerciseModal.module.css';

const SelectExerciseModal = ({
    visible,
    setVisible,
    onClick,
    withCategorySelector,
    alreadyAdded = [],
    addLoading,
    isOwn,
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.ExercisesAwaitSelect
    );

    // const isLesson = exercisesStore.view === Views.Lessons;
    const isLesson = false;

    const lastScroll = useRef(0);

    const typeOptions = [
        ...Object.values(GrammarExerciseTypes),
        ...Object.values(MediaExerciseTypes),
    ].map((type) => ({ value: type, name: t(`exercises.${type}`) }));

    const [exercises, setExercises] = useState([]);
    const [page, setPage] = useState(0);
    const [currentExercise, setCurrentExercise] = useState({});
    const [difficulty, setDifficulty] = useState('');
    const [searchString, setSearchString] = useState('');
    const [paginationEnd, setPaginationEnd] = useState(false);
    const [type, setType] = useState('');
    const [mode, setMode] = useState(
        isLesson ? FilterModes.Teacher : FilterModes.All
    );

    const [getExercises, isLoading] = useFetching(async () => {
        if (!visible) return;
        const { data } = await ExerciseService.getExercises({
            offset: 0,
            limit: 500,
            searchString,
            lang,
            type,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
        });
        const { items } = data;
        if (!items.length || items.length < ExercisesPaginationCount) {
            setPaginationEnd(true);
        }
        const newExercises = (
            page > 0 ? [...exercises, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        const withoutExisting = newExercises.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );
        setExercises(withoutExisting);
        if (!currentExercise.id) setCurrentExercise(withoutExisting[0]);
    });

    const [getOwnExercises, isOwnLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getSavedExercises({
            offset: 0,
            limit: 500,
            type,
            difficulty: difficulty && LanguageLevels.indexOf(difficulty),
            searchString,
            lang,
        });

        const items = data?.items ?? [];
        if (!items.length || items.length < ExercisesPaginationCount) {
            setPaginationEnd(true);
        }

        const withoutExisting = items.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );

        setExercises(withoutExisting);
        if (!currentExercise.id) setCurrentExercise(withoutExisting[0]);
    });

    const handleListScroll = (e) => {
        if (paginationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > lastScroll.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                setPage(page + 1);
            }
        }
        lastScroll.current = list.scrollLeft;
    };

    const handleExerciseClick = (exerciseObj, index) => {
        if (currentExercise && currentExercise?.id === exerciseObj.id) return;
        setCurrentExercise({ ...exerciseObj, index });
    };

    const handleExerciseSelect = () => {
        if (!currentExercise) return;
        const { id, title } = currentExercise;
        onClick({ id, title });
        setVisible(false);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    };

    const resetSearch = () => {
        setPage(0);
        setPaginationEnd(false);
        setExercises([]);
        setCurrentExercise({});
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    useEffect(() => {
        resetSearch();
        if (!visible) return;
        mode === FilterModes.All ? getExercises() : getOwnExercises();
    }, [searchString, lang, type, difficulty, visible, mode]);

    return (
        <AddChapterModal
            visible={visible}
            isOwn={isOwn}
            setVisible={handleClose}
            type={Chapters.Exercise}
        >
            <Toolbar>
                <ToggleButton
                    nullable
                    label={t('users.level')}
                    options={LanguageLevels}
                    value={difficulty}
                    onChange={setDifficulty}
                />
                {withCategorySelector && (
                    <ToggleButton
                        value={mode}
                        onChange={setMode}
                        options={Object.values(FilterModes)}
                        disabled
                        withTranslations={'exercises'}
                    />
                )}
                <Select
                    style={{ minWidth: 212 }}
                    label={t('exercises.type')}
                    value={type}
                    options={typeOptions}
                    variant={'white'}
                    onChange={setType}
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                />
            </Toolbar>
            <div className={cl.exercisesContainer}>
                <ChaptersScrollableList
                    type={Chapters.Exercise}
                    isLoading={isLoading || isOwnLoading}
                    chapters={exercises}
                    current={currentExercise}
                    setCurrent={handleExerciseClick}
                    onScroll={handleListScroll}
                    onSelect={handleExerciseSelect}
                />
                {visible && currentExercise?.id ? (
                    <ChapterPreviewContainer>
                        <Preview
                            isLoading={addLoading}
                            exerciseId={currentExercise?.id}
                        />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default observer(SelectExerciseModal);
