import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import situationsStore from '../../store/situationsStore';
import {
    PrimaryButton,
    PrimaryInput,
    PrimarySelect,
    RefreshButton,
    ToggleButton,
} from '../../../../UI';
import { AddCategory, NavigationWrapper } from '..';
import { LEVELS } from '../../data/constants';

const SearchBar = ({ isLoading, onRefresh }) => {
    const { t } = useTranslation();
    const [addModal, setAddModal] = useState(false);
    const {
        searchString,
        setSearchString,
        languageOptions,
        language,
        setLanguage,
        level,
        setLevel,
        resetSituations,
    } = situationsStore;

    const handleResetButton = () => {
        resetSituations();
    };

    const handleAddButton = () => {
        setAddModal(true);
    };

    const renderResetButton = () => {
        if (searchString || level)
            return (
                <PrimaryButton
                    variant={'dark'}
                    text={t('buttons.reset')}
                    onClick={handleResetButton}
                />
            );
        return <></>;
    };

    const handleQueueButton = () => {
        situationsStore.setIsQueue(true);
    };

    const handleNewCategory = () => {
        situationsStore.setWasChanged(true);
    };

    return (
        <NavigationWrapper>
            <PrimaryInput
                value={searchString}
                onChange={setSearchString}
                placeholder={t('situations.search')}
            />
            {renderResetButton()}
            <ToggleButton
                variant={'round'}
                options={LEVELS}
                nullable
                value={level}
                onChange={setLevel}
            />
            <PrimarySelect
                value={language}
                options={languageOptions}
                onChange={setLanguage}
                style={{ width: 140, minWidth: 140 }}
            />
            <PrimaryButton
                text={t('situations.queue')}
                onClick={handleQueueButton}
            />
            <PrimaryButton
                variant={'dark'}
                text={t('situations.category_add_btn')}
                onClick={handleAddButton}
            />

            <RefreshButton onClick={onRefresh} isLoading={isLoading} />
            <AddCategory
                visible={addModal}
                setVisible={setAddModal}
                onAdd={handleNewCategory}
            />
        </NavigationWrapper>
    );
};

export default observer(SearchBar);
