import React from 'react';
import { useTranslation } from 'react-i18next';
import languagesStore from '../../../../store/interface';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import libraryStore from '../../store/libraryStore';
import {
    LabeledSelect,
    PrimaryButton,
    PrimaryInput,
    RefreshButton,
} from '../../../../UI';
import { DEFAULT_SEARCH_OPTION, DIFF_OPTIONS } from '../../data/constants';
import { ROUTES } from '../../../../router';

import cl from './SearchBar.module.css';
import PriorityTracks from '../PriorityTracks/PriorityTracks';

const SearchBar = ({ onRefresh }) => {
    const { languages } = languagesStore;
    const { t } = useTranslation();
    const {
        blacklist,
        category,
        difficulty,
        language,
        searchString,
        status,
        tracksCount,
    } = libraryStore;

    const translateOptions = (options) =>
        options.map((o) =>
            o.value === DEFAULT_SEARCH_OPTION.value
                ? { ...o, name: t('track_statuses.all') }
                : o
        );

    const STATUS_OPTIONS = [
        { value: 'all', name: t('track_statuses.all') },
        { value: 'recognized', name: t('track_statuses.recognized') },
        { value: 'unrecognized', name: t('track_statuses.unrecognized') },
        { value: 'processed', name: t('podcasts.processed') },
        { value: 'unprocessed', name: t('podcasts.unprocessed') },
    ];

    const LANGUAGE_OPTIONS = [
        { value: DEFAULT_SEARCH_OPTION.value, name: t('track_statuses.all') },
        ...languages,
    ];

    const DIFFICULTY_OPTIONS = translateOptions(DIFF_OPTIONS);
    const CATEGORY_OPTIONS = translateOptions(libraryStore.categoryOptions);

    return (
        <>
            <div className={`${cl.searchBar} ${blacklist ? cl.blacklist : ''}`}>
                <PrimaryInput
                    color={'light'}
                    style={{
                        flex: '55%',
                        width: 150,
                    }}
                    onChange={(val) => {
                        libraryStore.setSearchString(val);
                    }}
                    value={searchString}
                    placeholder={t('library.search')}
                />
                {!blacklist && (
                    <>
                        <LabeledSelect
                            style={{ width: 'auto', minWidth: 150 }}
                            value={language}
                            options={LANGUAGE_OPTIONS}
                            onChange={(selected) => {
                                libraryStore.setLanguage(selected);
                            }}
                            label={t('library.track_language')}
                        />
                        <LabeledSelect
                            style={{ width: 'auto', minWidth: 50 }}
                            value={difficulty}
                            options={DIFFICULTY_OPTIONS}
                            onChange={(selected) => {
                                libraryStore.setDifficulty(selected);
                            }}
                            label={t('library.difficult')}
                        />
                        <LabeledSelect
                            style={{ width: 'auto', minWidth: 150 }}
                            value={status}
                            onChange={(selected) => {
                                libraryStore.setStatus(selected);
                            }}
                            options={STATUS_OPTIONS}
                            label={t('library.status')}
                        />
                        <LabeledSelect
                            style={{
                                width: 'auto',
                                minWidth: 250,
                                maxWidth: 300,
                            }}
                            value={category}
                            onChange={(selected) => {
                                libraryStore.setCategory(selected);
                            }}
                            options={CATEGORY_OPTIONS}
                            label={t('tags_cont.choose_category')}
                        />
                    </>
                )}
                <PriorityTracks />
                <PrimaryButton
                    variant={blacklist ? 'dark' : 'light'}
                    onClick={libraryStore.toggleBlacklist}
                    text={t(
                        blacklist ? 'navbar.all_tracks' : 'podcasts.blacklist'
                    )}
                />
                <NavLink to={ROUTES.add_track}>
                    <PrimaryButton
                        variant={'dark'}
                        text={t('navbar.add_track')}
                    />
                </NavLink>
                <RefreshButton onClick={onRefresh} />
            </div>
            {tracksCount > 0 && (
                <p className={cl.amount}>{`${t(
                    'landing.videos_amount_by_filter'
                )} ${tracksCount}`}</p>
            )}
        </>
    );
};

export default observer(SearchBar);
