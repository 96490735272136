import { makeAutoObservable } from 'mobx';
import {
    DictionaryViews,
    SortTypes,
    WordStatuses,
    WordsPaginationCount,
} from '../data/constants';

class DictionaryStore {
    view = DictionaryViews.words;

    page = 0;
    words = [];
    active = {};
    wordsCount = null;
    markedIds = [];
    sort = SortTypes.wordAsc;

    searchString = '';
    partOfSpeech = '';
    language = JSON.parse(localStorage.getItem('dictionaryLanguage')) || 'en';
    status = WordStatuses.ok;
    checked = false;

    paginationEnd = false;
    markOnOpen = JSON.parse(localStorage.getItem('markOnOpen')) || false;
    disableAutoScroll =
        JSON.parse(localStorage.getItem('disableAutoScroll')) || false;
    multiselect = false;

    fileLanguage = 'en';
    processedCount = 0;
    totalCount = 0;
    abortCallback = () => {};
    endOfFile = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setView(view) {
        this.view = view;
    }

    setPage(page) {
        this.page = page;
    }

    setNextPage() {
        this.setPage(this.page + 1);
    }

    setWords(words) {
        this.words = words;
    }

    setSort(sort) {
        this.sort = sort;
    }

    setMultiselect(multiselect) {
        this.multiselect = multiselect;
    }

    processSelectedWord(word) {
        const newWord = this.words.find((w) => w.id === word.id);
        newWord.selected = !newWord.selected;
        this.setWords(this.words.map((w) => (w.id === word.id ? newWord : w)));
    }

    setWordsData(data) {
        const { items, total } = data;
        if (!items.length || items.length < WordsPaginationCount) {
            this.setPaginationEnd(true);
        }
        this.setWordsCount(total);
        const words = (
            this.page > 0 ? [...this.words, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        this.setWords(words);
    }

    setActive(active) {
        this.active = active;
    }

    setWordsCount(wordsCount) {
        this.wordsCount = wordsCount;
    }

    setMarkedIds(markedIds) {
        this.markedIds = markedIds;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setPartOfSpeech(partOfSpeech) {
        this.partOfSpeech = partOfSpeech;
    }

    setLanguage(language) {
        this.language = language;
        localStorage.setItem('dictionaryLanguage', JSON.stringify(language));
    }

    setStatus(status) {
        this.status = status;
    }

    setChecked(checked) {
        this.checked = checked;
    }

    setPaginationEnd(paginationEnd) {
        this.paginationEnd = paginationEnd;
    }

    setMarkOnOpen(markOnOpen) {
        this.markOnOpen = markOnOpen;
        localStorage.setItem('markOnOpen', JSON.stringify(markOnOpen));
    }

    setDisableAutoScroll(disableAutoScroll) {
        this.disableAutoScroll = disableAutoScroll;
        localStorage.setItem(
            'disableAutoScroll',
            JSON.stringify(disableAutoScroll)
        );
    }

    addMarkedId(id) {
        this.setMarkedIds([...this.markedIds, id]);
    }

    markWord(id) {
        this.setWords(
            this.words.map((w) => (w.id === id ? { ...w, checked: true } : w))
        );
    }

    changeWord(wordObj) {
        const { id } = wordObj;
        this.setWords(this.words.map((w) => (w.id === id ? wordObj : w)));
        if (this.active && this.active.id === id) {
            this.setActive(wordObj);
        }
    }

    deleteWord(id) {
        this.setWords(this.words.filter((w) => w.id !== id));
        this.setActive({});
    }

    resetSearch() {
        this.setPage(0);
        this.setPaginationEnd(false);
        this.setWords([]);
        this.setActive({});
    }

    setFileLanguage(fileLanguage) {
        this.fileLanguage = fileLanguage;
    }

    setProcessedCount(processedCount) {
        this.processedCount = processedCount;
    }

    setTotalCount(totalCount) {
        this.totalCount = totalCount;
    }

    setAbortCallback(abortCallback) {
        this.abortCallback = abortCallback;
    }

    setEndOfFile(flag) {
        this.endOfFile = flag;
    }

    incrementProcessedCount(count) {
        this.setProcessedCount(this.processedCount + count);
    }

    abortFileLoading() {
        this.setProcessedCount(0);
        this.setTotalCount(0);
        this.abortCallback();
    }
}

const dictionaryStore = new DictionaryStore();
export default dictionaryStore;
