import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import userDialoguesStore from '../../../../pages/UserDialogues/store/userDialogues';
import { Button } from '../../../../../../teacherComponents';
import { URL } from '../../../../../../api/SituationsService';
import { ReactComponent as IconHide } from '../../../../../../assets/svg/hide.svg';
import { ReactComponent as IconSound } from '../../../../../../assets/svg/icon-sound.svg';
import cl from './ListenMode.module.css';

const ListenMode = ({ statement }) => {
    const { playbackSpeed } = userDialoguesStore;
    const { phrase, phraseVoice, translate } = statement;
    const words = phrase.split(' ');

    const [audio] = useState(new Audio());
    const [blurText, setBlurText] = useState(false);
    const [blurTranslation, setBlurTranslation] = useState(true);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    const handleAudioClick = () => {
        audio.pause();
        audio.src = `${URL}${phraseVoice}`;
        audio.playbackRate = playbackSpeed;
        setTimeout(() => {
            audio.play();
        });
    };

    useEffect(() => {
        audio.pause();
        if (phraseVoice) {
            handleAudioClick();
        } else {
            audio.src = '';
        }
    }, [phraseVoice]);

    useEffect(() => {
        const updateWordHighlight = () => {
            const { currentTime, duration } = audio;
            setCurrentWordIndex(
                Math.ceil((words.length * currentTime) / duration)
            );
        };

        audio.addEventListener('timeupdate', updateWordHighlight);

        return () => {
            audio.removeEventListener('timeupdate', updateWordHighlight);
        };
    }, [audio, words]);

    useEffect(() => {
        return () => audio.pause();
    }, []);

    return (
        <motion.div
            className={cl.card}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div className={`${cl.part} ${blurText ? cl.blur : ''}`}>
                <p onClick={() => setBlurText(false)}>
                    {words.map((word, index) => (
                        <span
                            key={index}
                            className={
                                index <= currentWordIndex ? cl.active : cl.word
                            }
                        >
                            {word}&nbsp;
                        </span>
                    ))}
                </p>
            </div>
            <Button
                variant={'grey'}
                className={`${cl.eyeButton} ${blurText ? '' : cl.visible}`}
                onClick={() => setBlurText(true)}
                icon={<IconHide />}
            />
            <Button
                variant={'grey'}
                className={`${cl.eyeButton} ${blurTranslation ? '' : cl.visible}`}
                onClick={() => setBlurTranslation(true)}
                icon={<IconHide />}
            />
            <Button
                className={cl.playButton}
                icon={<IconSound />}
                variant={'grey'}
                onClick={handleAudioClick}
            />
            <div className={`${cl.part} ${blurTranslation ? cl.blur : ''}`}>
                <p onClick={() => setBlurTranslation(false)}>{translate}</p>{' '}
            </div>
        </motion.div>
    );
};

export default observer(ListenMode);
