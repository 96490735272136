export const googleLngList = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    ru: 'Русский',
    uk: 'Украї́нська',
    tr: 'Türkçe',
    ar: 'العربية',
    ja: '日本語',
    zh: '中文',
    nl: 'Nederlands',
    fi: 'Suomi',
    pt: 'Português',
    pl: 'Polski',
    hu: 'Magyar',
    it: 'Italiano',
    el: 'Ελληνικά',
};
