import React from 'react';
import { observer } from 'mobx-react-lite';
import { FirstSection, Footer, Section } from './components';
import { authStore } from '../../App';

const TeachersTutorial = () => {
    const { isAuth } = authStore;

    return (
        <div style={{ width: '100%', overflow: isAuth ? 'hidden' : '' }}>
            <FirstSection />
            <Section index={1} />
            <Section index={2} />
            <Section index={3} />
            <Section index={4} />
            <Section index={5} />
            <Footer />
        </div>
    );
};

export default observer(TeachersTutorial);
