import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import exercisesStore from '../../store/exercisesStore';
import onboardingStore from '../../../../store/onboarding';
import Player from '../../../Player/Player';
import { Chapters } from '../../../TeacherLessons/data/constants';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '..';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
} from '../../../../teacherComponents';
import { Checkbox } from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import { FilterModes, TrackPaginationLimit } from '../../data/constants';
import { OnboardingKeys } from '../../../../data/onboardingKeys';
import cl from './SelectVideoModal.module.css';

const SelectVideoModal = ({
    visible,
    setVisible,
    onClick,
    isOwn,
    withCategorySelector,
    alreadyAdded = [],
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.VideosAwaitSelect
    );

    const isLesson = false;

    const { difficulty: globalDifficulty, categoryOptions } = exercisesStore;

    const scrollRef = useRef(0);

    const [currentTrack, setCurrentTrack] = useState();
    const [mode, setMode] = useState(
        isLesson ? FilterModes.Teacher : FilterModes.All
    );
    const [page, setPage] = useState(0);
    const [tracks, setTracks] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [difficulty, setDifficulty] = useState(globalDifficulty);
    const [paginationEnd, setPaginationEnd] = useState(false);
    const [processed, setProcessed] = useState(true);
    const [category, setCategory] = useState();

    const [getTracks, isLoading] = useFetching(async () => {
        if (!visible) return;
        const params = {
            offset: page * TrackPaginationLimit,
            limit: TrackPaginationLimit,
            lang,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
            processed: processed ? processed : null,
            searchString,
            recognized: true,
            categories: category ? [category] : null,
        };
        const { data } =
            mode === FilterModes.All
                ? await TrackService.getTracks(params)
                : await TrackService.getFavorites(params);
        handleTracksResponse(data);
    });

    const handleTracksResponse = (data) => {
        const { items } = data;
        if (!items.length) setPaginationEnd(true);
        const newTracks = (page > 0 ? [...tracks, ...items] : items).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item?.id === t?.id)
        );

        const withoutExisting = newTracks.filter(
            (e) => !alreadyAdded.some((a) => a?.id === e?.id)
        );
        setTracks(withoutExisting);
        setCurrentTrack(withoutExisting[0]);
    };

    const handleTrackListScroll = (e) => {
        const list = e.target;
        if (paginationEnd || !list) return;

        if (!isLoading && list.scrollTop > scrollRef.current) {
            const halfwayPoint = list.scrollHeight / 2;

            if (list.scrollTop + list.clientHeight >= halfwayPoint) {
                setPage(page + 1);
            }
        }

        scrollRef.current = list.scrollTop;
    };

    const handleTrackSelect = () => {
        if (!currentTrack) return;
        const { id, title } = currentTrack;
        onClick({ id, title });
        setVisible(false);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    useEffect(() => {
        getTracks();
    }, [page, visible]);

    useEffect(() => {
        setPage(0);
        setPaginationEnd(false);
        getTracks();
    }, [difficulty, lang, searchString, processed, mode, category, visible]);

    return (
        <AddChapterModal
            visible={visible}
            isOwn={isOwn}
            setVisible={handleClose}
            type={Chapters.Track}
        >
            <Toolbar>
                <ToggleButton
                    value={difficulty}
                    onChange={setDifficulty}
                    options={LanguageLevels}
                    nullable
                />
                {withCategorySelector && (
                    <ToggleButton
                        value={mode}
                        onChange={setMode}
                        options={Object.values(FilterModes)}
                        disabled
                        withTranslations={'exercises'}
                    />
                )}
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={category}
                    onChange={setCategory}
                    options={categoryOptions}
                />
                <Checkbox
                    value={processed}
                    onChange={setProcessed}
                    label={t('exercises.processed_tracks')}
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                />
            </Toolbar>
            <div className={cl.tracksContainer}>
                <ChaptersScrollableList
                    type={Chapters.Track}
                    isLoading={isLoading}
                    chapters={tracks}
                    current={currentTrack}
                    setCurrent={setCurrentTrack}
                    onScroll={handleTrackListScroll}
                    onSelect={handleTrackSelect}
                />
                {visible && currentTrack ? (
                    <ChapterPreviewContainer>
                        <Player track={currentTrack} />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default SelectVideoModal;
