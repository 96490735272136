import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../hooks/useFetching';
import DictionaryService from '../../api/DictionaryService';
import UserService from '../../api/UserService';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import SituationsService from '../../api/SituationsService';
import TrackService from '../../api/TrackService';
import TranslationService from '../../api/TranslationService';
import { ServiceState } from './components';
import { PrimaryButton } from '../../UI';
import { DefaultServicesState, GrafanaUrl } from './data/constants';
import cl from './HealthCheck.module.css';

const HealthCheck = () => {
    const { t } = useTranslation();

    const [healthArr, setHealthArr] = useState(DefaultServicesState);

    const updateService = (service) => {
        if (!service) return;
        setHealthArr((prev) =>
            prev.map((s) =>
                s.serviceName === service.serviceName ? service : s
            )
        );
    };

    const handleRefresh = () => {
        setHealthArr(DefaultServicesState);
        getHealthCheck();
    };

    const isLoading = () => healthArr.some((s) => !s.status);

    const [getHealthCheck] = useFetching(async () => {
        DictionaryService.getHealthCheck().then((res) => {
            updateService(res?.data);
        });
        TrackService.getHealthCheck().then((res) => {
            updateService(res?.data);
        });
        UserService.getHealthCheck().then((res) => {
            updateService(res?.data);
        });
        TranslationService.getHealthCheck().then((res) => {
            updateService(res?.data);
        });
        SituationsService.getHealthCheck().then((res) => {
            updateService(res?.data);
        });
    });

    useEffect(() => {
        getHealthCheck();
    }, []);

    const openGrafana = () => {
        window.open(GrafanaUrl, '_blank');
    };

    return (
        <PageWrapper style={{ overflow: 'hidden scroll' }}>
            <div className={cl.serviceList}>
                {healthArr.map((service) => (
                    <ServiceState service={service} key={service.serviceName} />
                ))}
            </div>
            <div className={cl.buttonsContainer}>
                <PrimaryButton
                    variant={'light'}
                    text={'Grafana'}
                    onClick={openGrafana}
                />
                <PrimaryButton
                    variant={'dark'}
                    text={t('buttons.refresh')}
                    onClick={handleRefresh}
                    isLoading={isLoading()}
                />
            </div>
        </PageWrapper>
    );
};

export default HealthCheck;
