import React from 'react';
import cl from './Switch.module.css';

const Switch = ({ isOn, handleToggle, color, id }) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={cl.switch}
                id={id}
                type="checkbox"
            />
            <label
                className={`${cl.label} ${isOn ? cl.active : ''} ${color ? cl[color] : ''}`}
                htmlFor={id}
            >
                <span className={cl.btn} />
            </label>
        </>
    );
};

export default Switch;
