export const Faces = [
    '😀',
    '😎',
    '😍',
    '🤗',
    '🤔',
    '🤨',
    '🙄',
    '😏',
    '😥',
    '🤐',
    '🥱',
    '😴',
    '😌',
    '😝',
    '🤤',
    '😒',
    '🤑',
    '😲',
    '😤',
    '😨',
    '🤯',
    '😬',
    '🥵',
    '🥶',
    '😳',
    '🤪',
    '😵',
    '😡',
    '😠',
    '🤬',
    '😷',
    '🤒',
    '🤕',
];
