import React from 'react';

import { ReactComponent as IconCloze } from '../../../../../../assets/svg/exercises-cloze.svg';
import { ReactComponent as IconSubstitution } from '../../../../../../assets/svg/exercises-substitution.svg';
import { ReactComponent as IconMultichoice } from '../../../../../../assets/svg/exercises-multichoice.svg';
import { ReactComponent as IconWordsOrder } from '../../../../../../assets/svg/exercises-words-order.svg';
import { ReactComponent as IconStatementsTransformation } from '../../../../../../assets/svg/exercises-statements-transformation.svg';
import { ReactComponent as IconSentencesOrder } from '../../../../../../assets/svg/exercises-sentences-order.svg';
import { ReactComponent as IconQuiz } from '../../../../../../assets/svg/exercises-quiz.svg';
import { ReactComponent as IconAdvancedQuiz } from '../../../../../../assets/svg/exercises-advanced-quiz.svg';
import { ReactComponent as IconSummary } from '../../../../../../assets/svg/exercises-summary.svg';
import { ReactComponent as IconPresentation } from '../../../../../../assets/svg/exercises-presentation.svg';
import { ReactComponent as IconFreeFormQuestions } from '../../../../../../assets/svg/exercises-free-form-questions.svg';
import { ReactComponent as IconAbc } from '../../../../../../assets/svg/exercises-abc.svg';

import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import cl from './ExerciseIcon.module.css';

const ExerciseIcon = ({ type, additionalClass }) => {
    switch (type) {
        case GrammarExerciseTypes.Cloze:
            return (
                <IconAbc className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case GrammarExerciseTypes.Substitution:
            return (
                <IconAbc className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case GrammarExerciseTypes.Multichoice:
            return (
                <IconAbc className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case GrammarExerciseTypes.WordsOrder:
            return (
                <IconWordsOrder
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case GrammarExerciseTypes.StatementsTransformation:
            return (
                <IconStatementsTransformation
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.SentencesOrder:
            return (
                <IconSentencesOrder
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Quiz:
            return (
                <IconQuiz className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case MediaExerciseTypes.AdvancedQuiz:
            return (
                <IconAdvancedQuiz
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Summary:
            return (
                <IconSummary
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Presentation:
            return (
                <IconPresentation
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.FreeFormQuestions:
            return (
                <IconFreeFormQuestions
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        default:
            return null;
    }
};

export default ExerciseIcon;
