import { useState } from 'react';

export const useFetching = (callback, defaultLoading) => {
    const [isLoading, setIsLoading] = useState(Boolean(defaultLoading));
    const [error, setError] = useState(false);

    const fetching = async (...args) => {
        try {
            setIsLoading(true);
            await callback(...args);
        } catch (e) {
            setError(e?.response?.data ?? {});
        } finally {
            setIsLoading(false);
        }
    };

    function resetError() {
        setError(false);
    }

    return [fetching, isLoading, error, resetError];
};
