import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../../UI';
import { ChapterCard, GenerateChaptersButtons } from '..';
import { ReactComponent as IconEyes } from '../../../../assets/svg/lessons-eyes.svg';
import cl from './ChaptersList.module.css';

const ChaptersList = ({
    isLoading,
    chapters,
    type,
    current,
    setCurrent,
    onDelete,
    onSelect,
    isFilter,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cl.wrapper}>
            <div className={cl.chaptersContainer}>
                {isLoading ? (
                    <div className={cl.emptyAlert}>
                        <Loader style={{ opacity: 0.2 }} />
                    </div>
                ) : chapters.length ? (
                    <>
                        <div className={cl.list}>
                            {chapters.map((chapter) => (
                                <ChapterCard
                                    key={chapter.id}
                                    type={type}
                                    chapter={chapter}
                                    isActive={current?.id === chapter.id}
                                    setActive={setCurrent}
                                    onSelect={onSelect}
                                    onDelete={onDelete}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <div className={cl.emptyAlert}>
                        <IconEyes />
                        <p className={cl.alertTitle}>
                            {t(
                                isFilter
                                    ? `exercises.empty_search_${type}_title`
                                    : `exercises.empty_fav_${type}_title`
                            )}
                        </p>
                        <p className={cl.alertSubtitle}>
                            {t(
                                isFilter
                                    ? `exercises.empty_search_${type}_subtitle`
                                    : `exercises.empty_fav_${type}_subtitle`
                            )}
                        </p>
                    </div>
                )}
            </div>

            <GenerateChaptersButtons variant={type} view={type} />
        </div>
    );
};

export default observer(ChaptersList);
