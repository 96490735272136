import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../store/exercisesStore';
import onboardingStore from '../../../../../../store/onboarding';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import SituationsService from '../../../../../../api/SituationsService';
import {
    Input,
    Select,
    ToggleButton,
    Toolbar,
} from '../../../../../../teacherComponents';
import { LanguageLevels } from '../../../../../../data/common';
import Situation from '../Situation/Situation';
import { FilterModes } from '../../../../data/constants';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '../../../../components';
import { Chapters, Views } from '../../../../../TeacherLessons/data/constants';
import { OnboardingKeys } from '../../../../../../data/onboardingKeys';
import cl from './SelectSituationModal.module.css';

const SelectSituationModal = ({
    visible,
    setVisible,
    onClick,
    withCategorySelector,
    alreadyAdded = [],
    isOwn,
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { situationCategoryOptions } = exercisesStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.SituationsAwaitSelect
    );

    // const isLesson = exercisesStore.view === Views.Lessons;
    const isLesson = false;

    const [mode, setMode] = useState(
        isLesson ? FilterModes.Teacher : FilterModes.All
    );
    const [situations, setSituations] = useState([]);
    const [filteredSituations, setFilteredSituations] = useState([]);
    const [difficulty, setDifficulty] = useState();
    const [searchString, setSearchString] = useState();
    const [categoryId, setCategoryId] = useState();
    const [currentSituation, setCurrentSituation] = useState();
    const [paginationEnd, setPaginationEnd] = useState(false);

    const [getAllSituations, allLoading] = useFetching(async () => {
        if (!visible) return;
        const { data } = await SituationsService.getAvailableCategories({
            language: lang.toUpperCase(),
            level: difficulty,
        });

        const allSituations = data.map((d) => d.situations).flat();
        const withoutExisting = allSituations.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );
        setSituations(withoutExisting);

        if (!currentSituation) setCurrentSituation(withoutExisting[0]);
    });

    const [getFavSituations, favLoading] = useFetching(async () => {
        const { data } = await ProgressService.getSituations({
            lang,
        });
        if (!data.items || !data.items.length) setPaginationEnd(true);
        const ids = data.items.map((s) => s.situationId);

        const { data: categories } =
            await SituationsService.getAvailableCategories({
                language: lang.toUpperCase(),
            });

        const situations = categories
            .map((c) => c.situations)
            .flat()
            .filter((s) => ids.includes(s.id));

        const withoutExisting = situations.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );
        setSituations(withoutExisting);
        if (!currentSituation) setCurrentSituation(withoutExisting[0]);
    });

    const handleSituationSelect = () => {
        if (!currentSituation) return;
        const { id, title } = currentSituation;
        onClick({ id, title });
        setVisible(false);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        setPaginationEnd(false);
        mode === FilterModes.All ? getAllSituations() : getFavSituations();
    }, [mode, visible, lang, difficulty]);

    useEffect(() => {
        setCategoryId();
        setDifficulty();
        setSearchString();
        setCurrentSituation();
        if (!visible) {
            setSituations([]);
        }
    }, [visible]);

    useEffect(() => {
        const filteredSituations = situations.filter((s) => {
            const matchesTitle = searchString
                ? s.title.toLowerCase().includes(searchString.toLowerCase())
                : true;

            const matchesCategory = categoryId
                ? s.categoryId === categoryId
                : true;

            return matchesTitle && matchesCategory;
        });

        setFilteredSituations(filteredSituations);
    }, [searchString, situations, categoryId]);

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    return (
        <AddChapterModal
            visible={visible}
            setVisible={handleClose}
            type={Chapters.Situation}
            isOwn={isOwn}
        >
            <Toolbar>
                <ToggleButton
                    value={difficulty}
                    onChange={setDifficulty}
                    options={LanguageLevels}
                    nullable
                />
                {withCategorySelector && (
                    <ToggleButton
                        value={mode}
                        onChange={setMode}
                        disabled
                        options={Object.values(FilterModes)}
                        withTranslations={'exercises'}
                    />
                )}
                <Select
                    label={t('exercises.category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={categoryId}
                    onChange={setCategoryId}
                    options={
                        categoryId
                            ? [
                                  { value: '', name: t('podcasts.all') },
                                  ...situationCategoryOptions,
                              ]
                            : situationCategoryOptions
                    }
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                />
            </Toolbar>
            <div className={cl.situationsContainer}>
                <ChaptersScrollableList
                    type={Chapters.Situation}
                    isLoading={allLoading || favLoading}
                    chapters={filteredSituations}
                    current={currentSituation}
                    setCurrent={setCurrentSituation}
                    onScroll={() => {}}
                    onSelect={handleSituationSelect}
                />
                {currentSituation ? (
                    <ChapterPreviewContainer>
                        <Situation situation={currentSituation} />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default observer(SelectSituationModal);
