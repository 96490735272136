import { makeAutoObservable } from 'mobx';
class UserExercisesStore {
    exercises = [];
    exercisesCount = null;

    type = '';
    difficulty = null;
    searchString = '';

    currentExercise = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setExercises(exercises) {
        this.exercises = exercises;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setType(type) {
        this.type = type;
    }

    setExercisesCount(exercisesCount) {
        this.exercisesCount = exercisesCount;
    }

    setExercisesData(data) {
        const { items, total } = data;

        this.setExercisesCount(total);
        const exercises = (
            this.page > 0 ? [...this.exercises, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        this.setExercises(exercises);
    }

    setCurrentExercise(currentExercise) {
        this.currentExercise = currentExercise;
    }

    resetSearch() {
        this.setTracks([]);
    }
}
const userExercisesStore = new UserExercisesStore();
export default userExercisesStore;
