import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import { LearnSituation, PonyPlayer } from '..';
import { Preview } from '../../../../pages/TeacherContent/views/ExercisesView/components';
import InfoCard from '../DictionaryCards/InfoCard/InfoCard';
import { ReactComponent as IconDictionary } from '../../../../assets/svg/dictionary.svg';
import { ReactComponent as IconVideo } from '../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconExercise } from '../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../assets/svg/lessons-situation.svg';
import cl from './UserChapterPreview.module.css';

const UserChapterPreview = ({ chapter, type }) => {
    const { t } = useTranslation();
    const { id } = chapter;

    const getPreview = () => {
        switch (type) {
            case Chapters.Track:
                return <PonyPlayer trackId={id} />;

            case Chapters.Exercise:
                return <Preview exerciseId={id} hideEdit />;

            case Chapters.Dictionary:
                return <InfoCard wordObj={chapter} />;

            default:
                return null;
        }
    };

    const getPlaceholder = () => {
        switch (type) {
            case Chapters.Track:
                return (
                    <div className={cl.dictionaryPlaceholder}>
                        <div className={cl.videoIcon}>
                            <IconVideo />
                        </div>
                        <p className={cl.selectWordText}>
                            {t('user_view.select_track')}
                        </p>
                    </div>
                );
            case Chapters.Dictionary:
                return (
                    <div className={cl.dictionaryPlaceholder}>
                        <div className={cl.dictionaryIcon}>
                            <IconDictionary />
                        </div>
                        <p className={cl.selectWordText}>
                            {t('user_view.select_word')}
                        </p>
                    </div>
                );
            case Chapters.Exercise:
                return (
                    <div className={cl.dictionaryPlaceholder}>
                        <div className={cl.exerciseIcon}>
                            <IconExercise />
                        </div>
                        <p className={cl.selectWordText}>
                            {t('user_view.select_exercise')}
                        </p>
                    </div>
                );
            case Chapters.Situation:
                return (
                    <div className={cl.dictionaryPlaceholder}>
                        <div className={cl.situationIcon}>
                            <IconSituation />
                        </div>
                        <p className={cl.selectWordText}>
                            {t('user_view.select_situation')}
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    if (!id) return getPlaceholder();
    if (type === Chapters.Situation)
        return <LearnSituation situaiton={chapter} />;

    return <div className={cl.previewContainer}>{getPreview()}</div>;
};

export default observer(UserChapterPreview);
