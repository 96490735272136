import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import LanguagesService from '../api/LanguagesService';

const DefaultLanguage = {
    code: 'en',
    name: 'English',
};

class LanguagesStore {
    languages = [];
    availableLanguages = [];

    lang = DefaultLanguage.code;
    nativeLang = DefaultLanguage.code;
    interfaceLang = DefaultLanguage.code;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        const savedLanguage = localStorage.getItem('i18nextLng');
        const browserLang = navigator?.languages?.[0]?.slice(0, 2);
        const initialLang =
            savedLanguage || browserLang || DefaultLanguage.code;

        this.setLang(initialLang);
        this.setNativeLang(initialLang);
        this.setInterfaceLang(initialLang);
        this.initialize();
    }

    async initialize() {
        await this.fetchLanguages();
    }

    setLang(lang) {
        const selectedLang = this.availableLanguages.find(
            (lObj) => lObj.value === lang
        );
        if (selectedLang) {
            this.lang = selectedLang.value;
        }
    }

    setNativeLang(lang) {
        const selectedLang = this.languages.find((lObj) => lObj.value === lang);
        if (selectedLang) {
            this.nativeLang = selectedLang.value;
        }
    }

    setInterfaceLang(lang) {
        const selectedLang = this.languages.find((lObj) => lObj.value === lang);
        if (selectedLang) {
            i18n.changeLanguage(selectedLang.value);
        }
    }

    async fetchLanguages() {
        try {
            const { data } = await LanguagesService.getAllLanguages({
                ready: null,
            });

            const mappedLanguages = data.map((lang) => ({
                value: lang.code.toLowerCase(),
                name: lang.title,
            }));

            this.languages = mappedLanguages;

            this.availableLanguages = mappedLanguages.filter((lang) =>
                data.find((d) => d.code.toLowerCase() === lang.value && d.ready)
            );
        } catch {
            const defaultLang = {
                value: DefaultLanguage.code,
                name: DefaultLanguage.name,
            };
            this.allLanguages = defaultLang;
            this.availableLanguages = defaultLang;
        }
    }
}

const languagesStore = new LanguagesStore();
export default languagesStore;
