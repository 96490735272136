export const dictionaryLangs = [
    'bg',
    'cs',
    'da',
    'el',
    'et',
    'fi',
    'hu',
    'id',
    'ja',
    'ko',
    'lt',
    'lv',
    'nb',
    'nl',
    'pl',
    'pt',
    'ro',
    'sk',
    'sl',
    'sv',
    'tr',
    'uk',
    'zh',
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ru',
];
