import { makeAutoObservable } from 'mobx';

const flagKey = 'teacherOnboardingCompleted';

class OnboardingStore {
    onboardingRoutes = [];
    navigate = null;

    currentRouteObj = '';
    currentComponent = '';

    isCompleted = false;
    isDelay = false;
    temporaryStudent = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        this.loadStateFromLocalStorage();
    }

    loadStateFromLocalStorage() {
        try {
            const storedFlag = localStorage.getItem(flagKey);

            if (storedFlag) {
                this.isCompleted = JSON.parse(storedFlag);
            } else {
                this.isCompleted = false;
            }
        } catch (error) {
            console.error(
                'Failed to load onboarding data from localStorage',
                error
            );
        }
    }

    initOnboardingStore({ routes, navigate }) {
        this.onboardingRoutes = routes;
        this.navigate = navigate;

        if (this.isCompleted) return;

        const initialRoute = routes[0];
        const initialComponent = initialRoute.components[0];

        this.setCurrentComponent(initialComponent);
        this.setCurrentRouteObj(initialRoute);
    }

    setCurrentComponent(currentComponent) {
        this.currentComponent = currentComponent;
    }

    setCurrentRouteObj(routeObj) {
        this.currentRouteObj = routeObj;
        this.navigate(routeObj.route);
    }

    moveToNextComponent() {
        if (this.isCompleted) return;

        const { components } = this.currentRouteObj;
        const currentIndex = components.indexOf(this.currentComponent);

        if (currentIndex + 1 >= components.length) {
            const currentRouteIndex = this.onboardingRoutes.findIndex(
                (rObj) => rObj.route === this.currentRouteObj.route
            );

            if (currentRouteIndex + 1 < this.onboardingRoutes.length) {
                const nextRoute = this.onboardingRoutes[currentRouteIndex + 1];

                this.setCurrentRouteObj(nextRoute);
                this.setCurrentComponent(nextRoute.components[0]);
            }
        } else {
            this.setCurrentComponent(components[currentIndex + 1]);
        }
    }

    isOnboarding(componentKey) {
        if (this.isCompleted) return null;
        return this.currentComponent === componentKey ? componentKey : null;
    }

    setTemporaryStudent(flag) {
        this.temporaryStudent = flag;
    }

    setIsCompleted(flag) {
        this.isCompleted = flag;
        localStorage.setItem(flagKey, JSON.stringify(flag));
    }

    setIsDelay(flag) {
        this.isDelay = flag;
    }
}

const onboardingStore = new OnboardingStore();

export default onboardingStore;
