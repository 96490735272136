import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userWordsStore from '../../../pages/UserWords/store/userWords';
import { useFetching } from '../../../../../hooks/useFetching';
import RecognitionService from '../../../../../api/RecognitionService';
import { Button } from '../../../../../teacherComponents';
import replaceTrailingPunctuation from '../../../../../utils/replaceTrailingPunctuation';
import { AudioRecorder } from '../../../../User/components';
import { ReactComponent as IconSound } from '../../../../../assets/svg/icon-sound.svg';
import cl from './PronunciationCard.module.css';

const PronunciationCard = ({
    sourceLang,
    wordObj,
    isTranslation,
    onComplete,
}) => {
    const { t } = useTranslation();
    const { playbackSpeed } = userWordsStore;
    const [currentIdx, setCurrentIdx] = useState(0);
    const [recognized, setRecognized] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isCorrect, setIsCorrect] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isBlur, setIsBlur] = useState(true);
    const [audio] = useState(new Audio());

    const timeoutRef = useRef();

    const examples = wordObj.examples.map((eObj) => eObj.example);
    const examplesVoicePath = wordObj.examples.map((eObj) => eObj.voicePath);
    const translations = wordObj.examples.map((eObj) => eObj.translation);

    const sentence = replaceTrailingPunctuation(examples[currentIdx] || '');
    const sentenceToCompare = sentence.length
        ? sentence
              .match(/\p{L}+/gu)
              .join('')
              .toLowerCase()
        : '';

    const [recognizeAudio, isRecognitionLoading] = useFetching(async (blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('language', sourceLang);
        const { data } = await RecognitionService.recognizeAudio(formData);
        const { text } = data;
        if (text) {
            setRecognized(text);
        } else {
            setIsError(true);
        }
    });

    const handleAudioClick = () => {
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    const handleExampleAudioClick = (src) => {
        audio.src = src;
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    const handleRecordingStart = () => {
        setIsError(false);
        setIsCorrect(null);
        setRecognized('');
        setIsRecording(true);
    };

    const handleRecordingStop = (blob) => {
        setIsRecording(false);
        recognizeAudio(blob);
    };

    const compareRecognized = () => {
        const recognizedToCompare = recognized
            .match(/\p{L}+/gu)
            .join('')
            .toLowerCase();
        // TODO: improve comparing algorithm @saratovkin
        if (recognizedToCompare === sentenceToCompare) {
            setIsCorrect(true);
            handleNextSentence();
        } else {
            setIsCorrect(false);
        }
    };

    const renderText = () => {
        if (isError) {
            return (
                <p className={cl.errorAlert}>{t('demo_page.no_recognition')}</p>
            );
        }
        if (isRecognitionLoading || isRecording)
            return (
                <div className={cl.dotsCont}>
                    <div className={cl.dots} />
                </div>
            );
        if (!recognized) return <></>;
        let checkClassname = '';
        if (isCorrect !== null) {
            checkClassname = isCorrect ? cl.correct : cl.wrong;
        }
        return (
            <p className={`${cl.recognizedText} ${checkClassname}`}>
                {recognized}
            </p>
        );
    };

    const handleNextSentence = () => {
        timeoutRef.current = setTimeout(() => {
            setCurrentIdx(currentIdx + 1);
        }, 2000);
    };

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
    }, [wordObj]);

    useEffect(() => {
        setIsCorrect(null);
        setRecognized('');
        if (examplesVoicePath[currentIdx]) {
            audio.pause();
            audio.src = examplesVoicePath[currentIdx];
        } else {
            audio.src = '';
        }
    }, [currentIdx, wordObj]);

    useEffect(() => {
        if (recognized) {
            compareRecognized();
        }
    }, [recognized]);

    useEffect(() => {
        setIsBlur(true);
    }, [wordObj, currentIdx, isTranslation]);

    useEffect(() => {
        if (currentIdx === examples.length) {
            setTimeout(() => {
                onComplete();
            }, 2000);
        }
    }, [currentIdx, examples]);

    return (
        <div className={cl.pronunciationCard}>
            {currentIdx === examples.length ? (
                <div className={cl.resultsCont}>
                    {examples.map((e, i) => (
                        <div className={cl.sentenceResult} key={i}>
                            <Button
                                className={cl.playButton}
                                icon={<IconSound />}
                                variant={'grey'}
                                onClick={() =>
                                    handleExampleAudioClick(
                                        examplesVoicePath[i]
                                    )
                                }
                            />
                            <p className={cl.translation}>{e}</p>
                            <p className={cl.translation}>{translations[i]}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <Button
                        className={cl.playButton}
                        icon={<IconSound />}
                        variant={'grey'}
                        onClick={handleAudioClick}
                    />
                    <div className={cl.translationList}>
                        {examples.map((text, idx) => (
                            <Fragment key={text}>
                                <p
                                    className={`${cl.translationText} ${
                                        currentIdx > idx
                                            ? cl.correct
                                            : currentIdx === idx
                                              ? cl.active
                                              : ''
                                    }`}
                                >
                                    {isTranslation ? translations[idx] : text}
                                </p>
                                {currentIdx === idx && (
                                    <p
                                        className={`${cl.translation} ${isBlur ? cl.blur : ''}`}
                                        onClick={() => setIsBlur(!isBlur)}
                                    >
                                        {isTranslation
                                            ? text
                                            : translations[idx]}
                                    </p>
                                )}
                            </Fragment>
                        ))}
                    </div>

                    <div className={cl.recognitionBlock}>
                        {renderText()}
                        <div
                            className={cl.audioBtnCont}
                            style={{ opacity: isCorrect ? 0 : 1 }}
                        >
                            <AudioRecorder
                                key={wordObj.id}
                                onStart={handleRecordingStart}
                                onStop={handleRecordingStop}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default observer(PronunciationCard);
