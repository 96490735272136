import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '..';
import { ROUTES } from '../../router';
import { ReactComponent as IconDictionary } from '../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../assets/svg/lessons-track.svg';
import cl from './NavigationSelect.module.css';

export const renderIcon = (type) => {
    switch (type) {
        case 'tracks':
            return <IconTrack />;
        case 'situations':
            return <IconSituation />;
        case 'exercises':
            return <IconExercise />;
        case 'dictionaries':
            return <IconDictionary />;
        default:
            return null;
    }
};

const NavigationSelect = () => {
    const ContentOptions = [
        {
            to: ROUTES.teacherExercises,
            text: 'exercises',
        },
        {
            to: ROUTES.teacherSituations,
            text: 'situations',
        },
        {
            to: ROUTES.teacherTracks,
            text: 'tracks',
        },
        {
            to: ROUTES.teacherDictionary,
            text: 'dictionaries',
        },
    ];

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const ref = useRef();
    const onboardingRef = useRef();
    const [expanded, setExpanded] = useState(false);

    const isOnboarding = false;

    const handleOptionClick = (to) => {
        navigate(to);
        setExpanded(false);
    };

    const renderNavigationIcon = (type) => (
        <div className={cl.iconContainer}>{renderIcon(type)}</div>
    );

    const renderTitle = () => {
        const currentView = ContentOptions.find((o) => o.to === pathname);
        return currentView
            ? `teacher_navbar.${currentView.text}`
            : 'exercises.my_content';
    };

    const isActive = () => ContentOptions.some((o) => o.to === pathname);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        if (isOnboarding) {
            setExpanded(true);
        }
    }, [isOnboarding]);

    return (
        <div className={cl.navSelectContainer} ref={ref}>
            <Button
                style={{ paddingRight: 38 }}
                text={t(renderTitle())}
                variant={isActive() ? 'grey' : 'white'}
                onClick={() => setExpanded(!expanded)}
            />

            {!expanded ? (
                <div className={cl.arrowDown} />
            ) : (
                <>
                    <div className={cl.arrowUp} />
                    <div className={cl.options} ref={onboardingRef}>
                        {ContentOptions.map((o) => (
                            <div
                                key={o.to}
                                className={`${cl.optionContainer} ${cl[o.text]}`}
                                onClick={() => handleOptionClick(o.to)}
                            >
                                {renderNavigationIcon(o.text)}
                                <div className={cl.optionText}>
                                    <p className={cl.title}>
                                        {t(`teacher_navbar.${o.text}`)}
                                    </p>
                                    <p className={cl.subtitle}></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default NavigationSelect;
