import { makeAutoObservable } from 'mobx';

const GlossarySettingsKey = 'glossarySettings';
const GlossaryPackSizeKey = 'glossaryPackSizeKey';
const GlossaryPlaybackKey = 'glossaryPlaybackKey';

const PackSizeOptions = [5, 7, 9];
const WordCardModes = {
    OriginalForm: 'original_form',
    WordPuzzle: 'word_puzzle',
    WordListening: 'word_listening',
    WordTranslation: 'word_translation',
    PhrasePuzzle: 'phrase_puzzle',
    PhraseSpeaking: 'phrase_speaking',
    PhraseTranslation: 'phrase_translation',
    PhraseWriting: 'phrase_writing',
};
const WordStatuses = {
    Active: 'active',
    Learned: 'learned',
};
const PlayBackOptions = [0.6, 0.7, 0.8, 0.9, 1];

class UserWordsStore {
    page = 0;
    searchString = '';
    paginationEnd = false;
    status = WordStatuses.Active;
    currentWord = {};

    dictionaryWords = [];
    activeWords = [];
    pendingWords = [];
    translatedWords = [];
    currentCardIdx = 0;

    cardModes = new Array(Object.keys(WordCardModes).length).fill(true);
    wordsPerPack = PackSizeOptions[0];
    playbackSpeed = PlayBackOptions[PlayBackOptions.length - 1];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        this.init();
    }

    init() {
        this.cardModes = localStorage.getItem(GlossarySettingsKey)
            ? JSON.parse(localStorage.getItem(GlossarySettingsKey))
            : this.cardModes;

        this.wordsPerPack = localStorage.getItem(GlossaryPackSizeKey)
            ? JSON.parse(localStorage.getItem(GlossaryPackSizeKey))
            : this.wordsPerPack;

        this.playbackSpeed = localStorage.getItem(GlossaryPlaybackKey)
            ? JSON.parse(localStorage.getItem(GlossaryPlaybackKey))
            : this.playbackSpeed;
    }

    setPage(page) {
        this.page = page;
    }

    setStatus(status) {
        this.status = status;
    }

    setNextPage() {
        this.setPage(this.page + 1);
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setPaginationEnd(paginationEnd) {
        this.paginationEnd = paginationEnd;
    }

    addNewDictionaryWord(word) {
        const selectedModes = this.cardModes
            .map((m, i) => (m ? i : null))
            .filter((v) => v !== null);

        const newWord = {
            ...word,
            levels: selectedModes,
        };

        this.setDictionaryWords(
            [...this.dictionaryWords, newWord].filter(
                (w, index, self) =>
                    !self.slice(0, index).some((item) => item.word === w.word)
            )
        );
    }

    setWordsData(data) {
        const items = data.map((w) => ({ ...w, id: w.wordId || w.word }));
        if (!items.length) {
            this.setPaginationEnd(true);
        }
        const words = (
            this.page > 0 ? [...this.words, ...items] : items
        ).filter(
            (w, index, self) =>
                !self.slice(0, index).some((item) => item.id === w.id)
        );
        const selectedModes = this.cardModes
            .map((m, i) => (m ? i : null))
            .filter((v) => v !== null);

        this.setDictionaryWords(
            words.map((w) => ({
                ...w,
                levels: selectedModes,
            }))
        );
    }

    setCurrentWord(currentWord) {
        this.currentWord = currentWord;
    }

    resetSearch() {
        this.setPaginationEnd(false);
        this.setPage(0);
        this.setTracks([]);
    }

    setDictionaryWords(dictionaryWords) {
        this.dictionaryWords = dictionaryWords;
    }

    setActiveWords(activeWords) {
        this.activeWords = activeWords;
    }

    setPendingWords(pendingWords) {
        this.pendingWords = pendingWords;
    }

    removePendingWord(wordId, data) {
        this.pendingWords = this.pendingWords.filter(
            (word) => word.wordId !== wordId
        );

        this.setDictionaryWords(
            this.dictionaryWords.map((w) =>
                w.id === wordId ? { ...w, ...data } : w
            )
        );
    }

    initActiveWords(words) {
        this.setActiveWords(words);
    }

    changeWordLevel(wObj) {
        const words = this.activeWords.map((w) =>
            w.id === wObj.id ? { ...w, lastIteration: w.lastIteration + 1 } : w
        );
        this.setActiveWords(words);
    }

    setCurrentCardIdx(cardIdx) {
        this.currentCardIdx = cardIdx;
    }

    toggleCardMode(idx) {
        const newModes = this.cardModes.map((mode, i) =>
            i === idx ? !mode : mode
        );
        localStorage.setItem(GlossarySettingsKey, JSON.stringify(newModes));
        this.cardModes = newModes;
        this.refreshWordSettings(newModes);
    }

    refreshWordSettings(newModes) {
        const selectedModes = newModes
            .map((m, i) => (m ? i : null))
            .filter((v) => v !== null);
        const newLastIteration = newModes.findIndex((flag) => flag);
        this.setActiveWords(
            this.activeWords.map((w) => ({
                ...w,
                levels: selectedModes,
                lastIteration: newLastIteration,
            }))
        );
        this.setDictionaryWords(
            this.dictionaryWords.map((w) => ({
                ...w,
                levels: selectedModes,
                lastIteration: newLastIteration,
            }))
        );
        this.setCurrentCardIdx(0);
    }

    setWordsPerPack(wordsPerPack) {
        this.wordsPerPack = wordsPerPack;
        localStorage.setItem(GlossaryPackSizeKey, JSON.stringify(wordsPerPack));
    }

    setPlaybackSpeed(playbackSpeed) {
        this.playbackSpeed = playbackSpeed;
        localStorage.setItem(
            GlossaryPlaybackKey,
            JSON.stringify(playbackSpeed)
        );
    }
}

const userWordsStore = new UserWordsStore();
export default userWordsStore;
export { PackSizeOptions, PlayBackOptions, WordCardModes, WordStatuses };
