import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../TeacherContent/data/constants';

const ExerciseTypes = [
    ...Object.values(GrammarExerciseTypes),
    ...Object.values(MediaExerciseTypes),
];

const ExercisesPerPage = 100;

export { ExercisesPerPage, ExerciseTypes };
