import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../App';
import languagesStore from '../../store/interface';
import onboardingStore from '../../store/onboarding';
import UserService from '../../api/UserService';
import { useFetching } from '../../hooks/useFetching';
import { Loader } from '../../UI';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { Button, Input, Label, Select } from '../../teacherComponents';
import { Roles } from '../../data/common';
import { ROUTES } from '../../router';
import { ReactComponent as IconLogout } from '../../assets/svg/icon-logout.svg';
import cl from './Profile.module.css';

const Profile = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const { availableLanguages, lang, languages, nativeLang } = languagesStore;

    const { user } = authStore;
    const { role } = user;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sex, setSex] = useState('');

    const [logout, logoutLoading] = useFetching(async () => {
        await UserService.logout();
    });

    const [updateUser, isLoading] = useFetching(
        async ({ lang, nativeLang }) => {
            const { id } = user;
            const res = await UserService.updateUser(
                lang || nativeLang
                    ? { id, lang, nativeLang }
                    : { id, name, sex }
            );

            if (res && res.status === 204) {
                authStore.setUser(
                    lang
                        ? { ...user, lang }
                        : nativeLang
                          ? { ...user, nativeLang }
                          : { ...user, name, sex }
                );
            }
        }
    );

    useEffect(() => {
        setName(user.name ?? '');
        setEmail(user.email ?? '');
        setSex(user.sex ?? '');
    }, [user]);

    const resetOnboarding = () => {
        onboardingStore.setIsCompleted(false);
        navigate(ROUTES.teachersLanding);
    };

    const hasChanged = () => {
        if (name && name !== user.name) return true;
        if (sex && sex !== user.sex) return true;
        return false;
    };

    const handleLogout = async () => {
        await logout();
        authStore.logout(navigate);
    };

    const getCurrentLangName = () =>
        availableLanguages.find((l) => l.value === i18n.language)?.name ?? '';

    const handleLangChange = (lang) => {
        updateUser({ lang });
    };

    const handleNativeLangChange = (nativeLang) => {
        updateUser({ nativeLang });
    };

    return (
        <PageWrapper style={{ overflowY: 'auto', position: 'relative' }}>
            {isLoading && (
                <Loader
                    style={{
                        position: 'absolute',
                        zIndex: 3,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
            <div className={cl.profile}>
                <p className={cl.title}>{t('login.title')}</p>
                <div className={cl.infoCont}>
                    <div>
                        <Label text={t('email')} />
                        <Input
                            variant={'outlined'}
                            value={email}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <Label text={t('users.role')} />
                        <Input
                            variant={'outlined'}
                            value={role}
                            disabled={true}
                        />
                    </div>
                </div>

                <div>
                    <Label text={t('profile.name')} />
                    <Input
                        variant={'outlined'}
                        value={name}
                        onChange={setName}
                    />
                </div>

                <div style={{ width: '100%' }}>
                    <Label
                        text={t(
                            role === Roles.User
                                ? 'users.lang'
                                : 'situations.language'
                        )}
                    />
                    <Select
                        label={getCurrentLangName()}
                        variant={'outlined'}
                        style={{ width: '100%' }}
                        value={lang}
                        options={availableLanguages}
                        onChange={handleLangChange}
                        hideReset
                    />
                </div>
                {role === Roles.User && (
                    <div style={{ width: '100%' }}>
                        <Label text={t('users.native')} />
                        <Select
                            label={getCurrentLangName()}
                            variant={'outlined'}
                            style={{ width: '100%' }}
                            value={nativeLang}
                            options={languages}
                            onChange={handleNativeLangChange}
                            hideReset
                        />
                    </div>
                )}
                <div style={{ width: '100%' }}>
                    <Label text={t('users.interface_lang')} />
                    <Select
                        label={getCurrentLangName()}
                        variant={'outlined'}
                        style={{ width: '100%' }}
                        value={i18n.language}
                        options={availableLanguages}
                        onChange={languagesStore.setInterfaceLang}
                        hideReset
                    />
                </div>

                {role === Roles.Teacher && (
                    <Button
                        style={{ margin: 'auto' }}
                        variant={'purple'}
                        text={t('teacher_onboarding.reset_onboarding')}
                        onClick={resetOnboarding}
                    />
                )}
                <div className={cl.horizontalCont}>
                    {hasChanged() && (
                        <Button
                            text={t('buttons.save_changes')}
                            variant={'purple'}
                            onClick={updateUser}
                        />
                    )}
                    <Button
                        icon={<IconLogout className={cl.logoutIcon} />}
                        variant={'red'}
                        text={t('buttons.logout')}
                        onClick={handleLogout}
                        isLoading={logoutLoading}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

export default observer(Profile);
